var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { "fill-rule": "evenodd", "stroke-width": "1.25" } },
    [
      _c("path", {
        attrs: {
          d:
            "M7.625 7.625v7.75h7.75v-7.75h-7.75zM17.9666667 17.9117647L15 15M17.9666667 5.08823529L15 8M5.03333333 17.9117647L8 15M5.03333333 5.08823529L8 8M22 19.5c0 1.3807273-1.1192439 2.5-2.4999358 2.5C18.1193723 22 17 20.8807273 17 19.5s1.1193723-2.5 2.5000642-2.5C20.8807561 17 22 18.1192727 22 19.5zM22 3.5C22 2.11927265 20.8807561 1 19.5000642 1 18.1193723 1 17 2.11927265 17 3.5S18.1193723 6 19.5000642 6C20.8807561 6 22 4.88072735 22 3.5zM1 19.5C1 20.8807273 2.11924391 22 3.49993579 22 4.88062768 22 6 20.8807273 6 19.5S4.88062768 17 3.49993579 17C2.11924391 17 1 18.1192727 1 19.5zM1 3.5C1 2.11927265 2.11924391 1 3.49993579 1 4.88062768 1 6 2.11927265 6 3.5S4.88062768 6 3.49993579 6C2.11924391 6 1 4.88072735 1 3.5z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }