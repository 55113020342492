/* eslint-disable prettier/prettier */
// import survey from '@/data/fake-survey.js'

export const state = () => ({
  questionId: null,
  segmentQuestionFilterGroups: [],
  segmentQuestionFiltersUngrouped: []
})
export const mutations = {
  setGroupedQuestionId(state, val) {
    state.questionId = val
  },
  segmentQuestionFilterGroups(state, arr = []) {
    state.segmentQuestionFilterGroups = arr
  },
  segmentQuestionFiltersUngrouped(state, arr = []) {
    state.segmentQuestionFiltersUngrouped = arr
  }
}

export const actions = {
  setGroupedQuestionId({ commit }, val) {
    commit('setGroupedQuestionId', val)
  },
  setCurrentQuestionFilterGroups({ commit }, arr = []) {
    commit('segmentQuestionFilterGroups', arr)
  },
  setSegmentQuestionFilterUngrouped({ commit }, arr = []) {
    commit('segmentQuestionFiltersUngrouped', arr)
  },
}

export const getters = {
  getGroupedQuestionId: state => state.questionId,
  getSegmentQuestionFilterGroups: state => state.segmentQuestionFilterGroups,
  getSegmentQuestionFiltersUngrouped: state => state.segmentQuestionFiltersUngrouped
}
