// import ApiService from '@/services/Api'

// export default (ctx, inject) => {
//   const api = new ApiService({ $axios: ctx.app.$axios, $store: ctx.store })
//   ctx.$api = api
//   inject('api', api)
// }

import AdminService from '@/services/AdminService'
import AnalyzeService from '@/services/AnalyzeService'
import AuthService from '@/services/AuthService'
import BrandService from '@/services/BrandService'
import SurveyService from '@/services/SurveyService'
import SyndicatedService from '@/services/SyndicatedService'
import WorkspaceService from '@/services/WorkspaceService'
import UtilService from '@/services/UtilService'
import CategoryService from '@/services/CategoryService'
import ParentBrandService from '@/services/ParentBrandService'
import ActivitiesService from '@/services/ActivitiesService'
import StrategyService from '@/services/StrategyService'

export default (ctx, inject) => {
  const $axios = ctx.app.$axios
  const store = ctx.store
  const api = {
    admin: new AdminService({ $axios: $axios, $store: store }),
    analyze: new AnalyzeService({ $axios: $axios, $store: store }),
    auth: new AuthService({ $axios: $axios, $store: store }),
    brand: new BrandService({ $axios: $axios, $store: store }),
    survey: new SurveyService({ $axios: $axios, $store: store }),
    syndicated: new SyndicatedService({ $axios: $axios, $store: store }),
    workspace: new WorkspaceService({ $axios: $axios, $store: store }),
    util: new UtilService({ $axios: $axios, $store: store }),
    category: new CategoryService({ $axios: $axios, $store: store }),
    parentBrand: new ParentBrandService({ $axios: $axios, $store: store }),
    activities: new ActivitiesService({ $axios: $axios, $store: store }),
    strategy: new StrategyService({ $axios: $axios, $store: store })
  }
  ctx.$api = api
  inject('api', api)
}
