<template>
  <v-content id="segment-container" class="pt-0 segment-container">
    <div class="wrapper">
      <div class="header">
        <v-card class="font-weight-medium filters-card bg-black">
          <div>
            <div
              class="close-btn"
              style="
                cursor: pointer;
                position: absolute;
                top: 0;
                right: 10px;
                color: white;
                align-items: flex-end;
                justify-content: flex-end;
              "
              @click="closeFilters"
            >
              <v-icon style="color: white">
                close
              </v-icon>
            </div>
          </div>
          <v-card-title primary class="filterstitle font-weight-bold">
            Filters
          </v-card-title>
        </v-card>
      </div>
      <v-alert
        id="my-alert"
        v-model="alert"
        icon="info"
        outline
        style="font-size: 13px; padding-top: 60px; border: none !important"
        class="my-alert segmentfilter font-weight-bold ma-0"
      >
        You must select at least one ungrouped option for segmentation.
      </v-alert>
      <simplebar class="scroller">
        <v-card dark class="bg-black">
          <v-card-title primary class="title font-weight-bold">
            Segmented By:
          </v-card-title>
          <v-content fluid class="pt-0 pb-0 scroll-content-segs">
            <v-radio-group v-model="radios">
              <v-content class="mb-4 pl-0 pt-0" data-id="no-sementation">
                <v-radio
                  data-id="no-sementation"
                  name="radio"
                  class="no-segments pl-4 pr-2 font-weight-bold"
                  label="No Segmentation"
                  :value="0"
                  @change="onRemoveSegmentation"
                />
              </v-content>

              <div v-for="question in surveyQuestions" :key="question.id">
                <v-content
                  v-if="question.SurveyAnswers.length > 0"
                  :id="'segment-' + question.id.toString()"
                  class="pt-3 pl-4 pr-4"
                  :class="{ active: localCurrentQuestionId == question.id }"
                  style="border-tp: 1px solid #999"
                >
                  <v-radio
                    ref="radiobuttons"
                    class="font-weight-bold"
                    name="radio"
                    :data-id="question.id"
                    :label="question.text"
                    :value="question.id"
                    @change="onRadioChange(question)"
                  />
                  <div
                    class="container pt-0"
                    style="border-bottom: 1px solid #333"
                  >
                    <DraggableAnswers
                      v-if="localCurrentQuestionId == question.id"
                      :id="question.id"
                      :key="audienceId"
                      :answers="question.SurveyAnswers"
                      :current-answer="localCurrentQuestionId === question.id"
                      @updateSegmentation="onUpdateSegmentation"
                    />
                  </div>
                </v-content>
              </div>
            </v-radio-group>
          </v-content>
        </v-card>
      </simplebar>
    </div>
    <div class="footer text-xs-left pr-3">
      <v-btn
        class="text-black"
        color="primary"
        :class="{ disabled: !enabled }"
        @click="sendSegments()"
      >
        Apply sub-segment
      </v-btn>
    </div>
  </v-content>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import simplebar from 'simplebar-vue'
import DraggableAnswers from '@/components/pages/survey/filters/DraggableAnswers'
export default {
  components: {
    DraggableAnswers,
    simplebar
  },
  data() {
    return {
      radios: 0,
      alert: false,
      enabled: true,
      oldSegments: {},
      localCurrentQuestionId: -1,
      localCurrentQuestionText: '',
      grouped: null,
      ungrouped: null,
      apiGroups: null
    }
  },
  computed: {
    ...mapGetters({
      survey: 'analyzeSurvey/getSurvey',
      currentQuestion: 'analyzeSurvey/getCurrentQuestion',
      currentQuestionId: 'analyzeSurvey/getCurrentQuestionId',
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      audienceQuestions: 'analyzeSurvey/getAudienceQuestions',
      audienceId: 'analyzeSurvey/getAudienceId',
      getSegGroups: 'analyzeSurvey/getSegGroups',
      segmentGroups: 'analyzeSurvey/getSegmentQuestionFilterGroups',
      segmentsUpgrouped: 'analyzeSurvey/getSegmentQuestionFiltersUngrouped'
    }),
    surveyQuestions() {
      if (this.audienceId === 0) {
        return this.segmentQuestions
      } else {
        return this.audienceQuestions
      }
    }
  },

  mounted() {
    // set the current question open when toggling btw charts
    this.radios = this.localCurrentQuestionId
    this.$bus.$on('enable-segments', bool => {
      this.enable(bool)
    })
    this.$bus.$on('reset-filters-panel', () => {
      console.log('SegmentFilter.vue, reset-filters-panel event received')
      this.$set(this, 'radios', 0)
      this.$set(this, 'localCurrentQuestionId', 0)
      this.$set(this, 'localCurrentQuestionText', '')
    })
  },
  destroyed() {
    this.$bus.$off('enable-segments')
  },
  updated() {
    this.radios = this.localCurrentQuestionId
  },
  methods: {
    ...mapActions({
      setView: 'analyzeSurvey/setCurrentView',
      setCurrentQuestionId: 'analyzeSurvey/setCurrentQuestionId',
      setCurrentQuestion: 'analyzeSurvey/setCurrentQuestion',
      resetAudience: 'analyzeSurvey/resetAudience',
      clearSegments: 'analyzeSurvey/clearSegments',
      setFilterGroups: 'analyzeSurvey/setCurrentQuestionFilterGroups',
      setUngroupedFilters: 'analyzeSurvey/setSegmentQuestionFilterUngrouped',
      setSegGroups: 'analyzeSurvey/setSegGroups'
    }),
    onRadioChange(question) {
      this.$set(this, 'localCurrentQuestionId', question.id)
      this.$set(this, 'localCurrentQuestionText', question.text)
      // *** temp storage this
      // this.setCurrentQuestionId(question.id)
      // this.setCurrentQuestion(question.text)
      // this.clearSegments()
    },
    enable(bool) {
      if (bool === true) {
        this.enabled = false
        this.alert = true
      } else {
        this.enabled = true
        this.alert = false
      }
    },

    // Sends the groups for chart grouping when clicking 'apply'
    sendSegments(bool = true) {
      this.setCurrentQuestionId(this.localCurrentQuestionId)
      this.setCurrentQuestion(this.localCurrentQuestionText)
      this.clearSegments()

      this.setSegGroups(this.apiGroups)
      this.setFilterGroups(this.grouped)
      this.setUngroupedFilters(this.ungrouped)

      if (this.localCurrentQuestionId === 0) this.resetAudience()

      const groups = {
        survey_id: this.survey.id,
        respondent_group_id: this.audienceId,
        segment_question: this.currentQuestionId,
        SegmentGroups: this.getSegGroups
      }

      // set grouping for charts
      this.$bus.$emit('apply-filters', groups)
      this.$bus.$emit('update-filters-panel', {
        groups: this.segmentGroups,
        ungrouped: this.segmentsUpgrouped
      })
      if (bool) {
        this.closeFilters()
      }
    },

    closeFilters() {
      // this.setPanelGrouping()
      if (this.localCurrentQuestionId !== this.currentQuestionId) {
        this.$set(this, 'localCurrentQuestionId', this.currentQuestionId)
        // this.$set(this, 'radios', this.currentQuestionId)
      }
      this.setView('FILTERS')
    },
    setPanelGrouping() {
      this.$bus.$emit('update-filters-panel', {
        groups: this.segmentGroups,
        ungrouped: this.segmentsUpgrouped
      })
    },

    // removes grouping
    onRemoveSegmentation: function() {
      this.$set(this, 'localCurrentQuestionId', 0)
      this.$set(this, 'localCurrentQuestionText', '')
      // remove segementtion from the store
      // *** temp storage this
      // this.alert = false
      // this.closeFilters()
      // this.resetAudience()
      // this.sendSegments(false)
    },
    onUpdateSegmentation({ grouped, ungrouped, apiGroups }) {
      this.$set(this, 'grouped', grouped)
      this.$set(this, 'ungrouped', ungrouped)
      this.$set(this, 'apiGroups', apiGroups)
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/css/_segmentFilters.scss';
@import '@/assets/css/_filterAlert.scss';
.close-btn .v-btn__content {
  align-items: flex-end;
  justify-content: flex-end;
  background: #222;
}

.segment-container {
  .theme--dark.v-icon {
    color: #fff !important;
  }
  .accent--text {
    color: white !important;
    caret-color: white !important;
  }
  .close-btn .v-btn__content {
    align-items: flex-end;
    justify-content: flex-end;
  }
  .filterstitle {
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: all 0.2s;
  max-height: 30px;
}
.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
