export const state = () => ({
  // miniNav: true,
  // breadcrumbSection: null,
  // breadcrumbBrand: null,
  // breadcrumbs: []
})

export const getters = {
  // minNav(state) {
  //   return state.miniNav
  // }
  // breadcrumbSection(state) {
  //   return state.breadcrumbSection
  // }
}

export const mutations = {
  // toggleMiniNav(state, val) {
  //   state.miniNav = !state.miniNav
  // },
  // updateBreadcrubs(state, val) {
  //   val = val || {}
  //   // const section = val.section || ''
  //   // const brand = val.brand || ''
  //   // state.breadcrumbSection = section
  //   // state.breadcrumbBrand = brand
  // }
}

export const actions = {
  // toggleMiniNav({ commit }) {
  //   commit('toggleMiniNav')
  // },
  // updateBreadcrubs({ commit }, val) {
  //   commit('updateBreadcrubs', val)
  // }
}
