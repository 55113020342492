import Api from '@/services/Api'
export default class AdminService extends Api {
  // Admin - Get list of all users with the role
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-GetUserList
  getUsers() {
    return this.$axios.get('admin/user/list').then(response => response.data)
  }

  // Admin - Get list of all users with the role
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-GetUserList
  getUserDetail(payload) {
    return this.$axios
      .post('admin/user/details', payload)
      .then(response => {
        // console.log('createSurvey service, response:', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Admin - Update user Details
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-UpdateUserDetails
  editUserDetail(payload) {
    return this.$axios
      .post('admin/user/update', payload)
      .then(response => {
        // console.log('editUserDetail(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Admin - Add users to workspace
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-AssignWorkspaceToUser
  addUserToWorkspace(payload) {
    return this.$axios
      .post('admin/user/workspace/add', payload)
      .then(response => {
        // console.log('addUserToWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Admin - Delete users from workspace
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-DeleteUserFromWorkspace
  deleteUserFromWorkspace(payload) {
    return this.$axios
      .delete('admin/user/workspace/delete', { data: payload })
      .then(response => {
        // console.log('deleteUserFromWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Admin - Invite user via email
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-SendInviteEmail
  inviteUserViaEmail(payload) {
    return this.$axios
      .post('admin/user/invite', payload)
      .then(response => {
        // console.log('addUserToWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Admin - Set User password
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-SetUserPassword
  setUserPassword(payload) {
    return this.$axios
      .post('admin/user/setPassword', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Admin - Forgot password email
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-SendResetPasswordEmail
  forgotPasswordEmail(payload) {
    return this.$axios.post('login/password/forgot', payload)
    // .then(response => {
    //   return response
    // })
    // .catch(err => {
    //   console.log('*** error ***', err)
    //   return err
    // })
  }

  // Admin - Set User password
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Admin-SetUserPassword
  uploadProfileImage(payload) {
    // console.log('uploadProfileImage(payload)', payload)
    return this.$axios
      .post('admin/user/image', payload)
      .then(response => {
        // console.log('addUserToWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        // console.log('addUserToWorkspace(payload)', err)
        return err.response
      })
  }
}
