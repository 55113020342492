var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "stroke-width": "1.25",
      d: "M11 18h7v-7h-7zM1 8h7V1H1zM1 18h7v-7H1zM11 8h7V1h-7z"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }