<template>
  <v-dialog v-model="dialog" width="700">
    <v-btn slot="activator" icon @click="prepopulateText">
      <v-icon id="edit-syndicated">
        edit
      </v-icon>
    </v-btn>
    <v-card>
      <v-card-title class="card-title">
        <v-layout>
          <v-flex>
            <v-layout column>
              <!-- <h2>{{ currentDataset.name }}</h2> -->
              <h2>Segment Info</h2>
              <span class="segment-definition">{{ currentDataset.query }}</span>
            </v-layout>
          </v-flex>
          <v-spacer />
          <v-flex shrink>
            <v-btn icon @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-form ref="form" @submit.prevent="handleSubmit">
        <v-layout column>
          <v-flex class="form-field">
            <label class="bold">Name</label>
            <v-text-field
              v-model="name"
              placeholder="Provide a name for your segment"
              :rules="nameRules"
              :error-messages="nameErrors"
              hide-details
              validate-on-blur
              box
            />
          </v-flex>
          <v-flex class="form-field">
            <label class="bold">Description</label>
            <v-textarea
              v-model="description"
              placeholder="Leave a description about why you’re creating this CSV"
              :rules="descriptionRules"
              :error-messages="descriptionErrors"
              hide-details
              validate-on-blur
              box
            />
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer />
          <Button variant="primary" type="submit" :disabled="unchanged">
            Save Changes
          </Button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { notEmpty } from '@/js/validations'
import Button from '@/components/ui/Button'
export default {
  components: {
    Button
  },
  layout: 'survey',
  middleware: ['authenticated'],
  data: () => ({
    dialog: false,
    name: '',
    description: '',
    unique: false,
    nameRules: [notEmpty],
    nameErrors: [],
    descriptionRules: [notEmpty],
    descriptionErrors: []
  }),
  computed: {
    ...mapGetters({
      currentDataset: 'syndicated/currentDataset'
    }),
    unchanged() {
      return (
        this.name === this.currentDataset.name &&
        this.description === this.currentDataset.description
      )
    }
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapActions({
      updateDataset: 'syndicated/updateDataset'
    }),
    prepopulateText() {
      const { name, description } = this.currentDataset
      this.$set(this, 'name', name)
      this.$set(this, 'description', description)
    },
    handleSubmit() {
      this.$nuxt.$loading.start()
      this.$set(this, 'nameErrors', [])
      const { name, description } = this
      if (this.$refs.form.validate()) {
        // if form data is valid
        const payload = {
          segment_id: this.currentDataset.id,
          name,
          description
        }
        this.$api.syndicated.updateSyndicatedName(payload).then(data => {
          if (data && data.data) {
            const { error } = data.data
            this.nameErrors.push(error)
          } else {
            this.updateDataset({ ...this.currentDataset, name, description })
            this.$set(this, 'dialog', false)
          }
          this.$nuxt.$loading.finish()
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
label.bold {
  color: #000;
  font-weight: bold;
}
form {
  padding: 1.5em;
}
#edit-syndicated {
  @include headline-small-soft;
  font-family: 'Material Icons';
}

.form-field {
  margin-bottom: $spacing-base;
}

.card-title {
  border-bottom: 1px solid color('rule-light');
}

.segment-definition {
  @include headline-small-strong;
}
</style>
