var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._b(
      {
        class: _vm.classes,
        attrs: {
          type: _vm.type,
          to: _vm.to,
          round: _vm.round,
          flat: "",
          dark: _vm.dark,
          disabled: _vm.disabled,
          small: _vm.small
        },
        on: {
          mousedown: function($event) {
            return _vm.$emit("mousedown")
          },
          mouseup: function($event) {
            return _vm.$emit("mouseup")
          },
          click: function($event) {
            return _vm.$emit("click", $event)
          }
        }
      },
      "v-btn",
      _vm.$attrs,
      false
    ),
    [
      _vm.icon
        ? _c("v-icon", [_vm._v("\n    " + _vm._s(_vm.icon) + "\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.icon ? _c("v-spacer") : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }