<template>
  <v-layout :key="componentKey">
    <v-content :id="id" class="pt-2 mr-2 answers" style="position:relative;">
      <div :class="{'disabled':disabled}" class="overlay" style="position:absolute;top:0;height:100%;background:rgba(34,34,34,0.50 );width:100%;z-index:4" />
      <v-layout
        v-for="(answer,index) in answers"
        ref="filteranswer"
        :key="index"
        justify-space-between
        class="filter-answer mb-2"
        :class="{'disabled inactive': !answer.selected}"
      >
        <input
          :id="`segment-${answer.id.toString()}`"
          ref="input"
          type="checkbox"
          :checked="answer.selected || false"
          :data-id="answer.id"
          :copy="answer.text"
          :data-count="answer.respondent_count"
          :name="id.toString()"
          class="mt-0 mb-1 text-white styled-checkbox disabled"
          @change="onFiltersChange(answer,index)"
        >
        <label :for="`segment-${answer.id.toString()}`">{{ answer.text }}</label>
        <div style="font-size:12px">
          {{ answer.respondent_count }}
        </div> 
      </v-layout>
    </v-content>
  </v-layout>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import utils from '@/js/utils.js'

export default {
  props: {
    answers: {
      type: Array,
      default: function() {
        return []
      }
    },
    id: {
      type: Number,
      default: null
    }
  },
  data: () => {
    return {
      groups: [],
      targetClass: '.filter-answer',
      componentKey: 0,
      disabled: false
    }
  },
  computed: {
    ...mapGetters({
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      audienceId: 'analyzeSurvey/getAudienceId',
      audienceResponseFilters: 'analyzeSurvey/getAudienceResponseFilters'
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.disable()
      this.$bus.$on('disable', this.disable)
      this.$bus.$on('enable', this.enable)
      this.$bus.$on('reset', this.reset)
    })
  },

  // set segment response count in store
  updated() {
    const total = this.answers.reduce(function(acc, ans) {
      if (ans.respondent_count) {
        return acc + ans.respondent_count
      } else return acc
    }, 0)
    this.setSegmentedResponseCount(total)
  },
  methods: {
    ...mapActions({
      setSegmentedResponseCount: 'analyzeSurvey/setSegmentedResponseCount'
    }),
    reset() {
      this.$nextTick(() => {
        if (this.audienceId !== 0) {
          this.componentKey += 1
        } else {
          this.$refs.input.map(input => {
            return (input.checked = false)
          })
        }
      })
    },
    onFiltersChange(answer, index) {
      this.$nextTick(() => {
        this.setInputChecked(this.$refs.input[index], index)

        const obj = {
          question_id: this.id,
          Answers: this.getAnswers(this.$refs.input),
          isempty: this.getResults(),
          index: index
        }
        this.$emit('update-question', obj)
      })
    },
    getAnswers(a) {
      return a.map(input => {
        return {
          checked: input.checked,
          id: input.id,
          question_Id: this.id,
          respondent_count: input.getAttribute('data-count')
        }
      })
    },
    setInputChecked(input, index) {
      console.log('setInputChecked, arguments:', arguments)
      if (!input.checked) {
        this.$refs.filteranswer[index].classList.add('inactive')
      } else {
        this.$refs.filteranswer[index].classList.remove('inactive')
      }
    },

    getResults() {
      return this.$refs.input.every(function(e) {
        return e.checked === false
      })
    },

    // disable audience inputs
    disable() {
      this.disabled = true
      utils.changeClass(this.$refs.input, 'disabled', 'add')
      utils.changeClass(this.$refs.filteranswer, 'disabled', 'add')
    },

    // enable audience inputs
    enable() {
      this.$nextTick(() => {
        this.disabled = false
        if (this.audienceId === 0) {
          this.addAllAnswers()
        }
        utils.changeClass(this.$refs.input, 'disabled', 'remove')
        utils.changeClass(this.$refs.filteranswer, 'disabled', 'remove')
      })
    },

    // set all inputs true
    addAllAnswers() {
      this.$refs.input.map(input => (input.checked = true))
      utils.changeClass(this.$refs.filteranswer, 'inactive', 'remove')
      // this.$emit('update-question', this.createQuestionObject(this.$refs.input))
    },
    createQuestionObject(refs) {
      return Object.assign(
        {},
        { question_id: this.id },
        { Answers: this.setAllChecked(refs) }
      )
    },

    // return checked inputs for audience
    setAllChecked(inputs) {
      // return inputs.map(input => (input.checked = true))
    }
  }
}
</script>
<style lang='scss' scoped>
@import '@/assets/css/_custom_checkbox.scss';
.overlay {
  display: none;
  &.disabled {
    display: block;
  }
}

.filter-answer {
  transition: all 1ms;

  &.inactive {
    opacity: 0.5;
  }
  &.disabled {
    pointer-events: none;
    opacity: 1;
    .v-input--selection-controls__ripple:before {
      background: white;
      border-radius: 0;
      content: '-';
      position: absolute;
      opacity: 1;
      width: 100%;
      height: 13px;
      top: 11px;
    }
  }
  input {
    &.inactive {
      opacity: 0.5;
    }
    &.disabled {
      opacity: 0.5;
      &:before {
        background: white;
        border-radius: 0;
        position: absolute;
        opacity: 1;
        width: 100%;
        height: 3px;
        top: 11px;
      }
    }
  }
  .v-label {
    color: white !important;
  }
  .v-icon {
    color: white !important;
  }
}
.styled-checkbox {
  .v-label {
    color: white !important;
  }
  .v-icon {
    color: white !important;
  }
}
</style>
