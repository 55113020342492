var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filterpanel" },
    [
      _c(
        "v-flex",
        { staticClass: "pl-3 pr-3 pb-3 pt-3", attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            {
              staticClass: "mb-2",
              attrs: {
                "align-center": "",
                "justify-space-between": "",
                row: ""
              }
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "font-weight-bold",
                  staticStyle: { color: "white" }
                },
                [_vm._v("\n        Filter results\n      ")]
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { staticClass: "text-xs-right" },
                [
                  _c(
                    "v-layout",
                    { staticClass: "info-btn", attrs: { right: "" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { left: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "div",
                                    _vm._g(
                                      {
                                        staticClass: "custom-info",
                                        attrs: { dark: "", "max-width": "50" }
                                      },
                                      on
                                    )
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          [
                            _c("p", { staticStyle: { "max-width": "200px" } }, [
                              _vm._v(
                                "\n                Create segments based on responses from demographic and\n                psychographic questions. If you asked for respondents’ ages,\n                you can create a segment that shows only respondents of a\n                certain age.\n              "
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticStyle: { "max-width": "200px" } }, [
                              _vm._v(
                                "\n                Once you’ve defined your segment, use another question to\n                compare sub-segments. For example, within your age segment,\n                you could sub-segment using a gender or income question.\n              "
                              )
                            ])
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticClass: "bg-black", attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            {
              staticClass: "mb-3 pt-2 pb-2 pl-3 pr-3",
              staticStyle: { background: "#333333" },
              attrs: {
                "align-center": "",
                "justify-space-between": "",
                row: ""
              }
            },
            [
              _c("v-flex", { staticClass: "text-18 font-weight-bold" }, [
                _vm._v("\n        Your segment\n      ")
              ]),
              _vm._v(" "),
              _vm.brandInfo.is_editable
                ? _c(
                    "v-flex",
                    { staticClass: "text-xs-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-btn",
                          staticStyle: { "min-width": "60px" },
                          attrs: { outline: "", small: "", color: "white" },
                          on: {
                            click: function($event) {
                              return _vm.showMyAudience($event)
                            }
                          }
                        },
                        [_vm._v("\n          Edit\n        ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            {
              staticClass: "pt-2 mb-2 ml-3 mr-3",
              attrs: { wrap: "", "align-center": "" }
            },
            [
              _c(
                "v-flex",
                {
                  staticClass: "font-weight-bold",
                  attrs: { xs12: "", "d-flex": "" }
                },
                [_c("FilterSelect")],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {},
                [
                  _c(
                    "v-expansion-panel",
                    {
                      staticClass: "segment-detail-expansion-panel",
                      attrs: { dark: "" }
                    },
                    _vm._l(1, function(item, i) {
                      return _c(
                        "v-expansion-panel-content",
                        {
                          key: i,
                          attrs: { id: "expansionpanel" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "segment-details-label",
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _vm._v(
                                          "\n                Segment details\n              "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "simplebar",
                            {
                              ref: "simplebar",
                              refInFor: true,
                              staticClass: "simplebar1",
                              attrs: {
                                id: "simplebar1",
                                "data-simplebar-auto-hide": "true"
                              }
                            },
                            [
                              _c("no-ssr", [
                                _c(
                                  "div",
                                  _vm._l(_vm.surveyQuestions, function(
                                    question,
                                    index
                                  ) {
                                    return _c(
                                      "v-card",
                                      {
                                        key: index,
                                        staticStyle: {
                                          "border-bottom": "1px solid #333"
                                        },
                                        attrs: { flat: "" }
                                      },
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                staticStyle: {
                                                  color: "#999999",
                                                  "text-align": "left"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "question-text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(question.text)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("DropdownAnswers", {
                                              attrs: {
                                                id: question.id,
                                                answers: question.SurveyAnswers
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "no-ssr",
            [
              _c(
                "v-layout",
                {
                  staticClass: "mb-3 pt-2 pb-2 pl-3 pr-3",
                  staticStyle: { background: "#333333" },
                  attrs: {
                    "align-center": "",
                    "justify-space-between": "",
                    row: ""
                  }
                },
                [
                  _c("v-flex", { staticClass: "text-18 font-weight-bold" }, [
                    _vm._v("\n          Compare sub-segments\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "text-xs-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "filter-btn",
                          staticStyle: { "min-width": "60px" },
                          attrs: {
                            id: "segments",
                            outline: "",
                            small: "",
                            color: "white"
                          },
                          on: {
                            click: function($event) {
                              return _vm.showMySegments()
                            }
                          }
                        },
                        [_vm._v("\n            Edit\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { staticClass: "filter-container" },
                [
                  _c("v-flex", [
                    _vm.currentQuestionId == 0
                      ? _c(
                          "div",
                          { staticClass: "segmentname grey--text caption" },
                          [
                            _vm._v(
                              "\n            *no segment selected\n          "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm.currentQuestionId == 0
                    ? _c(
                        "v-flex",
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "pl-3 pr-3 pt-2",
                              attrs: { "justify-space-between": "" }
                            },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "audience-name-container" },
                                [
                                  _c("span", {
                                    staticClass: "circle small-circle",
                                    style: {
                                      backgroundColor: _vm.backgroundColor(0)
                                    },
                                    attrs: { "data-id": 0 }
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "audience-name" }, [
                                    _vm._v(_vm._s(_vm.audienceName))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "font-weight-bold",
                                  staticStyle: { float: "right" }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getFilteredResponsesCount) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-content", {
                    staticClass: "pt-0 pl-4 pr-4 mb-4 font-weight-bold",
                    domProps: { textContent: _vm._s(_vm.currentQuestion) }
                  }),
                  _vm._v(" "),
                  _c(
                    "simplebar",
                    {
                      staticClass: "simplebar2 pl-4 pr-4",
                      attrs: {
                        id: "simplebar2",
                        "data-simplebar-auto-hide": "false"
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "filter-datapoint-html" } },
                        [
                          _vm.grouped.length
                            ? _c(
                                "v-flex",
                                { staticClass: "pt-0", attrs: { xs12: "" } },
                                _vm._l(_vm.grouped, function(group, index) {
                                  return _c(
                                    "v-content",
                                    {
                                      key: index,
                                      staticClass: "pt-0 pl-2 pr-2 mb-2 group",
                                      staticStyle: {
                                        "padding-left": "30px !important"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "justify-space-between": "",
                                            row: ""
                                          }
                                        },
                                        [
                                          group.Answers.length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "mb-2 grp-name",
                                                  attrs: {
                                                    id: group.group_name
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(group.group_name) +
                                                      "\n                  "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "font-weight-bold" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(group.total) +
                                                  "\n                  "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        [
                                          group.Answers.length
                                            ? _c("span", {
                                                staticClass:
                                                  "circle small-circle",
                                                staticStyle: {
                                                  width: "15px",
                                                  height: "15px",
                                                  "border-radius": "50%",
                                                  display: "inline-block",
                                                  "margin-right": "10px",
                                                  position: "absolute",
                                                  top: "0",
                                                  left: "-30px"
                                                },
                                                style: {
                                                  backgroundColor: _vm.backgroundColor(
                                                    index
                                                  )
                                                },
                                                attrs: { "data-id": index }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm._l(group.Answers, function(
                                            answer,
                                            ind
                                          ) {
                                            return _c("v-flex", { key: ind }, [
                                              answer.checked == true
                                                ? _c(
                                                    "span",
                                                    {
                                                      ref: "rolloverbtn",
                                                      refInFor: true,
                                                      staticClass:
                                                        "rolloverbtn",
                                                      attrs: { id: answer.id },
                                                      on: {
                                                        mouseover: function(
                                                          $event
                                                        ) {
                                                          return _vm.onAnswerOver(
                                                            index,
                                                            answer
                                                          )
                                                        },
                                                        mouseleave:
                                                          _vm.onAnswerOut
                                                      }
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "target"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                answer.text
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "target font-weight-bold"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                answer.respondent_count
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ])
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            {
                              attrs: { column: "" },
                              on: { mouseleave: _vm.onPanelOut }
                            },
                            _vm._l(_vm.ungrouped, function(answer, index) {
                              return _c(
                                "v-flex",
                                {
                                  key: index,
                                  staticClass: "mb-2",
                                  attrs: { "justify-space-around": "" }
                                },
                                [
                                  _c("Subsegments", {
                                    attrs: {
                                      "enable-scroll": _vm.enableScroll,
                                      answer: answer,
                                      index: index,
                                      "grouped-length": _vm.grouped.length,
                                      "background-color": _vm.backgroundColor(
                                        index + _vm.grouped.length
                                      )
                                    }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("FilterProgress", {
            attrs: {
              value: _vm.getFilteredResponsesCount,
              total: _vm.getActiveResponseCount
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }