import { render, staticRenderFns } from "./DropdownMenu.vue?vue&type=template&id=01c4aa40&scoped=true&"
import script from "./DropdownMenu.vue?vue&type=script&lang=js&"
export * from "./DropdownMenu.vue?vue&type=script&lang=js&"
import style0 from "./DropdownMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./DropdownMenu.vue?vue&type=style&index=1&id=01c4aa40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c4aa40",
  null
  
)

/* vuetify-loader */
import installComponents from "!/var/www/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib'
import { VListTileContent } from 'vuetify/lib'
import { VListTileTitle } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
installComponents(component, {VIcon,VListTileContent,VListTileTitle,VSelect})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('01c4aa40', component.options)
    } else {
      api.reload('01c4aa40', component.options)
    }
    module.hot.accept("./DropdownMenu.vue?vue&type=template&id=01c4aa40&scoped=true&", function () {
      api.rerender('01c4aa40', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/ui/DropdownMenu.vue"
export default component.exports