<template>
  <v-dialog v-model="dialog" width="700">
    <v-btn slot="activator" icon>
      <v-icon id="edit-survey">
        edit
      </v-icon>
    </v-btn>
    <v-card>
      <v-form ref="form">
        <h2>Edit Survey</h2>
        <p>Enter a new title and description below.</p>
        <TextField
          v-model="name"
          label="Enter survey name"
          :rules="nameRules"
          :error-messages="nameErrors"
          autofocus
          placeholder="Enter survey name"
          validate-on-blur
          required
        />
        <Textarea
          v-model="description"
          label="Survey description"
          solo
          placeholder="Add a description for your survey here."
          required
          validate-on-blur
          :rules="descriptionRules"
          :error-messages="descriptionErrors"
        />
        <Button variant="secondary" @click="submit">
          submit
        </Button>
        <Button @click="dialog = false">
          cancel
        </Button>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { notEmpty, max6000, max255 } from '@/js/validations'
import Button from '@/components/ui/Button'
import TextField from '@/components/ui/TextField'
import Textarea from '@/components/ui/Textarea'
export default {
  components: {
    Button,
    TextField,
    Textarea
  },
  layout: 'survey',
  middleware: ['authenticated'],
  data: () => ({
    dialog: false,
    name: '',
    description: '',
    unique: false,
    nameRules: [notEmpty, max255],
    nameErrors: [],
    descriptionRules: [max6000],
    descriptionErrors: []
  }),
  computed: {
    ...mapGetters({
      brandInfo: 'brandInfo'
    }),
    _name: {
      get: function() {
        return this.$store.state.survey.title
      },
      set: function() {}
    },
    _description() {
      return this.$store.state.survey.description
    }
  },
  watch: {
    _name: function() {
      this.$set(this, 'name', this._name)
    },
    _description() {
      this.$set(this, 'description', this._description)
    }
  },
  mounted() {
    this.$set(this, 'name', this._name)
    this.$set(this, 'description', this._description)
  },
  methods: {
    submit() {
      this.$set(this, 'nameErrors', [])
      if (this.$refs.form.validate()) {
        // if form data is valid
        const payload = {
          survey_id: this.$store.state.survey.id,
          name: this.name,
          description: this.description
        }
        this.$api.survey.updateSurveyNameDescription(payload).then(data => {
          if (data && data.data) {
            // if there's an error
            const { error } = data.data
            this.nameErrors.push(error) // display error
          } else {
            const { Survey } = data
            this.$store.dispatch('survey/setTitle', Survey.name)
            this.$set(this, 'dialog', false)
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
label.bold {
  color: #000;
  font-weight: bold;
}
form {
  padding: 1.5em;
}
#edit-survey {
  @include headline-small-soft;
  font-family: 'Material Icons';
}

// .survey-description {
//   margin-top: 1000px;
// }
</style>
