<template>
  <v-tabs class="tabs-container survey-navigation-container" slider-color="secondary">
    <v-tab
      v-for="{ id, name, disabled, icon, svgIcon } in sections"
      :key="id"
      :to="`/dashboard/${$route.params.brand}/surveys/${$route.params.survey}/${id}`"
      ripple
      :disabled="disabled"
      @change="onChange(id)"
    >
      <v-icon v-if="icon" class="icon">
        {{ icon }}
      </v-icon>
      <span v-if="svgIcon" class="svg-icon-container">
        <img :src="svgIcon">
      </span>
      {{ name }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapActions } from 'vuex'
import mapIcon from '@/components/icons/_outline_map.svg'

export default {
  props: {
    currentSection: {
      default: '',
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    sections() {
      return [
        {
          name: 'Design survey',
          id: 'design',
          disabled: false
        },
        {
          name: 'Preview survey',
          id: 'preview',
          disabled: false
        },
        {
          name: 'Gather responses',
          id: 'gather_responses',
          disabled: false
        },
        {
          name: 'Analyze journey',
          id: 'analyze_journey',
          disabled: false,
          svgIcon: mapIcon
        },
        {
          name: 'Analyze audience',
          id: 'analyze_audience',
          disabled: false,
          icon: 'person_outline'
        }
      ]
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      setSection: 'setSection'
    }),
    onChange(id) {
      this.setSection(id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';

.survey-navigation-container {
  position: fixed;
  top: 48px;
  left: 0;
  width: 100%;
  padding-left: $leftnav-width + 12px;
  z-index: 2;
  background-color: #fff;
}

.v-btn {
  border-bottom: 3px solid transparent;
}
.v-btn--active {
  border-bottom-color: color('primary');
}
.icon {
  color: inherit;
  margin-right: 5px;
  font-size: 20px;
}

.svg-icon-container {
  width: 20px;
  margin-right: 5px;
  position: relative;
  top: 2px;

  img {
    width: 100%;
  }
}
</style>

<style lang="scss">
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.tabs-container {
  padding-left: 12px;
  .v-tabs__slider {
    height: 3px;
  }

  .v-tabs__item {
    text-transform: none;

    &:not(.v-tabs__item--active) {
      opacity: 1;
      color: color(font-medium-light);

      .svg-icon-container {
        opacity: 0.4;
      }
    }
  }
}
</style>
