var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { "fill-rule": "evenodd", "stroke-width": "1.25" } },
    [
      _c("path", {
        attrs: { d: "M8 3h14M8 11h14M8 19h14", "stroke-linecap": "round" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M5 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM5 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M5 3a2 2 0 1 1-4 0 2 2 0 0 1 4 0z", fill: "#000" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }