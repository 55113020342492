var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.enableScroll
    ? _c("div", [
        _c(
          "span",
          {
            directives: [
              {
                name: "scroll-to",
                rawName: "v-scroll-to",
                value: "#anchor-" + _vm.index,
                expression: "`#anchor-${index}`"
              }
            ],
            ref: "rolloverbtn",
            staticClass: "rolloverbtn scroll-to",
            attrs: { id: _vm.id, href: "#" },
            on: {
              mouseover: function($event) {
                return _vm.onAnswerOver(_vm.index + _vm.groupedLength, _vm.id)
              },
              mouseleave: _vm.onAnswerOut
            }
          },
          [
            _c("div", { staticClass: "response-container" }, [
              _c("span", {
                staticClass: "circle small-circle",
                staticStyle: {
                  width: "15px",
                  height: "15px",
                  "border-radius": "50%",
                  display: "inline-block",
                  "margin-right": "10px"
                },
                style: { backgroundColor: _vm.backgroundColor }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "target", attrs: { id: _vm.text } }, [
                _vm._v(_vm._s(_vm.text))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "target font-weight-bold" }, [
              _vm._v(_vm._s(_vm.answer.respondent_count))
            ])
          ]
        )
      ])
    : _c("div", [
        _c(
          "span",
          {
            staticClass: "rolloverbtn",
            attrs: { id: _vm.id },
            on: {
              mouseover: function($event) {
                return _vm.onAnswerOver(_vm.index + _vm.groupedLength, _vm.id)
              },
              mouseleave: _vm.onAnswerOut
            }
          },
          [
            _c("div", { staticClass: "response-container" }, [
              _c("span", {
                staticClass: "circle small-circle",
                staticStyle: {
                  width: "15px",
                  height: "15px",
                  "border-radius": "50%",
                  display: "inline-block",
                  "margin-right": "10px"
                },
                style: { backgroundColor: _vm.backgroundColor }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "target", attrs: { id: _vm.text } }, [
                _vm._v(_vm._s(_vm.text))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "target font-weight-bold" }, [
              _vm._v(_vm._s(_vm.answer.respondent_count))
            ])
          ]
        )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }