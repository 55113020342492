import Vue from 'vue'

/* eslint-disable prettier/prettier */

export const state = () => ({
  survey: {},
  surveyQuestions: [],
  currentQuestionId: 0,
  currentQuestion: '',
  audienceFilters: [],
  audienceList: [],
  audienceGroup: [],
  audienceName: 'All respondents',
  audienceId: 0,
  journeyQuestions: [],
  segmentationQuestions: [],
  audienceQuestions: [],
  segmentQuestionFilterGroups: [],
  segmentQuestionFiltersUngrouped: [],
  segGroups: [],
  currentView: '',
  analyzeJourneyView: 'hero',
  compareSegmentsIds: [],
  compareSegmentsPanelData: {
    SegmentGroups: [],
    activeSegmentCount: 0,
    checkedSegmentCount: 0
  },
  loading: false,
  active_responses_count: 0,
  filtered_responses_count: 0,
  segmented_response_count: 0,
  journeyChartData: [],
  // questionChartData: {},
  questionChartSegments: [],
  questionChartMaxRespondentCount: 0,
  selectedQuestionIndex: null,
  chartEmotion: {},
  compareViewMount: ''
})
export const mutations = {
  setSelectedQuestionIndex(state, index) {
    state.selectedQuestionIndex = index
  },
  setJourneyChartData(state, chartData) {
    Vue.set(state, 'journeyChartData', chartData)
  },
  setQuestionChartSegments(state, segments) {
    Vue.set(state, 'questionChartSegments', segments)
  },
  setQuestionChartMaxRespondentCount(state, count) {
    state.questionChartMaxRespondentCount = count
  },
  // setQuestionChartData(state, chartData) {
  //   Vue.set(state, 'questionChartData', chartData)
  // },
  clearJourneyChartData(state) {
    Vue.set(state, 'journeyChartData', [])
  },
  clearQuestionChartData() {
    Vue.set(state, 'questionChartSegments', [])
    Vue.set(state, 'questionChartMaxRespondentCount', 0)
  },
  active_responses_count(state, num = 0) {
    state.active_responses_count = num
  },
  setFilteredResponsesCount(state, num) {
    state.filtered_responses_count = num
  },
  segmented_response_count(state, num = 0) {
    state.segmented_response_count = num
  },
  setSegGroups(state, arr = []) {
    state.segGroups = arr
  },
  setAudience(state, filters = []) {
    state.audienceFilters = filters
  },
  setAudienceId(state, id) {
    // console.log('setAudienceId = ', JSON.stringify(id, null, 2))
    state.audienceId = id
  },
  audienceName(state, str) {
    state.audienceName = str
  },
  currentView(state, str = '') {
    state.currentView = str
  },
  setAnalyzeJourneyView(state, str = '') {
    state.analyzeJourneyView = str
  },
  setCompareSegmentsIds(state, ids = []) {
    state.compareSegmentsIds = ids
  },
  setCompareSegmentsPanelData(state, obj = {}) {
    state.compareSegmentsPanelData = obj
  },
  setAudienceList(state, arr = []) {
    // console.log('setAudienceList = ',JSON.stringify(arr,null,2))
    state.audienceList = arr
  },
  setCurrentQuestionId(state, id) {
    state.currentQuestionId = id
  },
  setCurrentQuestion(state, id) {
    state.currentQuestion = id
  },
  segmentQuestionFilterGroups(state, arr = []) {
    // console.log('segmentQuestionFilterGroups = ',JSON.stringify(arr,null,2))
    state.segmentQuestionFilterGroups = arr
  },
  segmentQuestionFiltersUngrouped(state, arr = []) {
    // console.log('segmentQuestionFiltersUngrouped = ',JSON.stringify(arr,null,2))
    state.segmentQuestionFiltersUngrouped = arr
  },
  segmentationQuestions(state, arr = []) {
    state.segmentationQuestions = arr
  },
  audienceQuestions(state, arr = []) {
    /*********
      Manipulate data to add selected items when Question is not part of filter
        **********/
    arr.forEach(q => {
      // console.log(q, 'question_id')
      const checkFilter = q.SurveyAnswers.filter(q => {
        return q.selected !== true
      })
      /*********
      If all are not selected make all selected
        **********/
      if (checkFilter.length === q.SurveyAnswers.length) {
        q.SurveyAnswers.map(a => {
          a.selected = true
        })
      }
    })
    state.audienceQuestions = arr
  },
  journeyQuestions(state, arr = []) {
    state.journeyQuestions = arr
  },
  survey(state, obj) {
    state.survey = obj
  },
  surveyQuestions(state, arr = []) {
    state.surveyQuestions = arr
  },
  loading(state, bool) {
    state.loading = bool
  },
  setCompareViewMount(state, str = '') {
    state.compareViewMount = str
  },
  setChartEmotion(state, obj) {
    Vue.set(state, 'chartEmotion', obj)
  }
}

export const actions = {
  setSelectedQuestionIndex({ commit }, index) {
    commit('setSelectedQuestionIndex', index)
  },
  setJourneyChartData({ commit }, chartData) {
    commit('setJourneyChartData', chartData)
  },
  setQuestionChartSegments({ commit }, segments) {
    commit('setQuestionChartSegments', segments)
  },
  setQuestionChartMaxRespondentCount({ commit }, count) {
    commit('setQuestionChartMaxRespondentCount', count)
  },
  setChartEmotion({ commit }, obj) {
    commit('setChartEmotion', obj)
  },
  // setQuestionChartData({ commit }, chartData) {
  //   commit('setQuestionChartData', chartData)
  // },
  clearJourneyChartData({ commit }) {
    commit('clearJourneyChartData')
  },
  clearQuestionChartData({ commit }) {
    commit('clearQuestionChartData')
  },
  setAudienceFilters({ commit }, arr = []) {
    commit('setAudience', arr)
  },
  setAudienceQuestions({ commit }, arr = []) {
    commit('audienceQuestions', arr)
  },
  setSegGroups({ commit }, arr = []) {
    commit('setSegGroups', arr)
  },
  setActiveResponseCount({ commit }, num = 0) {
    commit('active_responses_count', num)
  },
  setFilteredResponsesCount({ commit }, num = 0) {
    commit('setFilteredResponsesCount', num)
  },
  setSegmentedResponseCount({ commit }, num = 0) {
    commit('segmented_response_count', num)
  },
  setAudienceId({ commit }, id) {
    commit('setAudienceId', id)
  },
  setAudienceList({ commit }, arr) {
    commit('setAudienceList', arr)
  },
  setAudienceName({ commit }, str = 'All respondents') {
    commit('audienceName', str)
  },
  setSegmentationFilters({ commit }, val) {
    commit('setFilters', val)
  },
  setCurrentQuestionId({ commit }, val = 0) {
    commit('setCurrentQuestionId', val)
  },
  setCurrentQuestion({ commit }, str = '') {
    commit('setCurrentQuestion', str)
  },
  setSegmentQuestions({ commit }, val = []) {
    commit('segmentationQuestions', val)
  },
  setCurrentView({ commit }, str = '') {
    commit('currentView', str)
  },
  setAnalyzeJourneyView({ commit }, str = '') {
    commit('setAnalyzeJourneyView', str)
  },
  setCompareSegmentsIds({ commit }, str = '') {
    commit('setCompareSegmentsIds', str)
  },
  setCompareSegmentsPanelData({ commit }, obj = {}) {
    commit('setCompareSegmentsPanelData', obj)
  },
  setCurrentQuestionFilterGroups({ commit }, arr = []) {
    commit('segmentQuestionFilterGroups', arr)
  },
  setSegmentQuestionFilterUngrouped({ commit }, arr = []) {
    commit('segmentQuestionFiltersUngrouped', arr)
  },
  setLoading({ commit }, bool) {
    commit('loading', bool)
  },
  setCompareViewMount({ commit }, str) {
    commit('setCompareViewMount', str)
  },
  clearSegments({ commit }) {
    commit('segmentQuestionFilterGroups')
    commit('segmentQuestionFiltersUngrouped')
  },

  loadSurvey({ dispatch, commit }, survey) {
    const segmentationQuestions = survey.SurveyQuestions.filter(
      question => question.type_id !== 3
    )
    // console.log('store, analyzeSurvey, loadSurvey, segmentationQuestions:', segmentationQuestions)
    const journeyQuestions = survey.SurveyQuestions.filter(
      question => question.type_id === 3
    )
    commit('survey', survey)
    commit('active_responses_count', survey.active_responses_count)
    commit('segmentationQuestions', segmentationQuestions)
    commit('surveyQuestions', segmentationQuestions)
    commit('journeyQuestions', journeyQuestions)
    dispatch('fetchAudienceList', survey.id)
  },

  // api call to save audience. than chain to load audience group
  loadAudienceAndGroupFilters: ({ dispatch }, payload) =>
    dispatch('loadAudienceGroup', payload).then(data => {
      const obj = {
        hashcode: payload.hashcode,
        Filters: data.SurveyRespondentGroup.Filters
      }
      return dispatch('loadAudienceResponseGroupFilters', obj)
    }),

  saveAndUpdateAudience: ({ dispatch }, payload) =>
    dispatch('updateAudience', payload).then(data => {
      return [
        dispatch('loadAudienceResponseGroupFilters', payload),
        dispatch('fetchAudienceList', data.SurveyRespondentGroup.survey_id)
      ]
    }),

  saveAndLoadAudience: ({ dispatch }, payload) =>
    dispatch('saveAudience', payload).then(data => {
      return [
        dispatch('loadAudienceGroup', { id: data.SurveyRespondentGroup.id }),
        dispatch('loadAudienceResponseGroupFilters', payload),
        dispatch('fetchAudienceList', payload.survey_id)
      ]
    }),

  saveAudience({ commit }, payload) {
    return this.$api.survey.postAudienceGroup(payload).then(data => {
      return data
    })
  },

  // Delete and audience
  deleteAudience({ dispatch }, payload) {
    return this.$api.survey
      .deleteAudienceGroup(payload)
      .then(data => {
        return data
      })
      .then(dispatch('fetchAudienceList'))
  },

  //  Update a current audience group
  updateAudience({ commit }, payload) {
    return this.$api.survey.updateAudienceGroup(payload).then(data => {
      if (data !== undefined) {
        // commit('setCurrentQuestionId', 0)
        commit('audienceName', data.SurveyRespondentGroup.name)
        // commit('setCurrentQuestion')
        // commit('setSegGroups', [])
        return data
      }
    })
  },

  // api call to load audience list
  fetchAudienceList({ commit }, id) {
    return this.$api.survey.getAudienceList(id).then(data => {
      if (data !== undefined) {
        commit('setAudienceList', data.SurveyRespondentGroupList)
        return data
      }
    })
  },

  // api call to load audience
  loadAudienceGroup({ dispatch, commit }, obj) {
    // dispatch('resetAudience')
    return this.$api.survey.getAudience(obj.id).then(data => {
      commit('setAudience', data.SurveyRespondentGroup.Filters)
      commit('setAudienceId', data.SurveyRespondentGroup.id)
      commit('audienceName', data.SurveyRespondentGroup.name)
      return data
    })
  },

  loadAudienceResponseGroupFilters({ commit }, payload) {
    return this.$api.survey
      .surveyRespondentsGroupResponseFilters(payload)
      .then(data => {
        commit('audienceQuestions', data.Survey.SurveyQuestions)
        commit('active_responses_count', data.Survey.active_responses_count)
        commit(
          'setFilteredResponsesCount',
          data.Survey.filtered_responses_count
        )
        commit('loading', false)
        return data
      })
  },

  // reset the survey to original questions
  resetSurvey({ dispatch, commit }, questions) {
    console.log('store, analyzeSurvey, resetSurvey, questions:', questions)
    commit('segmentationQuestions', questions)
    dispatch('removeSegmentation')
  },
  removeSegmentation({ commit }) {
    commit('setAudienceId', 0)
    commit('audienceName', 'All respondents')
    commit('setCurrentQuestionId', 0)
    commit('setCurrentQuestion')
    commit('setSegGroups')
    commit('segmentQuestionFilterGroups')
    commit('segmentQuestionFiltersUngrouped')
  },
  resetAudience({ commit }) {
    commit('setCurrentQuestionId', 0)
    commit('setCurrentQuestion')
    commit('setSegGroups')
    commit('segmentQuestionFilterGroups')
    commit('segmentQuestionFiltersUngrouped')
  }
}

export const getters = {
  getSurvey: state => state.survey,
  getActiveResponseCount: state => state.active_responses_count,
  getSegmentedResponseCount: state => state.segmented_response_count,
  getFilteredResponsesCount: state => state.filtered_responses_count,
  getAudienceFilters: state => state.audienceFilters,
  getAudienceName: state => state.audienceName,
  getAudienceList: state => state.audienceList,
  getCompareSegmentsIds: state => state.compareSegmentsIds,
  getCompareSegmentsPanelData: state => state.compareSegmentsPanelData,
  getCurrentQuestionId: state => state.currentQuestionId,
  getCurrentQuestion: state => state.currentQuestion,
  getJourneyQuestions: state => state.journeyQuestions,
  getSurveyQuestions: state => state.surveyQuestions,
  getSegmentionQuestions: state => state.segmentationQuestions, // audienceQuestions
  getAudienceQuestions: state => state.audienceQuestions, // audienceQuestions
  getSegmentQuestionFilters: state => state.segmentQuestionFilters,
  getAudienceId: state => state.audienceId,
  getSegGroups: state => state.segGroups,
  getCurrentView: state => state.currentView,
  getAnalyzeJourneyView : state => state.analyzeJourneyView,
  getSegmentQuestionFilterGroups: state => state.segmentQuestionFilterGroups,
  getSegmentQuestionFiltersUngrouped: state =>
    state.segmentQuestionFiltersUngrouped,
  getLoading: state => state.loading,
  getCompareViewMount: state => state.compareViewMount,
  getJourneyChartData: state => state.journeyChartData,
  // getQuestionChartData: state => state.questionChartData
  getQuestionChartSegments: state => state.questionChartSegments,
  getQuestionChartMaxRespondentCount: state =>
    state.questionChartMaxRespondentCount,
  getSelectedQuestionIndex: state => state.selectedQuestionIndex,
  getChartEmotion: state => state.chartEmotion,
  getDatapointSegmentQuestions: (state, getters) => {
    const questions = getters.getAudienceQuestions.filter(question => {
      const { SurveyAnswers } = question
      return SurveyAnswers.some(answer => answer.selected === false)
    })
    const questionsTrueAnswers = questions.map(question => {
      const trueAnswers = question.SurveyAnswers.filter(
        answer => answer.selected === true
      )
      return {
        ...question,
        SurveyAnswers: trueAnswers
      }
    })
    // only return results if 'All respondents' is not selected as segmentation
    return getters.getAudienceName !== 'All respondents'
      ? questionsTrueAnswers
      : []
  }
}
