import Api from '@/services/Api'

export default class ParentBrandService extends Api {
  // Parent_Brands - Get list of parent brands
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Parent_Brands-GetParentBrands
  getParentBrands() {
    return this.$axios.get('parents').then(response => response.data)
  }

  // Parent_Brands - Create parent brand
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Parent_Brands-CreateParentBrand
  createParentBrand(payload) {
    return this.$axios
      .post('parents', payload)
      .then(response => {
        // console.log('deleteUserFromWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }
}
