<template>
  <v-app light>
    <v-layout>
      <v-flex class="left-nav-container">
        <LeftNav />
      </v-flex>
      <v-flex>
        <TopNav />
        <SurveyNavigation />
        <!-- <v-content class="content__wrap"> -->
        <v-layout>
          <v-flex xs9 class="nuxt-content-container">
            <nuxt />
          </v-flex>
          <!-- </v-content> -->
          <v-flex d-flex xs3 class="fixed-container">
            <v-layout>
              <FilterPanel />
            </v-layout>
          </v-flex>
          <v-flex class="holder">
            <transition name="slide-fade">
              <v-flex
                v-show="currentView == 'SEGMENT'"
                xs12
                class="panel segments"
              >
                <SegmentFilter />
              </v-flex>
            </transition>
            <transition name="slide-fade">
              <v-flex
                v-show="currentView == 'AUDIENCE'"
                xs12
                class="panel audience"
              >
                <AudienceFilter />
              </v-flex>
            </transition>
            <transition name="slide-fade">
              <v-flex
                v-show="currentView == 'COMPARE'"
                xs3
                class="panel segments"
                style="margin-top: 65px"
              >
                <CompareSegments />
              </v-flex>
            </transition>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeftNav from '@/components/layouts/LeftNav'
import TopNav from '@/components/layouts/TopNav'
import SurveyNavigation from '@/components/pages/survey/SurveyNavigation'
import FilterPanel from '@/components/pages/survey/filters/FilterPanel'
import SegmentFilter from '@/components/pages/survey/filters/SegmentFilter'
import AudienceFilter from '@/components/pages/survey/filters/AudienceFilter'
import CompareSegments from '@/components/pages/survey/filters/CompareSegments'
import 'simplebar/dist/simplebar.min.css'

export default {
  middleware: ['authenticated', 'checkWorkspaceIdMiddleware'],
  components: {
    TopNav,
    LeftNav,
    SurveyNavigation,
    FilterPanel,
    AudienceFilter,
    SegmentFilter,
    CompareSegments
  },
  computed: {
    ...mapGetters({
      currentView: 'analyzeSurvey/getCurrentView',
      getLayout: 'analyzeSurvey/getLayout'
    }),
    renderNav: function() {
      const renderNav =
        this.$route.name !== 'dashboard' && this.$store.state.auth
      return renderNav
    },
    showFilters: function() {
      if (
        this.$route.name !== 'dashboard-brand-surveys-survey-analyze_journey' &&
        this.$route.name !== 'dashboard-brand-surveys-survey-analyze_audience'
      ) {
        return false
      } else {
        return true
      }
    }
  },
  updated() {},
  mounted() {
    this.$setWorkspaceId()
  },
  beforeMount: function() {
    this.$checkWorkspaceId()
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      // toggleMiniNav: 'nav/toggleMiniNav'
      removeSegmentation: 'analyzeSurvey/removeSegmentation',
      resetView: 'analyzeSurvey/setCurrentView'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
.left-nav-container {
  width: auto;
  height: 100vh;
  z-index: 6;
  flex: 0;
}

.nuxt-content-container {
  padding: 0 $spacing-base $spacing-base
    calc(#{$spacing-base} + #{$leftnav-width});
}
</style>


<style lang="scss">
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_journeylayout.scss';
#nav-offset {
  margin-left: $leftnav-width;
}
// .simplebar-scrollbar:before {
//   position: absolute;
//   content: '';
//   background: #fff;
//   border-radius: 7px;
//   left: 0;
//   right: 0;
//   opacity: 0;
//   transition: opacity 0.2s linear;
// }
</style>
