import Vue from 'vue'

// constants
const COMPARE_ID = 'compare'
const COMPARE_NAME = 'Compare'
const PAGINATION_LIMIT = 25
const START_INDEX = 1

export const DEFAULT_FILTER_SETTINGS = {
  verticalThreshold: 5,
  selectionMin: 0,
  selectionMax: 700,
  availableMin: 0,
  availableMax: 1000,
  selectedFrequentKeywords: [],
  customKeywords: [],
  customKeywordText: '',
  currentPage: 1
}

export const state = () => ({
  // datasets will each contain a filterSettings property, which holds state of filter panel for that particular dataset, for example - filterSettings: { selectionMax: 0, selectionMin: 0, verticalThreshold: 0, keywords: [] }
  datasets: [],
  currentDatasetIndex: START_INDEX, // this index is used to find the currentDataset as well as to activate current vuetify tab
  comparisonDataset: {
    name: COMPARE_NAME,
    id: COMPARE_ID,
    categories: [],
    filterSettings: DEFAULT_FILTER_SETTINGS
  },
  showBaseline: false
})

export const getters = {
  COMPARE_ID() {
    return COMPARE_ID
  },
  PAGINATION_LIMIT() {
    return PAGINATION_LIMIT
  },
  allDatasets(state) {
    const { datasets, comparisonDataset } = state
    return [comparisonDataset, ...datasets]
  },
  currentPage(state, getters) {
    return getters.currentFilterSettings.currentPage
  },
  currentDataset(state, getters) {
    const { currentDatasetIndex } = state
    return getters.allDatasets[currentDatasetIndex]
  },
  currentFilterSettings(state, getters) {
    const { currentDataset } = getters
    return currentDataset && currentDataset.filterSettings
      ? currentDataset.filterSettings
      : DEFAULT_FILTER_SETTINGS
  },
  customKeywords(state, getters) {
    return getters.currentFilterSettings.customKeywords
  },
  datasetIds(state) {
    return state.datasets.map(({ id }) => ({ id }))
  },
  availableMax(state, getters) {
    return getters.currentFilterSettings.availableMax
  },
  availableMin(state, getters) {
    return getters.currentFilterSettings.availableMin
  },
  totalQuestionsPercentage(state, getters) {
    return getters.currentDataset
      ? getters.currentDataset.total_questions_percentage
      : 0
  },
  questionResultsAmount(state, getters) {
    return getters.currentDataset
      ? getters.currentDataset.total_questions_view
      : 0
  },
  totalQuestionsAmount(state, getters) {
    return getters.currentDataset ? getters.currentDataset.total_questions : 0
  },
  availableFrequentKeywords(state, getters) {
    return getters.currentDataset ? getters.currentDataset.keyword_list : []
  },
  datasetId(state, getters) {
    return getters.currentDataset ? getters.currentDataset.id : null
  }
}

export const mutations = {
  addDataset(state, dataset) {
    const filterSettings = {
      ...DEFAULT_FILTER_SETTINGS,
      availableMin: dataset.index_min * 1,
      availableMax: dataset.index_max * 1,
      selectionMin: dataset.default_index_threshold * 1,
      selectionMax: dataset.index_max * 1,
      verticalThreshold: dataset.default_vertical_threshold * 1
    }
    Vue.set(state, 'datasets', [
      ...state.datasets,
      { ...dataset, filterSettings }
    ])
  },
  removeDataset(state, datasetId) {
    Vue.set(
      state,
      'datasets',
      state.datasets.filter(dataset => dataset.id !== datasetId)
    )
  },
  updateDataset(state, updatedDataset) {
    state.datasets = state.datasets.map(
      dataset => (dataset.id === updatedDataset.id ? updatedDataset : dataset)
    )
  },
  setCurrentDatasetIndex(state, index) {
    state.currentDatasetIndex = index
  },
  clearDatasets(state) {
    console.log('syndicated, store, clearDatasets()')
    Vue.set(state, 'datasets', [])
    state.currentDatasetIndex = START_INDEX
    const comparisonDataset = {
      name: COMPARE_NAME,
      id: COMPARE_ID,
      categories: [],
      filterSettings: DEFAULT_FILTER_SETTINGS
    }
    Vue.set(state, 'comparisonDataset', comparisonDataset)
    // state.comparisonDataset = {
    //   name: COMPARE_NAME,
    //   id: COMPARE_ID,
    //   categories: [],
    //   filterSettings: DEFAULT_FILTER_SETTINGS
    // }
  },
  updateComparisonDataset(state, data) {
    state.comparisonDataset = {
      ...data,
      id: COMPARE_ID,
      name: COMPARE_NAME
    }
  },
  updateShowBaseline(state, val) {
    state.showBaseline = val
  },
  addKeyword(state, val) {
    const { keyword, datasetId } = val
    const allDatasets = [state.comparisonDataset, ...state.datasets]
    const targetDataset = allDatasets.find(dataset => dataset.id === datasetId)
    const keywordObj = { text: keyword }
    if (targetDataset) {
      Vue.set(targetDataset.filterSettings, 'customKeywords', [
        ...targetDataset.filterSettings.customKeywords,
        keywordObj
      ])
    }
  },
  updateKeywordText(state, val) {
    const { text, datasetId } = val
    const allDatasets = [state.comparisonDataset, ...state.datasets]

    const targetDataset = allDatasets.find(dataset => dataset.id === datasetId)
    if (targetDataset) {
      Vue.set(targetDataset.filterSettings, 'customKeywordText', text)
    }
  },
  removeKeyword(state, val) {
    const { keyword, datasetId } = val
    const allDatasets = [state.comparisonDataset, ...state.datasets]
    const targetDataset = allDatasets.find(dataset => dataset.id === datasetId)
    Vue.set(
      targetDataset.filterSettings,
      'customKeywords',
      targetDataset.filterSettings.customKeywords.filter(
        _keyword => _keyword !== keyword
      )
    )
  },
  updateCurrentPage(state, val) {
    const { currentPage, datasetId } = val
    const allDatasets = [state.comparisonDataset, ...state.datasets]
    const targetDataset = allDatasets.find(dataset => dataset.id === datasetId)
    if (targetDataset) {
      Vue.set(targetDataset.filterSettings, 'currentPage', currentPage)
    }
  }
}

export const actions = {
  addDataset({ commit }, val) {
    commit('addDataset', val)
  },
  removeDataset({ commit }, val) {
    commit('removeDataset', val)
  },
  updateDataset({ commit }, val) {
    commit('updateDataset', val)
  },
  setCurrentDatasetIndex({ commit }, val) {
    commit('setCurrentDatasetIndex', val)
  },
  clearDatasets({ commit }) {
    commit('clearDatasets')
  },
  updateComparisonDataset({ commit }, val) {
    commit('updateComparisonDataset', val)
  },
  updateShowBaseline({ commit }, val) {
    commit('updateShowBaseline', val)
  },
  updateKeywordText({ commit }, val) {
    commit('updateKeywordText', val)
  },
  addKeyword({ commit }, val) {
    commit('addKeyword', val)
  },
  removeKeyword({ commit }, val) {
    commit('removeKeyword', val)
  },
  updateCurrentPage({ commit }, val) {
    commit('updateCurrentPage', val)
  }
}
