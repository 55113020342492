<template>
  <v-content class="pt-0 audience-container">
    <div class="wrapper">
      <v-content v-if="dialog" class="dialog">
        <v-layout
          align-center
          justify-center
          align-items-center
          row
          fill-height
        >
          <v-card color="darken-2">
            <v-card-title primary-title>
              <div>
                <div class="font-weight-bold" style="font-size: 18px">
                  <span class="info-icon">i</span>
                  <span>Unsaved changes</span>
                </div>
                <p>
                  You have unsaved changes. Are you sure you want to continue?
                </p>
              </div>
            </v-card-title>
            <v-card-actions justify-end>
              <v-btn
                flat
                light
                class="btn-black font-weight-bold"
                @click="unsavedOk"
              >
                continue
              </v-btn>
              <v-btn
                flat
                outline
                class="font-weight-bold"
                @click="dialog = false"
              >
                cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-content>
      <div
        class="header"
        :class="{ open: (disabled && audienceId == 0) || createmode }"
      >
        <v-card class="font-weight-medium filters-card bg-black">
          <div>
            <div
              class="close-btn"
              style="
                cursor: pointer;
                position: absolute;
                top: 15px;
                right: 10px;
                color: white;
                align-items: flex-end;
                justify-content: flex-end;
              "
              @click="closeFilters"
            >
              <v-icon style="color: white">
                close
              </v-icon>
            </div>
          </div>
          <v-card-title primary class="filterstitle font-weight-bold pl-3">
            Edit segments
          </v-card-title>
        </v-card>
        <div class="bg-black pl-2">
          <v-alert
            v-if="audienceId == 0 && isloading == false && createmode === false"
            id="my-alert"
            :value="true"
            icon="info"
            outline
            style="font-size: 13px"
            class="my-alert font-weight-bold"
          >
            You will not be able to adjust the ‘all respondents’ segment. To
            create a new segment, open the dropdown and click create new
            segment.
          </v-alert>
          <v-alert
            v-else
            v-model="isValid"
            class="my-alert font-weight-bold ma-0 alert-no-icon"
            :value="true"
            outline
            style="
               {
                padding-left: 8px;
              }
            "
          >
            <span
              v-if="emptyanswers"
            >You must select at least one option from every question.</span>
            <span
              v-else
            >WARNING: At least one answer must be hidden before saving this
              segment</span>
          </v-alert>
          <v-alert
            id="my-alert-2"
            v-model="alert"
            class="my-alert font-weight-bold ma-0"
            :value="true"
            outline
          >
            Please name your segment before saving.
          </v-alert>
          <!--     <v-alert
            v-model="isValid"
            class="my-alert font-weight-bold ma-0"
            :value="true"
            outline
          >
            <span v-if="emptyanswers">
              You must select at least one option from every question.
            </span>
            <span v-else>
              You must filter at least one option.
            </span>
          </v-alert>-->
        </div>
      </div>
      <simplebar
        class="scroller"
        :class="{ open: disabled && audienceId == 0 }"
      >
        <v-content style class="pa-0 scroll-container">
          <v-content style class="pt-0 scroll-content">
            <v-layout column wrap align-left class="mb-0 ml-2 pt-3">
              <p style="color: white" class="font-weight-medium pl-2">
                Your segments
              </p>
              <v-content v-if="!showinput" xs12 d-flex class="pl-2 pt-0 pb-0">
                <FilterSelect />
              </v-content>
            </v-layout>
            <v-alert
              v-if="createmode"
              id="my-info"
              :value="true"
              outline
              style="font-size: 12px"
              class="my-icon font-weight-bold"
            >
              Define your segment by deselecting answers to questions. For
              example, if you want to create a segment for females only, just
              deselect all other genders for that question.
            </v-alert>
            <v-layout column class="ml-0 mr-2 mt-0 mb-0" style="width: 300px">
              <v-flex
                v-if="showinput"
                xs12
                d-flex
                style="background:white:color:black;"
              >
                <input
                  v-model="message"
                  type="text"
                  label="Segment name"
                  placeholder="Name your segment"
                  class="text-14 custom font-weight-bold ml-3 mb-2"
                  style="background:white:color:black;"
                  @input="nameChange"
                >
              </v-flex>
            </v-layout>
            <v-layout
              class="w-100 pl-3 pr-4 pt-0 pb-2 font-weight-bold"
              justify-space-between
              row
              style="
                color: #999999;
                font-size: 12px;
                border-bottom: 1px solid #444;
              "
            >
              <div>SHOW</div>
              <div>RESPONDENTS</div>
            </v-layout>
            <v-card class="bg-black">
              <v-container fluid class="pa-0 bg-black">
                <v-radio-group
                  v-model="radios"
                  class="bg-black"
                  style="padding-bottom: 100px"
                >
                  <v-list
                    v-for="(question, i) in surveyQuestions"
                    :key="question.id"
                    class="pt-0 pl-0 bg-black pb-2"
                  >
                    <v-container
                      v-if="
                        (question.SurveyAnswers &&
                          question.SurveyAnswers.length > 0) ||
                          (question.Answers && question.Answers.length > 0)
                      "
                      :id="'container-' + question.id.toString()"
                      :index="i"
                      fluid
                      :data-id="question.id"
                      :class="'question-container'"
                      class="pt-2 pb-2 pl-3 panel bg-black"
                      style="border-bottom: 1px solid #333"
                    >
                      <v-flex class="option-holder ml-0 mr-2">
                        <v-content class="pt-0 pb-2 pl-0 font-weight-bold">
                          {{ question.text }}
                        </v-content>
                        <FilterAnswers
                          :id="question.id"
                          :answers="question.SurveyAnswers || question.Answers"
                          :disabled="disabled"
                          @update-question="updateAudienceQuestion"
                        />
                      </v-flex>
                    </v-container>
                  </v-list>
                </v-radio-group>
              </v-container>
            </v-card>
          </v-content>
        </v-content>
      </simplebar>
    </div>
    <v-card class="fixed-bottom font-weight-medium footer bg-black ml-2">
      <v-layout align-center fill-height>
        <div class="text-xs-left pr-3">
          <v-btn
            v-if="
              audienceId != 0 &&
                !hasupdated &&
                !createmode &&
                disabled &&
                !isloading
            "
            class="text-black ml-3"
            color
            @click="editAudience"
          >
            EDIT
          </v-btn>
          <v-btn
            v-if="!disabled"
            class="text-black ml-3"
            :class="{ disabled: disabled || !hasupdated }"
            color="secondary"
            :disabled="dialog"
            @click="saveUpdateAudience"
          >
            SAVE
          </v-btn>
          <v-btn
            v-if="!disabled || (!createmode && showinput)"
            flat
            light
            color
            style="color:white; !important"
            :disabled="dialog"
            @click="reset"
          >
            CANCEL
          </v-btn>
        </div>
        <div v-if="isloading" class="progress-bar-container">
          <v-progress-linear class="progress" :indeterminate="true" />
        </div>
        <div v-if="!disabled" class="progress-bar-container">
          <div>
            {{
              (
                (getFilteredResponsesCount / getActiveResponseCount) *
                100
              ).toFixed(0)
            }}% of respondents
            <div>
              <v-layout justify-space-between justify-content-center row>
                <div class="bar">
                  <div
                    id="inner"
                    ref="inner"
                    class="inner"
                    :style="{
                      width:
                        (
                          (getFilteredResponsesCount / getActiveResponseCount) *
                          100
                        ).toFixed(0) + '%',
                    }"
                  />
                </div>
              </v-layout>
            </div>
          </div>
        </div>
        <div v-if="!disabled" class="copy ml-2 mr-2">
          {{ getFilteredResponsesCount }} of {{ getActiveResponseCount }}
        </div>
      </v-layout>
    </v-card>
  </v-content>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import simplebar from 'simplebar-vue'
import FilterAnswers from '@/components/pages/survey/filters/FilterAnswers'
import FilterSelect from '@/components/pages/survey/filters/FilterSelect'

export default {
  components: {
    FilterAnswers,
    FilterSelect,
    simplebar
  },
  data() {
    return {
      radios: 'No Segmentation',
      message: '',
      showinput: false,
      surveytotal: 0,
      disabled: true,
      createmode: false,
      hasupdated: false,
      alert: false,
      dialog: false,
      emptyanswers: true,
      isValid: false,
      originalfilters: []
    }
  },
  computed: {
    ...mapGetters({
      survey: 'analyzeSurvey/getSurvey',
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      audienceQuestions: 'analyzeSurvey/getAudienceQuestions',
      audienceFilters: 'analyzeSurvey/getAudienceFilters',
      audienceId: 'analyzeSurvey/getAudienceId',
      audienceList: 'analyzeSurvey/getAudienceList',
      audienceName: 'analyzeSurvey/getAudienceName',
      isloading: 'analyzeSurvey/getLoading',
      getActiveResponseCount: 'analyzeSurvey/getActiveResponseCount',
      getSegmentedResponseCount: 'analyzeSurvey/getSegmentedResponseCount',
      getFilteredResponsesCount: 'analyzeSurvey/getFilteredResponsesCount'
    }),
    audienceGroupFiltersTemplate() {
      const temp = this.segmentQuestions.map(question => {
        // console.log(question, 'temptemp')

        return {
          question_id: question.id,
          Answers: question.SurveyAnswers.map(ans => ans.id)
        }
      })
      // console.log(temp, 'temptemp')
      return temp
    },
    audienceGroupTemplate() {
      const group = {
        id: this.audienceId,
        name: this.audienceName,
        survey_id: this.survey.id,
        Filters: this.audienceGroupFiltersTemplate
      }
      return group
    },
    audienceGroup() {
      const filters = this.audienceFilters
      // const answers = filters.filter(a => a.Answers.length)
      // const hasNull = filters.filter(a => !a.question_id)
      // if (!answers.length || hasNull.length)
      // filters = this.audienceGroupFiltersTemplate
      // console.log(filters, 'audienceGroup audienceGroup')
      return filters
    },
    audienceGroupTrim() {
      let filters = this.audienceFilters
      const answers = filters.filter(a => a.Answers.length)
      const hasNull = filters.filter(a => !a.question_id)
      if (!answers.length || hasNull.length)
        filters = this.audienceGroupFiltersTemplate
      return filters
    },
    surveyQuestions() {
      let questions = this.audienceQuestions
      if (this.audienceId === 0) {
        questions = this.segmentQuestions
      }

      return questions
    }
  },
  watch: {
    audienceId(newVal, oldVal) {
      if (newVal === 0) {
        this.setAudienceFilters(this.addAllFilters(this.surveyQuestions))
        this.$bus.$emit('disable')
        this.$bus.$emit('apply-filters')
      }
      this.disabled = true
    },
    isloading(newVal) {
      // console.log('isloading', newVal)
      // console.log('isloading this.audienceFilters', this.audienceFilters)
      if (newVal === true) {
        // console.log('loading new val')
        // *** temp storage this
        // this.setSegGroups()
      } else if (newVal === false) {
        this.originalfilters = this.audienceFilters
        this.$bus.$emit('apply-filters')
      }
    }
  },

  mounted() {
    this.fetchAudienceList(this.survey.id)
    this.$bus.$on('create-audience', this.createAudience)
  },
  destroyed() {
    this.removeSegmentation()
    this.resetView()
  },

  methods: {
    ...mapActions({
      fetchAudienceList: 'analyzeSurvey/fetchAudienceList',
      setAudienceId: 'analyzeSurvey/setAudienceId',
      setSegGroups: 'analyzeSurvey/setSegGroups',
      saveAudience: 'analyzeSurvey/saveAndLoadAudience',
      updateAudience: 'analyzeSurvey/saveAndUpdateAudience',
      setView: 'analyzeSurvey/setCurrentView',
      deleteAudience: 'analyzeSurvey/deleteAudience',
      resetAudience: 'analyzeSurvey/resetAudience',
      setLoading: 'analyzeSurvey/setLoading',
      setAudienceFilters: 'analyzeSurvey/setAudienceFilters',
      removeSegmentation: 'analyzeSurvey/removeSegmentation',
      resetView: 'analyzeSurvey/setCurrentView'
    }),
    checkForEmpty() {
      const empty = this.audienceGroup.find(a => {
        return !a.Answers.length
      })
      // console.log(!!empty, 'checkForEmpty')
      return !!empty
    },
    checkCheckboxes() {
      let full = true
      const payload = this.getPayload(this.audienceId, this.getName())
      const testFull = payload.Filters.filter((a, i) => {
        const question = this.audienceGroupFiltersTemplate.find(
          q => q.question_id === a.question_id
        )
        return a.Answers.length && a.Answers.length !== question.Answers.length
      })
      if (testFull.length) full = false

      return full
    },
    nameChange() {
      this.hasupdated = true
      this.alert = false
      this.disabled = this.checkCheckboxes() || this.checkForEmpty()
    },
    unsavedOk() {
      this.dialog = false
      // this.disabled = true
      this.showinput = false
      this.alert = false
      this.hasupdated = false
      this.reset()
      this.setView('FILTERS')
    },

    // If audience id is 0, add all filters
    addAllFilters(arr) {
      return arr.map(question => {
        return {
          question_id: question.id,
          Answers: question.SurveyAnswers.map(a => a.id)
        }
      })
    },

    editAudience() {
      this.showinput = true
      this.disabled = false
      this.$bus.$emit('enable')
      if (this.audienceId === 0) {
        this.setAudienceFilters(this.audienceGroupFiltersTemplate)
        this.message = ''
      } else {
        this.message = this.audienceName
      }
    },

    createAudience() {
      // this.setAudienceId(0)
      this.showinput = true
      this.message = ''
      this.createmode = true
      this.disabled = true
      this.isValid = true
      this.emptyanswers = false
      this.hasupdated = false
      // this.$bus.$emit('reset')
      this.$bus.$emit('enable')
    },

    closeFilters() {
      if (this.hasupdated) {
        this.dialog = true
      } else {
        this.dialog = false
        this.disabled = true
        this.showinput = false
        this.alert = false
        this.hasupdated = false
        this.reset()
        this.setView('FILTERS')
      }
    },

    // updates are sent from the AnswerFilter component
    updateAudienceQuestion(obj) {
      // console.log('AudienceFilter, updateAudienceQuestion, obj:', obj)
      // 1. if there are unchecked answwers disable and show alert
      // // 2. Find the target Fiter that has to updated
      const targetFilter = this.getTarget(this.audienceGroup, obj)
      // console.log('targetFilter:', targetFilter)
      const updatedFilters = this.updateFilters(
        this.audienceGroup,
        targetFilter
      )

      // 3 update audience filters in the store with our updated question
      this.setAudienceFilters(updatedFilters)

      this.emptyanswers = this.checkForEmpty()
      this.isValid = this.checkCheckboxes() || this.emptyanswers

      this.disabled = this.isValid
      if (!this.disabled) {
        this.hasupdated = true
      }
    },

    // updates the current filter that has changed and return the rest
    updateFilters(arr, item) {
      let inArray = false
      let currentArr = arr.map(i => {
        if (i.question_id === item.question_id) {
          i = item
          inArray = true
        }
        return i
      })
      if (!inArray && item.Answers.length) currentArr = [...arr, item]
      return currentArr
    },

    // returns target question that needs to be updated
    getTarget(arr, obj) {
      const target = arr.filter(f => f.question_id === obj.question_id)
      return Object.assign({}, ...target, {
        question_id: obj.question_id,
        Answers: this.getChecked(obj.Answers)
      })
    },

    // returns checked answers to send to api when saving
    getChecked(answers) {
      return answers.filter(a => a.checked === true).map(a => {
        const id = parseInt(a.id.toString().replace('segment-', ''))
        // console.log('id:', id)
        return id
      })
    },

    // Save, update or show alert
    saveUpdateAudience() {
      this.dialog = false
      // this.$bus.$emit('reset-filters-panel')
      // 1. If name already exists or message is blank show alert
      const match1 = this.getMatchFromDB(this.audienceList, this.message)
      if (
        (this.message === '' && this.showinput) ||
        (match1 === true && this.createmode === true)
      ) {
        this.alert = true
        return
      }
      // 2. If name doesnt exist and createmode is true save
      if (match1 === false && this.createmode === true) {
        this.save()
        // 3. else update
      } else if (this.getMatchFromDB(this.audienceList, this.audienceName)) {
        this.update()
      }
    },

    // Check if audience name already exists in DB
    getMatchFromDB(a, m) {
      let match = false
      a.filter(i => {
        if (i.name.toLowerCase() === m.toLowerCase()) {
          match = true
        }
      })
      return match
    },
    save() {
      this.disabled = true
      this.showinput = false
      this.hasupdated = false
      this.alert = false
      this.createmode = false
      this.$bus.$emit('disable')
      const payload = this.getPayload(0, this.message)
      // this.resetAudience()
      this.setLoading(true)
      // api call to save new audience

      const testFull = payload.Filters.filter((a, i) => {
        const question = this.audienceGroupFiltersTemplate.find(
          q => q.question_id === a.question_id
        )
        return a.Answers.length && a.Answers.length !== question.Answers.length
      })
      payload.Filters = testFull
      this.saveAudience(payload)
    },
    update() {
      // alert('update')
      this.disabled = true
      this.showinput = false
      this.hasupdated = false
      this.alert = false
      this.$bus.$emit('disable')
      if (this.audienceHasChanged()) {
        const payload = this.getPayload(this.audienceId, this.getName())
        // this.resetAudience()
        this.setLoading(true)
        // api call to update new audience

        const testFull = payload.Filters.filter((a, i) => {
          const question = this.audienceGroupFiltersTemplate.find(
            q => q.question_id === a.question_id
          )
          return (
            a.Answers.length && a.Answers.length !== question.Answers.length
          )
        })
        payload.Filters = testFull
        this.updateAudience(payload)
      } else {
      }
    },
    // if filters and name haven't changed don't do api call
    audienceHasChanged() {
      const a = Object.assign(
        {},
        { name: this.audienceName },
        { Filters: this.originalfilters }
      )
      const b = Object.assign(
        {},
        { name: this.message },
        { Filters: this.audienceFilters }
      )
      return JSON.stringify(a) !== JSON.stringify(b)
    },
    getPayload(a, b) {
      return {
        id: a,
        name: b,
        hashcode: this.survey.hashcode,
        survey_id: this.survey.id,
        Filters: this.audienceGroup
      }
    },

    // If updating, check if the name has updated
    getName() {
      let name
      if (this.message === this.audienceName) {
        name = this.audienceName
      } else if (this.message === '') {
        name = this.audienceName
      } else {
        name = this.message
      }
      return name
    },

    // Delete audience is not currently being used
    deleteAudience(id, surveyid) {
      const payload = {
        id: id,
        survey_id: surveyid
      }
      this.$api.survey.deleteAudienceGroup(payload)
    },

    // reset the audience when user cancels
    reset: function() {
      this.disabled = true
      this.hasupdated = false
      this.showinput = false
      this.alert = false
      this.createmode = false
      this.$bus.$emit('disable')
      this.$bus.$emit('reset')
    }
  }
}
</script>
<style lang="scss">
.audience {
  .v-text-field--outline > .v-input__control > .v-input__slot {
    border: none !important;
    border-radius: 30px !important;
    background: #444 !important;
    font-size: 14px !important;
    min-height: 30px !important;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/_audienceFilters.scss';

.progress-bar-container {
  overflow: hidden;
}
</style>
