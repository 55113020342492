var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", { attrs: { "stroke-width": "1.25", fill: "none" } }, [
    _c("path", {
      attrs: { d: "M10.5 6a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z" }
    }),
    _vm._v(" "),
    _c("path", {
      attrs: {
        d:
          "M29 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM22 14.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM6 14.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM4 13l5.5-7.5M18 13l-6.366-7.366M25.5 6L20 13"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }