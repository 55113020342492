<template>
  <v-dialog
    v-model="dialog"
    class="arcadia-createsurvey-dialog"
    content-class="arcadia-createsurvey-dialog"
    width="700"
  >
   <!-- <Button slot="activator" variant="primary" icon="add_circle_outline">
      Create a survey
    </Button> -->saveBtn
    <v-sheet>
      <h2 class="save-title">
        Create a new survey
      </h2>
      <hr :style="{ color: '#d8d8d8#'}">
      <div class="save-container">
        <div class="form-container">
          <span
            class="form-description"
          >
            You can create and field a survey right from Arcadia. Just input all your questions, share a link to gather responses, and then analyze it to form your insights.
          </span>
          <v-form ref="form" class="access-form" lazy-validation @submit.prevent="submit">
            <TextField
              v-model="name"
              label="Enter a name for your survey"
              :rules="nameRules"
              :error-messages="nameErrors"
              autofocus
              placeholder="Enter survey name"
              validate-on-blur
            />

            <Textarea
              v-model="description"
              label="Survey description"
              solo
              placeholder="Leave a description about the goal of your survey."
              name="input-7-4"
              required
              validate-on-blur
              background-color="#FFF"
              :rules="descriptionRules"
            />
            <v-layout class="access-form-buttons">
              <Button class="contact-btn" variant="primary" @click="submit">
                Create survey
              </Button>
              <Button variant="outline" @click="dialog=false">
                Cancel
              </Button>
            </v-layout>
          </v-form>
        </div>
      </div>
    </v-sheet>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { notEmpty, max255 } from '@/js/validations'

import Button from '@/components/ui/Button'
import TextField from '@/components/ui/TextField'
import Textarea from '@/components/ui/Textarea'
export default {
  components: {
    Button,
    TextField,
    Textarea
  },
  layout: 'survey',
  middleware: ['authenticated'],
  data: () => ({
    dialog: false,
    name: '',
    description: '',
    unique: false,
    nameRules: [notEmpty, max255],
    nameErrors: [],
    descriptionRules: [],
    descriptionErrors: []
  }),
  computed: {
    ...mapGetters({
      brandInfo: 'brandInfo'
    })
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.$set(this, 'name', '')
        this.$set(this, 'description', '')
        this.$refs.form.reset()
      }
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        // if form data is valid
        const payload = {
          name: this.name,
          description: this.description,
          questions: [],
          workspace_id: this.$store.state.brandInfo.id,
          // default header and footer text
          header_text:
            'Thanks for taking our survey! Please answer all questions honestly and with as much detail as you can.',
          footer_text: 'Thank you for your time!'
        }
        this.$api.survey
          .createSurvey(payload) // then submit
          .then(response => {
            this.$set(this, 'dialog', false)
            const { Survey } = response.data
            const { hashcode } = Survey
            this.$router.push(
              `/dashboard/${this.$store.state.brandInfo.id}/surveys/${hashcode}`
            )
          })
          .catch(err => {
            const { response } = err
            this.$set(this, 'nameErrors', [response.data.error])
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';

.save-title {
  padding: $spacing-base;
}

.save-container {
  padding: $spacing-base;
}

.form-description {
  max-width: 515px;
}
</style>

<style lang="scss">
@import '@/assets/css/_vars.scss';

.arcadia-createsurvey-dialog {
  overflow: visible;

  .access-form-buttons {
    margin-top: 33px;
    display: flex;
    justify-content: flex-end;
  }

  .contact-container {
    padding: $spacing-base;
  }

  .contact-overlay {
    overflow: visible;
  }

  .contact-btn {
    margin-left: 0;
  }
}
</style>
