import Vue from 'vue'

Vue.prototype.$checkWorkspaceId = function() {
  // coerce route param string into number
  const id = this.$route.params.brand * 1
  const existingWorkspace = this.$store.state.brandInfo
  if (!id) {
    return
  }

  if (!existingWorkspace || id !== existingWorkspace.id) {
    return this.$api.workspace
      .getWorkspaceById(id)
      .then(data => {
        const { Workspace } = data
        this.$store.dispatch('setBrand', Workspace)
      })
      .catch(err => console.log('err:', err))
  }
}
