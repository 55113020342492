<template>
  <div id="left-nav-wrapper" @mouseover="handleMouseover" @mouseout="handleMouseout">
    <v-navigation-drawer
      value="true"
      fixed
      app
      temporary
      stateless
      disable-resize-watcher
      :mini-variant.sync="miniNav"
      :mini-variant-width="leftNavWidth"
      :width="200"
      hide-overlay
      dark
    >
      <v-layout class="left-nav-container" column fill-height>
        <v-list class="home-trigger-container">
          <v-list-tile v-if="$store.state.isAuth" to="/dashboard" exact>
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon
                id="home-icon"
                :color="lastPath === 'dashboard' ? selectedColor : iconColor "
              >
                mdi-home-outline
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>All Workspaces</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>

        <div class="nav-hr">
          <hr>
        </div>
        <!-- 
        <v-list v-if="$route.params.brand">
          <v-list-tile v-if="isAdmin && $route.params.brand" :to="`${baseURL}/edit`">
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon :color="lastPath === 'edit' ? selectedColor : iconColor ">
                mdi-settings-outline
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Settings</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :to="`/dashboard/${$route.params.brand}`" exact>
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon
                :color="lastPath === $route.params.brand ? selectedColor : iconColor "
              >
                mdi-view-dashboard-outline
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ workspaceName }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-for="item in items" :key="item.text" :to="`${baseURL}/${item.path}`">
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon
                :color="item && item.path === lastPath ? selectedColor : iconColor "
              >
                {{ item.icon }}
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-for="item in items2" :key="item.text" :to="`${baseURL}/${item.path}`">
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon
                :color="item && item.path === lastPath ? selectedColor : iconColor "
              >
                {{ item.icon }}
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>-->

        <v-spacer />

        <v-list>
          <div class="nav-hr">
            <hr>
          </div>

          <v-list-tile v-if="roleId === 1" :to="`/admin/accounts`" exact>
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon
                :color="onEditAccounts ? selectedColor : iconColor "
              >
                mdi-account-circle-outline
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Edit Accounts</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <div v-if="$store.state.isAuth" class="text-xs-center">
            <v-list-tile :to="`/admin/profile/${$store.state.auth.user.id}`">
              <v-list-tile-action :class="{ 'avatar-offset': !miniNav}">
                <Avatar
                  :image-name="profileImg"
                  :user-initials="userInitials"
                  avatar-size="25"
                  class="nav-avatar"
                />
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ name }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </div>

          <div class="nav-hr">
            <hr>
          </div>

          <v-list-tile class="tile">
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <FullScreenButton :on="fullscreen" :color="iconColor" @toggle="toggleFullscreen" />
            </v-list-tile-action>
            <v-list-tile-content @click="toggleFullscreen">
              <v-list-tile-title>Fullscreen</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile v-if="$store.state.isAuth" class="text-xs-center tile" @click="logout">
            <v-list-tile-action :class="{ 'hover-offset-md-icon': !miniNav}">
              <v-icon :color="iconColor">
                exit_to_app
              </v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Logout</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import _vars from '@/assets/css/_vars.scss'
import FullScreenButton from '@/components/ui/FullScreenButton'
import Avatar from '@/components/pages/admin/Avatar'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  components: {
    FullScreenButton,
    Avatar
  },
  data: () => ({
    fullscreen: false,
    miniNav: true,
    leftNavWidth: _vars['leftnav-width'],
    iconColor: _vars['font-medium-light'],
    selectedColor: _vars.primary,
    items: [
      // {
      //   icon: 'star',
      //   text: 'Insights',
      //   path: 'insights'
      //   // disabled:true
      // },
      // {
      //   icon: 'book',
      //   text: 'Stories',
      //   path: 'stories',
      //   disabled: true
      // },
      // {
      //   icon: 'person_outline',
      //   text: 'Personas',
      //   path: 'personas',
      //   disabled: true
      // },
      // {
      //   icon: 'map',
      //   text: 'Journeys',
      //   path: 'journeys',
      //   disabled: true
      // }
    ],
    items2: [
      {
        icon: 'mdi-book-outline',
        text: 'Saved Datapoints',
        path: 'strategy'
        // disabled: true
      },
      {
        icon: 'mdi-share-variant',
        text: 'Syndicated',
        path: 'syndicated'
        // disabled: true
      },
      {
        icon: 'mdi-forum-outline',
        text: 'Surveys',
        path: 'surveys'
        // disabled: false
      }
      // {
      //   icon: 'share',
      //   text: 'Social',
      //   path: 'social',
      //   disabled: true
      // },
      // {
      //   icon: 'search',
      //   text: 'Search',
      //   path: 'search',
      //   disabled: true
      // }
    ],
    adminOptions: [
      // {
      //   icon: 'insert_chart',
      //   text: 'Activity',
      //   path: 'activity',
      //   disabled: true
      // },
      {
        icon: 'mdi-account-circle-outline',
        text: 'Accounts',
        path: 'accounts',
        disabled: false
      }
      // {
      //   icon: 'timeline',
      //   text: 'Journeys',
      //   path: 'journeys',
      //   disabled: true
      // },
      // {
      //   icon: 'category',
      //   text: 'Categories',
      //   path: 'categories',
      //   disabled: true
      // },
      // {
      //   icon: 'group',
      //   text: 'Clients',
      //   path: 'clients',
      //   disabled: true
      // }
    ]
  }),
  computed: {
    ...mapGetters({
      roleId: 'auth/roleId',
      survey: 'analyzeSurvey/getSurvey',
      brandInfo: 'brandInfo'
    }),
    ...mapState(['auth']),
    isAdmin() {
      return this.roleId === 1
    },
    onAdminPage() {
      const pathElements = this.$route.path.split('/')
      return pathElements[1] === 'admin'
    },
    onAdminPageProfile() {
      const pathElements = this.$route.path.split('/')
      return pathElements[1] === 'admin' && pathElements[2] === 'profile'
    },
    onEditAccounts() {
      const pathElements = this.$route.path.split('/')
      return pathElements[1] === 'admin' && pathElements[2] === 'accounts'
    },
    baseURL: function() {
      const client = this.$route.params.brand
      return `/dashboard/${client}`
    },
    name: function() {
      return this.auth.user.first_name
    },
    profileImg: function() {
      return this.auth.user.image_name
    },
    lastPath: function() {
      const path = this.$route.path.split('/').filter(el => el.length)
      const lastPath = path[path.length - 1]
      return lastPath
    },
    userInitials: function() {
      return (
        this.auth.user.first_name.charAt(0) + this.auth.user.last_name.charAt(0)
      )
    },
    workspaceName() {
      return this.brandInfo ? this.brandInfo.name : ''
    }
  },
  watch: {
    $route(to, from) {
      // if (to.params.survey !== from.params.survey) {
      //   this.removeSegmentation()
      //   this.setView()
      // } else {
      //   const groups = {
      //     survey_id: this.survey.id,
      //     respondent_group_id: this.audienceId,
      //     segment_question: this.currentQuestionId,
      //     SegmentGroups: this.getSegGroups
      //   }
      //   // set grouping for charts
      //   this.$bus.$emit('apply-filters', groups)
      // }
    }
  },
  methods: {
    ...mapActions({
      // toggleMiniNav: 'nav/toggleMiniNav'
      removeSegmentation: 'analyzeSurvey/removeSegmentation',
      setView: 'analyzeSurvey/setCurrentView'
    }),
    handleMouseover(ev) {
      this.$set(this, 'miniNav', false)
    },
    handleMouseout(ev) {
      this.$set(this, 'miniNav', true)
    },
    logout() {
      Cookie.remove('auth')
      Cookie.remove('workspaceid')
      this.$store.commit('setAuth', null)
      location.reload()
    },
    matchPath(name) {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]
      return name === lastElement
    },
    toggleFullscreen() {
      this.$set(this, 'fullscreen', !this.fullscreen)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
.miniNav {
  position: absolute;
  bottom: 40px;
  &:hover,
  &:focus {
    position: absolute;
  }
}
.v-list__tile--active {
  color: #000;
  background: color('primary');
}
#left-nav-wrapper {
  font-family: $font-primary;
}

.nav-hr {
  display: flex;
  justify-content: center;
}

.nav-hr hr {
  flex: 1;
  @include rule-light;
  border-color: color('rule-medium');
}

.v-list,
.v-list__tile__content,
.theme--light,
.primary--text {
  color: color('font-light') !important;
}

.hover-offset {
  margin-left: 9px;
}

.hover-offset-md-icon {
  margin-left: 12px;
}

.hover-offset-fullscreen-icon {
  justify-content: center;
}

.avatar-offset {
  margin-left: 12px;
}

.theme--dark.v-navigation-drawer {
  background-color: color(bg-homepage);
}

.nav-title {
  color: color('font-medium');
  margin: 0px 20px 0px 20px;
  text-transform: uppercase;
  font-size: $font-size-base * 0.7;
  font-weight: $font-bold;
  transition: opacity 0.4s ease;
  white-space: nowrap;
}

.hidden {
  opacity: 0;
  transition: opacity 0.2s ease;
}

.selected {
  color: red !important;
  background-color: blue !important;
}

.v-list__tile__title {
  @include headline-small-soft;
  font-size: 14px;
  .v-list__tile:hover & {
    color: color('font-light');
  }
}
.v-list__tile--active {
  .v-list__tile__content {
    .v-list__tile__title {
      color: color('primary') !important;
    }
  }
}

.tile {
  cursor: pointer;
}

.left-nav-container {
  margin-left: -14px;
}

.home-trigger-container {
  padding: 0;
}
</style>

<style lang="scss">
#left-nav-wrapper {
  .v-list__tile__action {
    min-width: 40px;
  }
  .v-navigation-drawer--mini-variant .v-list__tile__action {
    min-width: 48px;
  }
}
</style>
