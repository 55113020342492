import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _8d0280b4 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _4f24b6ab = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _56a76582 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _49e7f2ca = () => interopDefault(import('../pages/styleguide/index.vue' /* webpackChunkName: "pages/styleguide/index" */))
const _763094c2 = () => interopDefault(import('../pages/admin/accounts.vue' /* webpackChunkName: "pages/admin/accounts" */))
const _d5a5e12a = () => interopDefault(import('../pages/admin/activity.vue' /* webpackChunkName: "pages/admin/activity" */))
const _0bfe5a10 = () => interopDefault(import('../pages/admin/categories.vue' /* webpackChunkName: "pages/admin/categories" */))
const _3a5f32dc = () => interopDefault(import('../pages/admin/clients.vue' /* webpackChunkName: "pages/admin/clients" */))
const _153219e2 = () => interopDefault(import('../pages/admin/journeys.vue' /* webpackChunkName: "pages/admin/journeys" */))
const _0d0ae4aa = () => interopDefault(import('../pages/admin/profile/_id/index.vue' /* webpackChunkName: "pages/admin/profile/_id/index" */))
const _28c4a00e = () => interopDefault(import('../pages/activateAccount/_id.vue' /* webpackChunkName: "pages/activateAccount/_id" */))
const _c139477c = () => interopDefault(import('../pages/dashboard/_brand/index.vue' /* webpackChunkName: "pages/dashboard/_brand/index" */))
const _18e14649 = () => interopDefault(import('../pages/resetPassword/_id.vue' /* webpackChunkName: "pages/resetPassword/_id" */))
const _a5152038 = () => interopDefault(import('../pages/survey/_id/index.vue' /* webpackChunkName: "pages/survey/_id/index" */))
const _791c0466 = () => interopDefault(import('../pages/dashboard/_brand/edit/index.vue' /* webpackChunkName: "pages/dashboard/_brand/edit/index" */))
const _17a4a25e = () => interopDefault(import('../pages/dashboard/_brand/insights/index.vue' /* webpackChunkName: "pages/dashboard/_brand/insights/index" */))
const _be8af594 = () => interopDefault(import('../pages/dashboard/_brand/journeys/index.vue' /* webpackChunkName: "pages/dashboard/_brand/journeys/index" */))
const _567a79ca = () => interopDefault(import('../pages/dashboard/_brand/personas/index.vue' /* webpackChunkName: "pages/dashboard/_brand/personas/index" */))
const _1803ddeb = () => interopDefault(import('../pages/dashboard/_brand/search/index.vue' /* webpackChunkName: "pages/dashboard/_brand/search/index" */))
const _3fe5dd70 = () => interopDefault(import('../pages/dashboard/_brand/social/index.vue' /* webpackChunkName: "pages/dashboard/_brand/social/index" */))
const _d39bc4f4 = () => interopDefault(import('../pages/dashboard/_brand/stories/index.vue' /* webpackChunkName: "pages/dashboard/_brand/stories/index" */))
const _82132014 = () => interopDefault(import('../pages/dashboard/_brand/strategy/index.vue' /* webpackChunkName: "pages/dashboard/_brand/strategy/index" */))
const _26bb9be8 = () => interopDefault(import('../pages/dashboard/_brand/surveys/index.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/index" */))
const _711f0bea = () => interopDefault(import('../pages/dashboard/_brand/syndicated/index.vue' /* webpackChunkName: "pages/dashboard/_brand/syndicated/index" */))
const _6c0ea797 = () => interopDefault(import('../pages/survey/_id/complete.vue' /* webpackChunkName: "pages/survey/_id/complete" */))
const _2ffae9b7 = () => interopDefault(import('../pages/dashboard/_brand/surveys/create_survey.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/create_survey" */))
const _790e036e = () => interopDefault(import('../pages/dashboard/_brand/surveys/import_survey.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/import_survey" */))
const _7b63683c = () => interopDefault(import('../pages/dashboard/_brand/syndicated/import.vue' /* webpackChunkName: "pages/dashboard/_brand/syndicated/import" */))
const _4d59d43a = () => interopDefault(import('../pages/dashboard/_brand/syndicated/segments/index.vue' /* webpackChunkName: "pages/dashboard/_brand/syndicated/segments/index" */))
const _69e7cbfd = () => interopDefault(import('../pages/dashboard/_brand/insights/_insight.vue' /* webpackChunkName: "pages/dashboard/_brand/insights/_insight" */))
const _0c340b66 = () => interopDefault(import('../pages/dashboard/_brand/journeys/_journey.vue' /* webpackChunkName: "pages/dashboard/_brand/journeys/_journey" */))
const _47761725 = () => interopDefault(import('../pages/dashboard/_brand/personas/_persona.vue' /* webpackChunkName: "pages/dashboard/_brand/personas/_persona" */))
const _c3209aac = () => interopDefault(import('../pages/dashboard/_brand/social/_social.vue' /* webpackChunkName: "pages/dashboard/_brand/social/_social" */))
const _135d8bf2 = () => interopDefault(import('../pages/dashboard/_brand/stories/_story.vue' /* webpackChunkName: "pages/dashboard/_brand/stories/_story" */))
const _280ec3b0 = () => interopDefault(import('../pages/dashboard/_brand/strategy/_insight/index.vue' /* webpackChunkName: "pages/dashboard/_brand/strategy/_insight/index" */))
const _3a1c55f6 = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/index.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/index" */))
const _f3b65540 = () => interopDefault(import('../pages/dashboard/_brand/syndicated/_syndicated.vue' /* webpackChunkName: "pages/dashboard/_brand/syndicated/_syndicated" */))
const _46755fa3 = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/analyze_audience.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/analyze_audience" */))
const _4584e071 = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/analyze_journey.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/analyze_journey" */))
const _4476b96c = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/design.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/design" */))
const _26ada79a = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/gather_responses.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/gather_responses" */))
const _e37ac550 = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/import_survey.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/import_survey" */))
const _736e1a28 = () => interopDefault(import('../pages/dashboard/_brand/surveys/_survey/preview.vue' /* webpackChunkName: "pages/dashboard/_brand/surveys/_survey/preview" */))
const _5fbd8934 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/admin",
      component: _8d0280b4,
      name: "admin"
    }, {
      path: "/dashboard",
      component: _4f24b6ab,
      name: "dashboard"
    }, {
      path: "/register",
      component: _56a76582,
      name: "register"
    }, {
      path: "/styleguide",
      component: _49e7f2ca,
      name: "styleguide"
    }, {
      path: "/admin/accounts",
      component: _763094c2,
      name: "admin-accounts"
    }, {
      path: "/admin/activity",
      component: _d5a5e12a,
      name: "admin-activity"
    }, {
      path: "/admin/categories",
      component: _0bfe5a10,
      name: "admin-categories"
    }, {
      path: "/admin/clients",
      component: _3a5f32dc,
      name: "admin-clients"
    }, {
      path: "/admin/journeys",
      component: _153219e2,
      name: "admin-journeys"
    }, {
      path: "/admin/profile/:id?",
      component: _0d0ae4aa,
      name: "admin-profile-id"
    }, {
      path: "/activateAccount/:id?",
      component: _28c4a00e,
      name: "activateAccount-id"
    }, {
      path: "/dashboard/:brand",
      component: _c139477c,
      name: "dashboard-brand"
    }, {
      path: "/resetPassword/:id?",
      component: _18e14649,
      name: "resetPassword-id"
    }, {
      path: "/survey/:id?",
      component: _a5152038,
      name: "survey-id"
    }, {
      path: "/dashboard/:brand/edit",
      component: _791c0466,
      name: "dashboard-brand-edit"
    }, {
      path: "/dashboard/:brand/insights",
      component: _17a4a25e,
      name: "dashboard-brand-insights"
    }, {
      path: "/dashboard/:brand/journeys",
      component: _be8af594,
      name: "dashboard-brand-journeys"
    }, {
      path: "/dashboard/:brand/personas",
      component: _567a79ca,
      name: "dashboard-brand-personas"
    }, {
      path: "/dashboard/:brand/search",
      component: _1803ddeb,
      name: "dashboard-brand-search"
    }, {
      path: "/dashboard/:brand/social",
      component: _3fe5dd70,
      name: "dashboard-brand-social"
    }, {
      path: "/dashboard/:brand/stories",
      component: _d39bc4f4,
      name: "dashboard-brand-stories"
    }, {
      path: "/dashboard/:brand/strategy",
      component: _82132014,
      name: "dashboard-brand-strategy"
    }, {
      path: "/dashboard/:brand/surveys",
      component: _26bb9be8,
      name: "dashboard-brand-surveys"
    }, {
      path: "/dashboard/:brand/syndicated",
      component: _711f0bea,
      name: "dashboard-brand-syndicated"
    }, {
      path: "/survey/:id?/complete",
      component: _6c0ea797,
      name: "survey-id-complete"
    }, {
      path: "/dashboard/:brand/surveys/create_survey",
      component: _2ffae9b7,
      name: "dashboard-brand-surveys-create_survey"
    }, {
      path: "/dashboard/:brand/surveys/import_survey",
      component: _790e036e,
      name: "dashboard-brand-surveys-import_survey"
    }, {
      path: "/dashboard/:brand/syndicated/import",
      component: _7b63683c,
      name: "dashboard-brand-syndicated-import"
    }, {
      path: "/dashboard/:brand/syndicated/segments",
      component: _4d59d43a,
      name: "dashboard-brand-syndicated-segments"
    }, {
      path: "/dashboard/:brand/insights/:insight",
      component: _69e7cbfd,
      name: "dashboard-brand-insights-insight"
    }, {
      path: "/dashboard/:brand/journeys/:journey",
      component: _0c340b66,
      name: "dashboard-brand-journeys-journey"
    }, {
      path: "/dashboard/:brand/personas/:persona",
      component: _47761725,
      name: "dashboard-brand-personas-persona"
    }, {
      path: "/dashboard/:brand/social/:social",
      component: _c3209aac,
      name: "dashboard-brand-social-social"
    }, {
      path: "/dashboard/:brand/stories/:story",
      component: _135d8bf2,
      name: "dashboard-brand-stories-story"
    }, {
      path: "/dashboard/:brand/strategy/:insight",
      component: _280ec3b0,
      name: "dashboard-brand-strategy-insight"
    }, {
      path: "/dashboard/:brand/surveys/:survey",
      component: _3a1c55f6,
      name: "dashboard-brand-surveys-survey"
    }, {
      path: "/dashboard/:brand/syndicated/:syndicated",
      component: _f3b65540,
      name: "dashboard-brand-syndicated-syndicated"
    }, {
      path: "/dashboard/:brand/surveys/:survey/analyze_audience",
      component: _46755fa3,
      name: "dashboard-brand-surveys-survey-analyze_audience"
    }, {
      path: "/dashboard/:brand/surveys/:survey/analyze_journey",
      component: _4584e071,
      name: "dashboard-brand-surveys-survey-analyze_journey"
    }, {
      path: "/dashboard/:brand/surveys/:survey/design",
      component: _4476b96c,
      name: "dashboard-brand-surveys-survey-design"
    }, {
      path: "/dashboard/:brand/surveys/:survey/gather_responses",
      component: _26ada79a,
      name: "dashboard-brand-surveys-survey-gather_responses"
    }, {
      path: "/dashboard/:brand/surveys/:survey/import_survey",
      component: _e37ac550,
      name: "dashboard-brand-surveys-survey-import_survey"
    }, {
      path: "/dashboard/:brand/surveys/:survey/preview",
      component: _736e1a28,
      name: "dashboard-brand-surveys-survey-preview"
    }, {
      path: "/",
      component: _5fbd8934,
      name: "index"
    }],

    fallback: false
  })
}
