<template>
  <v-text-field
    v-model="model"
    class="arcadia-text-field"
    :color="color"
    :label="label"
    :placeholder="placeholder"
    :type="type"
    :rules="rules"
    :validate-on-blur="validateOnBlur"
    :error-count="errorCount"
    :error-messages="errorMessages"
    :hide-details="hideDetails"
    :always-show-messages="alwaysShowMessages"
    :append-icon="appendIcon"
    :value="value"
    :disabled="disabled"
    :readonly="readonly"
    :class="{
      dark,
      alwaysShowMessages,
      onGray,
      unselectable
    }"
    @input="updateField()"
    @click:append="$emit('click:append')"
  />
</template>

<script>
export default {
  props: {
    alwaysShowMessages: {
      default: false,
      type: Boolean
    },
    appendIcon: {
      default: '',
      type: String
    },
    color: {
      default: 'primary',
      type: String
    },
    dark: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    errorCount: {
      default: 1,
      type: Number
    },
    errorMessages: {
      default: () => [],
      type: Array
    },
    hideDetails: {
      default: false,
      type: Boolean
    },
    label: {
      default: null,
      type: String
    },
    onGray: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: null,
      type: String
    },
    readonly: {
      default: false,
      type: Boolean
    },
    rules: {
      default: () => [],
      type: Array
    },
    ruleType: {
      default: '',
      type: String
    },
    type: {
      default: null,
      type: String
    },
    unselectable: {
      default: false,
      type: Boolean
    },
    validateOnBlur: {
      default: false,
      type: Boolean
    },
    value: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      model: ''
    }
  },
  watch: {
    value(nextValue) {
      this.model = nextValue
    }
  },
  methods: {
    updateField() {
      this.$emit('input', this.model)
    }
  }
}
</script>


<style lang="scss">
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.arcadia-text-field {
  input {
    &::placeholder {
      color: color('font-medium-light') !important;
    }
    &:focus {
      &::placeholder {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 0.1s;
      }
    }
  }
  margin-top: $spacing-base;
  padding-top: 0px;
  font-size: 14px;
  background-color: transparent;
  label {
    color: #999 !important;
    font-weight: bold;
  }
  &.dark {
    .v-label {
      color: #fff;

      &.v-label--active {
        color: #fff !important;
      }
    }
  }

  // &.theme--dark.v-input input::placeholder {
  //   // color: color(font-medium);
  //   color: orange !important;
  // }

  &.alwaysShowMessages {
    .v-text-field__details {
      display: block;
    }
  }

  &.error--text {
    .v-text-field__details {
      display: block;
    }

    .v-messages__message {
      color: #ff4d28;
      &:before {
        background-color: #ff4d28;
      }
    }
  }

  &.onGray {
    input {
      background-color: #fff;
    }
  }

  &.unselectable {
    input {
      user-select: none;
      pointer-events: none;
    }
  }

  .v-input__slot {
    margin-bottom: 0;

    &:before,
    &:after {
      border-width: 0 !important;
      border-color: transparent !important;
    }
  }

  .v-label {
    font-size: 12px;
    color: #000;

    position: static !important;
    display: block;
    padding-left: 0px;
    margin-bottom: 5px;

    &.v-label--active {
      transform: none;
      max-width: none;
      color: #000;
    }
  }

  .v-text-field__slot {
    flex-direction: column;
  }

  .v-text-field__details {
    display: none;
  }

  .v-input__control {
    position: relative;
  }

  .v-input__append-inner {
    align-self: flex-end;
  }

  input {
    background-color: color(background);
    padding: 5px 12px;
    color: #000 !important;
  }

  .v-messages {
    background-color: color(font-medium);
    padding: 15px $spacing-base;
    position: absolute;
    width: 265px;
    right: -289px; // width - spacing for arrow
    border-radius: 5px;
    top: 50%;
    transform: translateY(-50%);

    &:after {
      content: '';
      width: 20px;
      height: 20px;
      background-color: color(font-medium);
      transform-origin: center center;
      transform: rotate(45deg) translateY(-50%);
      position: absolute;
      left: -10px;
      top: 50%;
    }
  }
  .v-messages__message {
    position: relative;
    padding-left: 14px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    color: #fff;

    &:first-of-type {
      margin-top: 0;
    }

    &:before {
      content: '';
      width: 9px;
      height: 9px;
      background-color: #00e005;
      border-radius: 50%;
      position: absolute;
      left: 0;
    }
  }
}
</style>
