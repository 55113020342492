import Api from '@/services/Api'
export default class AnalyzeService extends Api {
  // Survey_Analyze - Analyze survey respondents
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveyRespondents
  surveyRespondents(payload) {
    return this.$axios
      .post('surveys/analyze/respondents', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Analyze survey respondents with returning all objects
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveyRespondentsByGroups
  surveyRespondentsGroup(payload) {
    return this.$axios
      .post('surveys/analyze/respondents/by/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Analyze survey respondents with returning all objects
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-analyzeSurveyRespondentsWithQuestionsAndAnswers
  surveyRespondentsAll(payload) {
    return this.$axios
      .post('surveys/analyze/respondents/with/objects', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Survey Keyword Statement Sentiments
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyKeywordStatementSentiments
  surveyKeywordStatementSentiments(payload) {
    return this.$axios
      .post('surveys/analyze/keyword/statement/sentiments', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Analyze survey sentiments by segment groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveySentimentsByGroups
  surveySetimentBySegmentGroups(payload) {
    return this.$axios
      .post('surveys/analyze/sentiments/by/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Survey Keyword Statement Tones
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyKeywordStatementTones
  surveyKeywordStatementTones(payload) {
    return this.$axios
      .post('surveys/analyze/keyword/statement/tones', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Popular Keywords One Count
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyPopularKeywordsSegmentsOneCount
  surveyKeywordsByOneCount(payload) {
    return this.$axios
      .post('surveys/analyze/popular/keywords/one/count', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Popular Keywords By Tone
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-AnalyzeSurveyPopularKeywordsToneSegments
  surveySetimentTonesByPopularKeywords(payload) {
    return this.$axios
      .post('surveys/analyze/tone/popular/keywords', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Popular Keywords Count
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyPopularKeywordsSegmentsCount
  surveySetimentsByPopularKeywordsCount(payload) {
    return this.$axios
      .post('surveys/analyze/popular/keywords/count', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Popular Keywords
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyPopularKeywordsSegments
  surveySetimentsByPopularKeywords(payload) {
    return this.$axios
      .post('surveys/analyze/popular/keywords', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Richest Responses By Tone
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyRichestResponsesToneSegments
  surveySetimentTonesByRichestResponses(payload) {
    return this.$axios
      .post('surveys/analyze/tone/richest/responses', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Richest Responses Count
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyRichestResponsesSegmentsCount
  surveySetimentsByRichestResponsesCount(payload) {
    const topContent = () => {
      return this.$axios.post(
        'surveys/analyze/richest/responses/count',
        payload
      )
    }

    const bottomContent = () => {
      return this.$axios.post('surveys/analyze/richest/responses', payload)
    }

    return Promise.all([topContent(), bottomContent()])
      .then(([tc, bc]) => {
        return { topContent: tc, bottomContent: bc }
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Richest Responses
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeSurveyRichestResponsesSegments
  surveySetimentsByRichestResponses(payload) {
    return this.$axios
      .post('surveys/analyze/richest/responses', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Sentiment
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-AnalyzeSurveySentimentsBySegments
  surveySetimentsBySegments(payload) {
    return this.$axios
      .post('surveys/analyze/sentiments/segments', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Journey - Tones
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-AnalyzeSurveyTonesBySegments
  surveyTonesBySegments(payload) {
    return this.$axios
      .post('surveys/analyze/tones/segments', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Compare_Segments - Analyze Audience - Segmentations
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Compare_Segments-analyzeAudienceSegmentationsSegments
  surveyAudienceBySegmentations(payload) {
    return this.$axios
      .post('surveys/analyze/audience/segmentations', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Analyze survey sentiments by survey and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveySentimentsBySurveyAndGroups
  surveySentimentsBySurveyAndGroups(payload) {
    return this.$axios
      .post('surveys/analyze/sentiments/by/survey/and/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Analyze survey tones by survey and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveyTonesBySurveyAndGroups
  surveyTonesBySurveyAndGroups(payload) {
    return this.$axios
      .post('surveys/analyze/tones/by/survey/and/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Get Tones Count based on Emotional Tones ( Analytical, Confident, Tenative)
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveyTonesEmotionalCount
  surveyTonesEmotionalCount(payload) {
    return this.$axios
      .post('surveys/analyze/statement/tones/count', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Analyze - Analyze personality insights by respondent by segment groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Analyze-AnalyzeSurveyPersonalityInsightsByGroups
  analyzePersonality(payload) {
    return this.$axios
      .post('surveys/analyze/personality', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Statement_Analyze - Analyze survey statement sentiments by phase and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Statement_Analyze
  surveyStatementSentimentsByPhaseAndGroups(payload) {
    return this.$axios
      .post('surveys/analyze/statement/sentiments/by/phase/and/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Statement_Analyze - Analyze survey statement sentiments by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Statement_Analyze-AnalyzeSurveyStatementSentimentsByQuestionAndGroups
  surveyStatementSentimentsByQuestionAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/statement/sentiments/by/question/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Statement_Analyze - Analyze survey statement tones by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Statement_Analyze-AnalyzeSurveyStatementTonesByQuestionAndGroups
  surveyStatementTonesByQuestionAndGroups(payload) {
    return this.$axios
      .post('surveys/analyze/statement/tones/by/question/and/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Analyze - Analyze survey keyword sentiments by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Analyze-AnalyzeSurveyKeywordSentimentsByQuestionAndGroups
  surveyKeywordSentimentsByQuestionAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/keyword/sentiments/by/question/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Analyze - Analyze survey keyword tones by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Analyze-AnalyzeSurveyKeywordTonesByQuestionAndGroups
  surveyKeywordTonesByQuestionAndGroups(payload) {
    return this.$axios
      .post('surveys/analyze/keyword/tones/by/question/and/groups', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Statement_Analyze - Analyze survey keyword's statement sentiments by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Statement_Analyze-AnalyzeSurveyKeywordStatementSentimentsByQuestionAndGroups
  surveyKeywordStatementSentimentsByQuestionAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/keyword/statement/sentiments/by/question/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Statement_Analyze - Analyze survey keyword's statement tones by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Statement_Analyze-AnalyzeSurveyKeywordStatementTonesByQuestionAndGroups
  surveyKeywordStatementTonesByQuestionAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/keyword/statement/tones/by/question/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Volume_Analyze - Analyze survey keyword sentiment counts by phase and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Volume_Analyze-AnalyzeSurveyKeywordSentimentCountsByPhaseAndGroups
  surveyKeywordVolumeStatementSentimentsByPhaseAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/keyword/sentiment/counts/by/phase/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Volume_Analyze - Analyze survey keyword sentiment counts by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Volume_Analyze-AnalyzeSurveyKeywordSentimentCountsByQuestionAndGroups
  surveyKeywordVolumeStatementSentimentsByQuestionAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/keyword/sentiment/counts/by/question/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Keyword_Volume_Analyze - Analyze survey one keyword sentiment counts by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Keyword_Volume_Analyze-AnalyzeSurveyOneKeywordSentimentCountsByQuestionAndGroups
  surveyKeywordByQuestionAndGroups(payload) {
    return this.$axios
      .post(
        'surveys/analyze/one/keyword/sentiment/counts/by/question/and/groups',
        payload
      )
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey_Statement_Volume_Analyze - Analyze survey statement sentiment counts by question and groups
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Statement_Volume_Analyze-AnalyzeSurveyStatementSentimentCountsByQuestionAndGroups
  surveyStatementVolumeSentimentsByQuestionAndGroups(payload) {
    const topContent = () => {
      return this.$axios.post(
        'surveys/analyze/statement/sentiment/counts/by/question/and/groups',
        payload
      )
    }

    const bottomContent = () => {
      return this.$axios.post(
        'surveys/analyze/statement/sentiments/by/question/and/groups',
        payload
      )
    }

    return Promise.all([topContent(), bottomContent()])
      .then(([tc, bc]) => {
        return { topContent: tc, bottomContent: bc }
      })
      .catch(err => {
        return err.response
      })
  }

  // Survey - Highlight survey response
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-HighlightSurveyOpenResponse

  surveyHighlightResponse(payload) {
    console.log('surveyHighlightResponse, payload:', payload)
    return this.$axios
      .put(`surveys/response/${payload.id}/highlight`, {
        is_highlighted: payload.is_highlighted
      })
      .then(res => res.data)
      .catch(err => err.response)
  }
}
