<template>
  <v-avatar class="profile-avatar" color="bg-medium" :size="avatarSize">
    <!-- using imageName because safari is not getting the load event on page load -->
    <img v-show="imageName" :src="profileImg" @error="imageLoadError" @load="image = true">
    <span v-show="!imageName" class="black--text">{{ userInitials }}</span>
  </v-avatar>
</template>
    <script>
const profileImgBase = `https://arcadia-profile-images.s3.amazonaws.com/`
export default {
  components: {},
  props: {
    avatarSize: {
      type: [Number, String],
      default: 140
    },
    imageName: {
      default: null,
      type: String
    },
    userInitials: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      image: false
    }
  },
  computed: {
    profileImg() {
      return profileImgBase + this.imageName
    }
  },
  watch: {},
  created() {},
  updated() {},
  mounted() {},
  methods: {
    imageLoadError() {
      console.log('Image failed to load')
    },
    loadedifcomplete(image) {
      console.log('hihihihi', image)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
.profile-avatar.v-avatar {
  span {
    text-transform: uppercase;
    font-size: 3em;
  }
  &.nav-avatar {
    span {
      font-size: 0.75em;
    }
  }
  &.news-avatar {
    span {
      font-size: 1.5em;
    }
  }
}
</style>
