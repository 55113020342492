var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-avatar",
    {
      staticClass: "profile-avatar",
      attrs: { color: "bg-medium", size: _vm.avatarSize }
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.imageName,
            expression: "imageName"
          }
        ],
        attrs: { src: _vm.profileImg },
        on: {
          error: _vm.imageLoadError,
          load: function($event) {
            _vm.image = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.imageName,
              expression: "!imageName"
            }
          ],
          staticClass: "black--text"
        },
        [_vm._v(_vm._s(_vm.userInitials))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }