var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "div",
        {
          staticClass: "loading-page",
          class: { show: _vm.show },
          attrs: { id: "loading-page" }
        },
        [
          _vm.screenType === "loading"
            ? _c("div", { staticClass: "loading-page-content" }, [_vm._m(0)])
            : _vm._e(),
          _vm._v(" "),
          _vm.screenType === "error"
            ? _c("div", { staticClass: "loading-page-content" }, [
                _c(
                  "button",
                  {
                    staticClass: "loading-page-close",
                    on: {
                      click: function($event) {
                        _vm.loading = false
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("close")])],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c("p", { staticClass: "loading-page-copy" }, [
                    _vm._v("\n        " + _vm._s(_vm.error) + "\n      ")
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticClass: "loading-page-copy" }, [
        _vm._v("\n        Loading\n      ")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "loading-page-animation" }, [
        _c("li"),
        _vm._v(" "),
        _c("li"),
        _vm._v(" "),
        _c("li"),
        _vm._v(" "),
        _c("li")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }