var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { dark: "" } },
    [
      _c(
        "v-content",
        { staticClass: "content__wrap" },
        [
          _c("v-toolbar-title", { staticClass: "logo" }, [
            _vm._v("\n      Arcadia\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "verify-container" }, [_c("nuxt")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }