import Api from '@/services/Api'

export default class CategoryService extends Api {
  // Categories - Get list of categories
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Categories-GetCategories
  getCategories() {
    return this.$axios.get('categories').then(response => response.data)
  }

  // Categories - Create category
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Categories-CreateCategory
  createCategory(payload) {
    return this.$axios
      .post('categories', payload)
      .then(response => {
        // console.log('deleteUserFromWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }
}
