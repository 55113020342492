var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { "fill-rule": "evenodd", "stroke-width": "1.25" } },
    [
      _c("circle", { attrs: { cx: "8", cy: "8", r: "7" } }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M13 13.5l4.56 4.56", "stroke-linecap": "square" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }