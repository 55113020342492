var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { "fill-rule": "evenodd", "stroke-width": "1.25" } },
    [
      _c("path", {
        attrs: { d: "M11.5 21a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17z" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M14 3.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM22 17.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0zM6 17.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }