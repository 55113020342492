var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canFullScreen
    ? _c(
        "v-icon",
        {
          class: { open: _vm.fullscreenOpen },
          attrs: { id: "fullscreen", icon: "", color: _vm.color },
          on: { click: _vm.ToggleFullscreenDirective }
        },
        [_vm._v("\n  fullscreen\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }