import Api from '@/services/Api'
export default class SyndicatedService extends Api {
  // Import Survey Questions and CSV info
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SurveyVendor-SaveSurveyResponses
  importData(syndicatedData) {
    return this.$axios
      .post('syndicated/data/save', syndicatedData)
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log('importData(syndicatedData)ERROR:', err)
        return err.response
      })
  }

  // SyndicatedData - List of syndicated uploads for workspace
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SyndicatedData-ListSyndicatedPerWorkspcace
  getList(workspaceId) {
    return this.$axios
      .get(`syndicated/${workspaceId}/list`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        console.log('getList(workspaceId)ERROR:', err)
      })
  }

  // SyndicatedData - Filter - reset (auto: true)
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SyndicatedData-ResetFilter
  autoFilter(datasetId) {
    const payload = {
      segment_id: datasetId,
      auto: true
    }
    // console.log('>>>>>INDIVIDUAL AUTOFILTER PAYLOAD:', payload)
    return this.$axios
      .post('syndicated/filter/reset', payload)
      .then(res => {
        // console.log('<<<<<INDIVIDUAL AUTOFILTER RESPONSE:', res.data)
        return res.data
      })
      .catch(err => console.log('autoFilter ERROR:', err))
  }

  // SyndicatedData - Filter - reset (auto: false)
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SyndicatedData-ResetFilter
  showAll(datasetId) {
    const payload = {
      segment_id: datasetId,
      auto: false
    }
    return this.$axios
      .post('syndicated/filter/reset', payload)
      .then(res => res.data)
      .catch(err => console.log('showAll ERROR:', err))
  }

  // SyndicatedData - Filter - cumulative
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SyndicatedData-Filter
  updateDatasetFilter(payload) {
    // console.log('>>>>>INDIVIDUAL PAYLOAD:', payload)

    return this.$axios
      .post('syndicated/filter', payload)
      .then(res => {
        // console.log('<<<<<INDIVIDUAL RESPONSE:', res.data)
        return res.data
      })
      .catch(err => console.log('ERROR:', err))
  }

  compareDatasetsReset(payload) {
    // console.log('>>>>>COMPARE RESET PAYLOAD:', payload)

    return this.$axios
      .post('syndicated/filter/compare/reset', { ...payload, auto: false })
      .then(res => {
        // console.log('<<<<<COMPARE RESET RESPONSE:', res.data)
        return res.data
      })
      .catch(err => console.log('ERROR:', err))
  }

  compareDatasetsAutofilter(payload) {
    // console.log('>>>>>COMPARE AUTOFILTER PAYLOAD:', payload)

    return this.$axios
      .post('syndicated/filter/compare/reset', { ...payload, auto: true })
      .then(res => {
        // console.log('<<<<<COMPARE AUTOFILTER RESPONSE:', res.data)
        return res.data
      })
      .catch(err => console.log('ERROR:', err))
  }

  compareDatasetsFilter(payload) {
    // console.log('>>>>>COMPARE PAYLOAD:', payload)

    return this.$axios
      .post('syndicated/filter/compare', payload)
      .then(res => {
        // console.log('<<<<<COMPARE RESPONSE:', res.data)
        return res.data
      })
      .catch(err => console.log('ERROR:', err))
  }

  updateSyndicatedName(payload) {
    return this.$axios
      .put('syndicated/data', payload)
      .then(res => res.data)
      .catch(err => console.log('ERROR:', err))
  }

  // SyndicatedData - Archive syndicated response
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SyndicatedData-ArchiveSyndicatedData
  archiveSegment(payload) {
    return this.$axios
      .put(`/syndicated/${payload.id}/archive`, payload)
      .then(res => res.data)
      .catch(err => console.log('ERROR:', err))
  }
}
