var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { light: "" } },
    [
      _c("LeftNav"),
      _vm._v(" "),
      _c("TopNav"),
      _vm._v(" "),
      _c(
        "v-content",
        { staticClass: "content__wrap" },
        [
          _c(
            "TitleBar",
            { attrs: { title: _vm.surveyTitle } },
            [
              _c(
                "Button",
                {
                  attrs: {
                    variant: "secondary",
                    icon: "add_circle_outline",
                    to:
                      "/dashboard/" +
                      this.$route.params.brand +
                      "/surveys/import_survey"
                  }
                },
                [_vm._v("\n        Import Survey\n      ")]
              ),
              _vm._v(" "),
              _c("CreateSurvey")
            ],
            1
          ),
          _vm._v(" "),
          _c("nuxt")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }