<template>
  <v-app light>
    <LeftNav />
    <TopNav />
    <v-content class="content__wrap">
      <TitleBar :title="surveyTitle">
        <Button variant="secondary" icon="add_circle_outline" :to="`/dashboard/${this.$route.params.brand}/surveys/import_survey`">
          Import Survey
        </Button>
        <CreateSurvey />
      </TitleBar>
      <nuxt />
    </v-content>
    <!-- <Footer /> -->
  </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import LeftNav from '@/components/layouts/LeftNav'
import TopNav from '@/components/layouts/TopNav'
// import Footer from '@/components/layouts/Footer'
import Button from '@/components/ui/Button'
import CreateSurvey from '@/components/pages/survey/CreateSurvey'
import TitleBar from '@/components/ui/TitleBar'
export default {
  middleware: ['authenticated'],
  components: {
    TopNav,
    LeftNav,
    // Footer,
    Button,
    CreateSurvey,
    TitleBar
  },
  computed: {
    ...mapGetters({
      brandInfo: 'brandInfo',
      // section: 'nav/breadcrumbSection',
      surveyTitle: 'survey/getTitle'
    })
  },
  updated() {},
  mounted() {},
  beforeMount: function() {},
  head() {
    return {
      title: `${(this.brandInfo && this.brandInfo.name) || ''}  Surveys`,
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.content__wrap {
  position: relative;
}
#submenu {
  position: absolute;
  top: -64px;
}
</style>
