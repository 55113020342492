var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { id: "left-nav-wrapper" },
      on: { mouseover: _vm.handleMouseover, mouseout: _vm.handleMouseout }
    },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            value: "true",
            fixed: "",
            app: "",
            temporary: "",
            stateless: "",
            "disable-resize-watcher": "",
            "mini-variant": _vm.miniNav,
            "mini-variant-width": _vm.leftNavWidth,
            width: 200,
            "hide-overlay": "",
            dark: ""
          },
          on: {
            "update:miniVariant": function($event) {
              _vm.miniNav = $event
            },
            "update:mini-variant": function($event) {
              _vm.miniNav = $event
            }
          }
        },
        [
          _c(
            "v-layout",
            {
              staticClass: "left-nav-container",
              attrs: { column: "", "fill-height": "" }
            },
            [
              _c(
                "v-list",
                { staticClass: "home-trigger-container" },
                [
                  _vm.$store.state.isAuth
                    ? _c(
                        "v-list-tile",
                        { attrs: { to: "/dashboard", exact: "" } },
                        [
                          _c(
                            "v-list-tile-action",
                            { class: { "hover-offset-md-icon": !_vm.miniNav } },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    id: "home-icon",
                                    color:
                                      _vm.lastPath === "dashboard"
                                        ? _vm.selectedColor
                                        : _vm.iconColor
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              mdi-home-outline\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [
                                _vm._v("All Workspaces")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "nav-hr" }, [_c("hr")]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-list",
                [
                  _c("div", { staticClass: "nav-hr" }, [_c("hr")]),
                  _vm._v(" "),
                  _vm.roleId === 1
                    ? _c(
                        "v-list-tile",
                        { attrs: { to: "/admin/accounts", exact: "" } },
                        [
                          _c(
                            "v-list-tile-action",
                            { class: { "hover-offset-md-icon": !_vm.miniNav } },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: _vm.onEditAccounts
                                      ? _vm.selectedColor
                                      : _vm.iconColor
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              mdi-account-circle-outline\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [
                              _c("v-list-tile-title", [_vm._v("Edit Accounts")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$store.state.isAuth
                    ? _c(
                        "div",
                        { staticClass: "text-xs-center" },
                        [
                          _c(
                            "v-list-tile",
                            {
                              attrs: {
                                to:
                                  "/admin/profile/" +
                                  _vm.$store.state.auth.user.id
                              }
                            },
                            [
                              _c(
                                "v-list-tile-action",
                                { class: { "avatar-offset": !_vm.miniNav } },
                                [
                                  _c("Avatar", {
                                    staticClass: "nav-avatar",
                                    attrs: {
                                      "image-name": _vm.profileImg,
                                      "user-initials": _vm.userInitials,
                                      "avatar-size": "25"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-tile-content",
                                [
                                  _c("v-list-tile-title", [
                                    _vm._v(_vm._s(_vm.name))
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "nav-hr" }, [_c("hr")]),
                  _vm._v(" "),
                  _c(
                    "v-list-tile",
                    { staticClass: "tile" },
                    [
                      _c(
                        "v-list-tile-action",
                        { class: { "hover-offset-md-icon": !_vm.miniNav } },
                        [
                          _c("FullScreenButton", {
                            attrs: { on: _vm.fullscreen, color: _vm.iconColor },
                            on: { toggle: _vm.toggleFullscreen }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        { on: { click: _vm.toggleFullscreen } },
                        [_c("v-list-tile-title", [_vm._v("Fullscreen")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.$store.state.isAuth
                    ? _c(
                        "v-list-tile",
                        {
                          staticClass: "text-xs-center tile",
                          on: { click: _vm.logout }
                        },
                        [
                          _c(
                            "v-list-tile-action",
                            { class: { "hover-offset-md-icon": !_vm.miniNav } },
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: _vm.iconColor } },
                                [
                                  _vm._v(
                                    "\n              exit_to_app\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-tile-content",
                            [_c("v-list-tile-title", [_vm._v("Logout")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }