const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js');
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['checkWorkspaceIdMiddleware'] = require('../middleware/checkWorkspaceIdMiddleware.js');
middleware['checkWorkspaceIdMiddleware'] = middleware['checkWorkspaceIdMiddleware'].default || middleware['checkWorkspaceIdMiddleware']

middleware['healthcheck'] = require('../middleware/healthcheck.js');
middleware['healthcheck'] = middleware['healthcheck'].default || middleware['healthcheck']

middleware['isAdmin'] = require('../middleware/isAdmin.js');
middleware['isAdmin'] = middleware['isAdmin'].default || middleware['isAdmin']

middleware['isAdminOrStrategist'] = require('../middleware/isAdminOrStrategist.js');
middleware['isAdminOrStrategist'] = middleware['isAdminOrStrategist'].default || middleware['isAdminOrStrategist']

middleware['needsWorkspace'] = require('../middleware/needsWorkspace.js');
middleware['needsWorkspace'] = middleware['needsWorkspace'].default || middleware['needsWorkspace']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.js');
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['userRoleRedirect'] = require('../middleware/userRoleRedirect.js');
middleware['userRoleRedirect'] = middleware['userRoleRedirect'].default || middleware['userRoleRedirect']

export default middleware
