<template>
  <div>
    <v-flex id="dropdownholder" :key="componentKey" xs12 d-flex>
      <DropdownMenu
        v-model="audienceId"
        class="filter-dropdown"
        dark
        color="info"
        item-value="id"
        item-text="name"
        :items="audienceSelect"
        return-object
        @change="changeAudience"
      />
    </v-flex>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import DropdownMenu from '@/components/ui/DropdownMenu'

export default {
  components: {
    DropdownMenu
  },
  data: () => {
    return {
      obj: {},
      list: [],
      componentKey: 0
    }
  },
  computed: {
    ...mapGetters({
      brandInfo: 'brandInfo',
      survey: 'analyzeSurvey/getSurvey',
      audienceList: 'analyzeSurvey/getAudienceList',
      audienceIndex: 'analyzeSurvey/getAudienceIndex',
      audienceName: 'analyzeSurvey/getAudienceName',
      audienceId: 'analyzeSurvey/getAudienceId',
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      isloading: 'analyzeSurvey/getLoading',
      getCurrentView: 'analyzeSurvey/getCurrentView'
    }),
    audienceSelect: function() {
      const list = this.brandInfo.is_editable
        ? [
            {
              name: 'All Respondents',
              id: 0
            },
            {
              name: 'Compare your segments',
              id: -2,
              class: 'icon',
              icon: 'arrow_forward'
            },
            {
              name: 'Create new segment',
              id: -1,
              class: 'icon',
              icon: 'add_circle_outline'
            }
          ]
        : [
            {
              name: 'All Respondents',
              id: 0
            }
          ]
      return list.concat(this.audienceList.slice().reverse())
    }
  },
  methods: {
    ...mapActions({
      loadAudienceAndGroupFilters: 'analyzeSurvey/loadAudienceAndGroupFilters',
      setView: 'analyzeSurvey/setCurrentView',
      resetSurvey: 'analyzeSurvey/resetSurvey',
      setAudienceId: 'analyzeSurvey/setAudienceId',
      setAudienceName: 'analyzeSurvey/setAudienceName',
      removeSegmentation: 'analyzeSurvey/removeSegmentation',
      setLoading: 'analyzeSurvey/setLoading'
    }),
    changeAudience: function(obj) {
      /* apply-filter will be emitted in AudienceFilter.vue on audienceID change */

      if (obj.id === -2) {
        this.setView('COMPARE')
        this.componentKey += 1
      } else if (obj.id === -1) {
        this.$bus.$emit('create-audience', obj)
        this.setView('AUDIENCE')
        this.componentKey += 1
      } else if (obj.id === 0) {
        this.$nuxt.$loading.start()
        this.setAudienceId(obj.id)
        this.setAudienceName()

        this.setLoading(true)
      } else {
        this.$nuxt.$loading.start()
        this.setAudienceId(obj.id)

        this.setLoading(true)
        this.loadAudienceAndGroupFilters({
          id: obj.id,
          hashcode: this.survey.hashcode
        })
      }
    }
  }
}
</script>
<style lang="scss">
.info--text {
  color: #fff !important;
  caret-color: #fff !important;
}
.filter-dropdown {
  max-width: 300px;
}
.v-list__tile__title {
  font-weight: 700;
}
</style>
