var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "arcadia-createsurvey-dialog",
      attrs: { "content-class": "arcadia-createsurvey-dialog", width: "700" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _vm._v("saveBtn\n  "),
      _c("v-sheet", [
        _c("h2", { staticClass: "save-title" }, [
          _vm._v("\n      Create a new survey\n    ")
        ]),
        _vm._v(" "),
        _c("hr", { style: { color: "#d8d8d8#" } }),
        _vm._v(" "),
        _c("div", { staticClass: "save-container" }, [
          _c(
            "div",
            { staticClass: "form-container" },
            [
              _c("span", { staticClass: "form-description" }, [
                _vm._v(
                  "\n          You can create and field a survey right from Arcadia. Just input all your questions, share a link to gather responses, and then analyze it to form your insights.\n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  ref: "form",
                  staticClass: "access-form",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [
                  _c("TextField", {
                    attrs: {
                      label: "Enter a name for your survey",
                      rules: _vm.nameRules,
                      "error-messages": _vm.nameErrors,
                      autofocus: "",
                      placeholder: "Enter survey name",
                      "validate-on-blur": ""
                    },
                    model: {
                      value: _vm.name,
                      callback: function($$v) {
                        _vm.name = $$v
                      },
                      expression: "name"
                    }
                  }),
                  _vm._v(" "),
                  _c("Textarea", {
                    attrs: {
                      label: "Survey description",
                      solo: "",
                      placeholder:
                        "Leave a description about the goal of your survey.",
                      name: "input-7-4",
                      required: "",
                      "validate-on-blur": "",
                      "background-color": "#FFF",
                      rules: _vm.descriptionRules
                    },
                    model: {
                      value: _vm.description,
                      callback: function($$v) {
                        _vm.description = $$v
                      },
                      expression: "description"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { staticClass: "access-form-buttons" },
                    [
                      _c(
                        "Button",
                        {
                          staticClass: "contact-btn",
                          attrs: { variant: "primary" },
                          on: { click: _vm.submit }
                        },
                        [_vm._v("\n              Create survey\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "Button",
                        {
                          attrs: { variant: "outline" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_vm._v("\n              Cancel\n            ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }