import Api from '@/services/Api'
export default class ActivitiesService extends Api {
  // Activities - Get workspace recent activities by workspaceId
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Activities-GetRecentActivitiesByWorkspace
  getActivitiesByWorkspaceId(workspaceId) {
    return this.$axios
      .get(`activities/recent/${workspaceId}`)
      .then(response => response.data)
  }
}
