<template lang="html">
  <v-icon
    v-if="canFullScreen"
    id="fullscreen"
    :class="{open:fullscreenOpen}"
    icon
    :color="color"
    @click="ToggleFullscreenDirective"
  >
    fullscreen
  </v-icon>
</template>
<script>
import * as screenfull from 'screenfull'
export default {
  props: {
    color: {
      type: String,
      default: '#00FF00'
    },
    on: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      canFullScreen: false,
      fullscreenOpen: false
    }
  },
  computed: {},
  watch: {
    on: function() {
      screenfull.toggle()
    }
  },
  mounted() {
    if (!screenfull.enabled) return
    this.canFullScreen = screenfull.enabled
    screenfull.on('change', evt => {
      this.fullscreenOpen = screenfull.isFullscreen
    })
  },
  methods: {
    ToggleFullscreenDirective() {
      if (screenfull.enabled) {
        screenfull.toggle()
      }
      this.$emit('toggle')
    }
  }
}
</script>
<style lang="scss" scoped>
#fullscreen {
  cursor: pointer;
}
</style>
