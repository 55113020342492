var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-flex",
        {
          key: _vm.componentKey,
          attrs: { id: "dropdownholder", xs12: "", "d-flex": "" }
        },
        [
          _c("DropdownMenu", {
            staticClass: "filter-dropdown",
            attrs: {
              dark: "",
              color: "info",
              "item-value": "id",
              "item-text": "name",
              items: _vm.audienceSelect,
              "return-object": ""
            },
            on: { change: _vm.changeAudience },
            model: {
              value: _vm.audienceId,
              callback: function($$v) {
                _vm.audienceId = $$v
              },
              expression: "audienceId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }