// constants
const PERSONALITY_INSIGHT_VIOLIN = 'PERSONALITY_INSIGHT_VIOLIN'
const PERSONALITY_INSIGHT_BOX = 'PERSONALITY_INSIGHT_BOX'
const PERSONALITY_INSIGHT_DENSITY = 'PERSONALITY_INSIGHT_DENSITY'
const SEGMENTATION_QUESTION_BAR = 'SEGMENTATION_QUESTION_BAR'
const SEGMENTATION_QUESTION_HEAT_MAP = 'SEGMENTATION_QUESTION_HEAT_MAP'
const JOURNEY_STATEMENT_LINE = 'JOURNEY_STATEMENT_LINE'
const JOURNEY_KEYWORD_LINE = 'JOURNEY_KEYWORD_LINE'
const JOURNEY_KEYWORD_STATEMENT_LINE = 'JOURNEY_KEYWORD_STATEMENT_LINE'
const JOURNEY_STATEMENT_VOLUME = 'JOURNEY_STATEMENT_VOLUME'
const JOURNEY_KEYWORD_VOLUME = 'JOURNEY_KEYWORD_VOLUME'
const JOURNEY_KEYWORD_STATEMENT_VOLUME = 'JOURNEY_KEYWORD_STATEMENT_VOLUME'
const SEGMENT_BAR = 'SEGMENT_BAR'
const MULTIPLE_SEGMENT_BAR = 'MULTIPLE_SEGMENT_BAR'

export const state = () => ({})

export const getters = {
  PERSONALITY_INSIGHT_VIOLIN() {
    return PERSONALITY_INSIGHT_VIOLIN
  },
  PERSONALITY_INSIGHT_BOX() {
    return PERSONALITY_INSIGHT_BOX
  },
  PERSONALITY_INSIGHT_DENSITY() {
    return PERSONALITY_INSIGHT_DENSITY
  },
  SEGMENTATION_QUESTION_BAR() {
    return SEGMENTATION_QUESTION_BAR
  },
  SEGMENTATION_QUESTION_HEAT_MAP() {
    return SEGMENTATION_QUESTION_HEAT_MAP
  },
  JOURNEY_STATEMENT_LINE() {
    return JOURNEY_STATEMENT_LINE
  },
  JOURNEY_KEYWORD_LINE() {
    return JOURNEY_KEYWORD_LINE
  },
  JOURNEY_KEYWORD_STATEMENT_LINE() {
    return JOURNEY_KEYWORD_STATEMENT_LINE
  },
  JOURNEY_STATEMENT_VOLUME() {
    return JOURNEY_STATEMENT_VOLUME
  },
  JOURNEY_KEYWORD_VOLUME() {
    return JOURNEY_KEYWORD_VOLUME
  },
  JOURNEY_KEYWORD_STATEMENT_VOLUME() {
    return JOURNEY_KEYWORD_STATEMENT_VOLUME
  },
  SEGMENT_BAR() {
    return SEGMENT_BAR
  },
  MULTIPLE_SEGMENT_BAR() {
    return MULTIPLE_SEGMENT_BAR
  }
}
