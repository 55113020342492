<template>
  <v-container class="title pa-0 ma-0 segment-answers">
    <v-layout
      class="w-100 pb-2 pt-2 font-weight-bold"
      justify-space-between
      row
      style="color:#fff;font-size:12px;"
    >
      <div>SHOWING</div>
      <div>RESPONDENTS</div>
    </v-layout>
    <no-ssr>
      <v-content
        v-for="(group, index) in groups"
        :id="'group'+parseInt(index+1)"
        :key="index"
        class="pt-0 pl-1 ml-1"
        :class="'group'+parseInt(index+1)"
        style="cursor:pointer;"
      >
        <div
          :id="'sub-group-'+parseInt(index+1)"
          class="group p1-2 pr-2 pt-1 pb-0"
          style="margin-left:-30px;"
        >
          <div :id="index+1" class="text-14" style="margin-bottom: 5px;">
            <v-flex class="groupname">
              <v-layout align-center justify-space-between row>
                <div class="leftside">
                  <div ref="groupname" @mouseleave="hover = false">
                    <v-icon v-if="!group.Answers.length">
                      arrow_right
                    </v-icon>
                    <v-icon v-if="group.Answers.length">
                      arrow_drop_down
                    </v-icon>
                    <img src="@/assets/images/folder.svg">
                    <span class>{{ group.group_name }}</span>
                    <span
                      v-if="!group.Answers.length"
                      class="dragnaddrop pb-2"
                      style="font-style: italic"
                    >Drag items here</span>
                  </div>
                </div>
                <div>
                  <div>
                    <v-btn
                      v-show="group.Answers.length"
                      text
                      small
                      flat
                      class="pa-0 ma-0 group-button"
                      @click="onUngroup(group,index)"
                    >
                      Ungroup
                    </v-btn>
                    <v-btn
                      text
                      small
                      flat
                      class="pa-0 ma-0 group-button"
                      @click="onNameChange(index)"
                    >
                      Rename
                    </v-btn>
                    <span
                      text
                      small
                      flat
                      style="width:30px;display:inline-block;"
                      class="pa-0 ma-0 text-xs-right"
                    >{{ group.total }}</span>
                  </div>
                </div>
              </v-layout>
              <div v-show="rename && currentIndex == index">
                <input
                  ref="gname"
                  v-model="group.group_name"
                  type="text"
                  style="color:black"
                  class="rename text-14 pl-2 ml-2"
                  @blur="onBlur(group,index)"
                  @keyup.enter="onKeyUp(index)"
                >
              </div>
            </v-flex>
          </div>
        </div>
        <div class="draggable-group-container">
          <draggable
            :id="'draggable-group-'+(index+1).toString()"
            ref="answer"
            :groupid="group.id"
            :list="group.Answers"
            group="answers"
            @start="isDragging=true"
            @end="sortByIndex(group)"
            @add="updateGroup(group)"
          >
            <div v-for="(answer,index2) in group.Answers" :key="index2">
              <v-layout
                v-if="answer.respondent_count > 0"
                ref="answer"
                justify-space-between
                class="answer mb-2 pl-2 pr-2"
              >
                <input
                  :id="`group-${answer.id.toString()}`"
                  :key="index2"
                  ref="checkbox"
                  type="checkbox"
                  :checked="answer.checked || false"
                  :data-id="answer.id"
                  :copy="answer.text"
                  :data-count="answer.respondent_count"
                  :name="id.toString()"
                  :label="answer.text"
                  :value="answer.text"
                  class="mt-0 mb-1 ml-2 draggable-answer text-white styled-checkbox"
                  @input="onFilterChange(group, `group-${answer.id.toString()}`, 'group')"
                >
                <label :for="`group-${answer.id.toString()}`">{{ answer.text }}</label>
                <div class="font-weight-bold" style="font-size:12px">
                  {{ answer.respondent_count }}
                </div>
              </v-layout>
            </div>
          </draggable>
        </div>
      </v-content>
    </no-ssr>
    <v-content id="ungrouped" class="pt-0">
      <v-list>
        <no-ssr>
          <draggable
            id="draggable-group-0"
            ref="ungrouped"
            :list="ungrouped.Answers"
            group="answers"
            @start="isDragging=true"
            @end="sortByIndex(ungrouped)"
            @add="updateGroup(ungrouped)"
          >
            <div v-for="(answer,index) in ungrouped.Answers" :key="index">
              <v-layout
                v-if="answer.respondent_count > 0"
                ref="answer"
                justify-space-between
                class="answer mb-1 pl-2 pr-2 pb-1 pt-1"
                :class="{'over': over}"
              >
                <input
                  :id="`ungrouped-${ungrouped,answer.id.toString()}`"
                  :key="index"
                  type="checkbox"
                  :checked="answer.checked || false"
                  :data-id="answer.id"
                  :copy="answer.text"
                  :data-count="answer.respondent_count"
                  :name="id.toString()"
                  :label="answer.text"
                  :value="answer.text"
                  class="mt-0 mb-1 draggable-answer text-white styled-checkbox"
                  @change="onFilterChange(ungrouped, `ungrouped-${answer.id.toString()}`, 'ungrouped')"
                >
                <label :for="`ungrouped-${ungrouped,answer.id.toString()}`" @click="logLabelClick">{{ answer.text }}</label>
                <div class="font-weight-bold" style="font-size:12px">
                  {{ answer.respondent_count }}
                </div>
              </v-layout>
            </div>
          </draggable>
        </no-ssr>
      </v-list>
    </v-content>
    <v-layout align-center justify-space-between row>
      <v-btn small flat class="creategroup lowercase ma-0" @click="addGroup(groups)">
        <v-icon class="mr-1">
          add_circle_outline
        </v-icon>CREATE NEW GROUP
      </v-btn>
      <v-btn
        v-if="groups.length"
        small
        flat
        class="lowercase ma-0 pl-0"
        @click="ungroupAll(groups)"
      >
        Ungroup all
      </v-btn>
    </v-layout>
  </v-container>
</template>
    <script>
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import utils from '@/js/utils.js'

export default {
  components: {
    draggable
  },
  props: {
    id: {
      type: Number,
      default: null
    },

    currentAnswer: {
      default: false,
      type: Boolean
    }
  },

  data: () => {
    return {
      selected: [],
      groups: [],
      rename: false,
      ungrouped: [],
      groupid: 1,
      isDragging: false,
      over: false,
      currentIndex: 0,
      hover: false,
      filterGroups: {
        filters: [],
        groups: [],
        ungrouped: []
      }
    }
  },
  computed: {
    ...mapGetters({
      audienceId: 'analyzeSurvey/getAudienceId',
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      audienceQuestions: 'analyzeSurvey/getAudienceQuestions',
      audienceResponseFilters: 'analyzeSurvey/getAudienceResponseFilters'
    }),
    answers() {
      if (this.audienceId === 0) {
        return utils.getSegmentQuestion(this.segmentQuestions, this.id)[0]
          .SurveyAnswers
      } else {
        return utils.getSegmentQuestion(this.audienceQuestions, this.id)[0]
          .SurveyAnswers
      }
    }
  },
  watch: {
    currentAnswer() {
      if (this.currentAnswer) {
        this.setSegmentation()
      }
    }
  },
  created() {
    // 1. create answers based on orig segment question to compare agianst filtered question
    this.$set(this, 'ungrouped', this.setUngroupedSelected(this.answers))
    // this.ungrouped = this.setUngroupedSelected(this.answers)
  },
  mounted() {
    // *** temp storage this
    this.setSegmentation()

    // update draggable panel info after getting response from api by changing main segmentation
    this.$bus.$on('update-draggable-answers', groupInfo => {
      this.onUpdateMainSegmentation(groupInfo)
    })
  },

  destroyed() {
    this.$bus.$off('update-draggable-answers')
  },

  methods: {
    logLabelClick(ev) {
      // console.log('logLabelClick, ev.target.htmlFor:', ev.target.htmlFor)
    },
    ...mapActions({
      setFilterGroups: 'analyzeSurvey/setCurrentQuestionFilterGroups',
      setUngroupedFilters: 'analyzeSurvey/setSegmentQuestionFilterUngrouped',
      setSegGroups: 'analyzeSurvey/setSegGroups'
    }),

    // Prevent dragging selections out of order from DB
    sortByIndex(group) {
      return group.Answers.sort((a, b) => a.index - b.index)
    },

    // return answers for the ungrouped items
    setUngroupedSelected(a) {
      let groups = a
      let ungrouped = this.answers
      if (this.groups.length) {
        groups = this.groups.flatMap(a => {
          return a.Answers.map(a => {
            return a.id
          })
        })
        ungrouped = this.answers.filter(function(item) {
          return !groups.includes(item.id)
        })
      }
      return {
        id: this.groupid,
        group_name: 'group0',
        Answers: this.setAnswersChecked(ungrouped)
      }
    },

    // return answers set checked for ungrouped items
    setAnswersChecked(a) {
      return a.map((b, index) => {
        return { ...b, checked: true, name: b.id, index: index }
      })
    },

    // return updated answers when the filters change
    updateAnswers(a, i, groupedOrUngrouped) {
      return a.map(a => {
        // console.log('anser id', `${groupedOrUngrouped}-${a.id}`)
        // console.log('target id', i.getAttribute('id'))
        if (`${groupedOrUngrouped}-${a.id}` === i.getAttribute('id')) {
          return Object.assign({}, a, { checked: i.checked })
        } else {
          return a
        }
      })
    },

    // put all checked items to an array of ids for API call
    getAnswersIdsInArray(answers) {
      return answers.filter(a => a.checked === true).map(a => a.id)
    },

    // return a group for each ungrouped item that is checked and have a respondent_count > 0 to api call
    getApiUnGrouped(a) {
      return a.map(c => {
        return {
          group_name: '',
          Answers: [c.id]
        }
      })
    },

    // Combine grouped and upgrouped items for API call
    combineForApiCall(grouped, ungrouped) {
      return grouped.concat(ungrouped)
    },

    getGroupsWithAnswers(arr) {
      const isfalse = element => element.checked === false
      return arr.filter(group => {
        return !group.Answers.every(isfalse)
      })
    },

    // called when adding an item
    updateGroup(group, answer) {
      this.$nextTick(() => {
        // get sum of responses for each group
        this.getRespondentTotals(this.groups)
        // set groups in store
        this.setSegmentation()
      })
    },

    // set grouping for charts api call and FiterPanel grouping display
    setSegmentation() {
      const grouped = cloneDeep(this.getGroupsWithAnswers(this.groups))
      const ungrouped = cloneDeep(this.getCheckedAns(this.ungrouped.Answers))
      const apiGroups = this.combineForApiCall(
        this.getApiGroups(grouped),
        this.getApiUnGrouped(ungrouped)
      )

      this.$emit('updateSegmentation', {
        grouped,
        ungrouped,
        apiGroups
      })

      // this.setSegGroups(apiGroups)
      // this.setFilterGroups(cloneDeep(grouped))
      // this.setUngroupedFilters(cloneDeep(ungrouped))
    },

    // Get groups for API call
    getApiGroups(arr) {
      return arr.map(g => {
        return {
          group_name: g.group_name,
          Answers: this.getAnswersIdsInArray(g.Answers)
        }
      })
    },

    // return ungrouped items that are checked and have a respondent_count
    getCheckedAns(arr) {
      return arr.filter(ans => ans.checked === true && ans.respondent_count > 0)
    },

    // Update group totals when adding or removing items
    getRespondentTotals(groups) {
      groups.forEach(group => {
        group.total = group.Answers.reduce(function(acc, ans) {
          return acc + ans.respondent_count
        }, 0)
      })
    },

    // Only return groups that have answers selected
    getGroupApiObj(g) {
      return Object.assign({}, { groups: g.filter(gr => gr.Answers.length) })
    },

    // Create grouping object for FilterPanel
    getPanelGroupingObj(groups, ungrouped) {
      return Object.assign({}, { groups: groups, ungrouped: ungrouped })
    },

    // Listener to update the group names when clicking out of the input box
    onBlur(group, index) {
      if (group.group_name === '') {
        this.groups[index].group_name = 'Group ' + (index + 1)
      }
      this.rename = false
      this.setSegmentation()
    },
    onKeyUp(index) {
      this.rename = false
    },

    // Click event listener for for thc create new groups button
    addGroup(group) {
      this.groups.push({
        id: this.groupid,
        group_name: 'Group ' + parseInt(this.groupid),
        Answers: [],
        total: 0
      })
      this.groupid++
    },

    // Listener to show the text input box
    onNameChange(index) {
      this.rename = true
      this.currentIndex = index
      if (this.groups[index].group_name === 'Group ' + (index + 1))
        this.groups[index].group_name = ''

      this.$nextTick(() => {
        this.$refs.gname[index].focus()
      })
    },
    // Change event listener when you check or uncheck an grouped item
    onFilterChange(group, id, groupedOrUngrouped) {
      // console.log('onFilterChange, arguments:', arguments)
      this.$nextTick(() => {
        const target = document.getElementById(id)
        const newAnswers = this.updateAnswers(
          group.Answers,
          target,
          groupedOrUngrouped
        )
        if (groupedOrUngrouped === 'ungrouped') {
          this.$set(this, groupedOrUngrouped, { ...group, Answers: newAnswers })
        } else {
          const newGroup = { ...group, Answers: newAnswers }
          const newArray = this.groups.map(
            _group => (_group.id === newGroup.id ? newGroup : _group)
          )
          this.$set(this, 'groups', newArray)
        }
        this.setSegmentation()
        // let SegmentFilter know if all inputs are unchecked or not
        this.$bus.$emit('enable-segments', this.noInputsChecked())
      })
    },
    // Check if no options are selected
    noInputsChecked() {
      // console.log('this.ungrouped.Answers:', this.ungrouped.Answers)
      const ungroupedChecked = this.ungrouped.Answers.filter(
        item => item.checked
      )

      const groupChecked = []
      this.groups.forEach(g => {
        g.Answers.forEach(item => {
          if (item.checked) groupChecked.push(item)
        })
      })

      // console.log(
      //   'noInputsChecked:',
      //   ungroupedChecked.concat(groupChecked).length
      // )
      if (!ungroupedChecked.concat(groupChecked).length) {
        return true
      } else {
        return false
      }
    },

    // remove a group from segmentation
    onUngroup(group, index) {
      group.Answers = []
      const groups = this.groups
      groups.splice(index, 1)
      this.groups = groups
      this.ungrouped = this.setUngroupedSelected()
      this.setSegmentation()
    },

    // reset to original answers
    ungroupAll(groups) {
      this.groups = []
      this.setFilterGroups()
      this.setUngroupedFilters()
      this.ungrouped = this.setUngroupedSelected(this.answers)
      this.setSegmentation()
    },

    onUpdateMainSegmentation({ groups, ungrouped }) {
      // get id for filter purpose
      const groupedID = []
      groups.forEach(el => {
        el.Answers.forEach(_el => groupedID.push(_el.id))
      })
      const ungroupedID = ungrouped.map(el => (el = el.id))

      // set grouped answers
      this.groups = this.cleanSource(groups)

      // remove grouped answers from ungrouped if exist
      this.ungrouped.Answers = this.ungrouped.Answers.filter(
        answer => !groupedID.includes(answer.id)
      )

      // uncheck items if not returned from api
      this.ungrouped.Answers.forEach(answer => {
        if (!ungroupedID.includes(answer.id)) answer.checked = false
      })

      this.setSegmentation()
    },

    cleanSource(source) {
      // using native JSON functions removes reactivity
      // so we can clone an object without mutating the original source
      return JSON.parse(JSON.stringify(source))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_custom_checkbox.scss';

.draggable-group-container {
  padding-left: 20px;
}
.answer {
  align-items: center;
}
</style>
