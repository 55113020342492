var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      staticClass: "tabs-container survey-navigation-container",
      attrs: { "slider-color": "secondary" }
    },
    _vm._l(_vm.sections, function(ref) {
      var id = ref.id
      var name = ref.name
      var disabled = ref.disabled
      var icon = ref.icon
      var svgIcon = ref.svgIcon
      return _c(
        "v-tab",
        {
          key: id,
          attrs: {
            to:
              "/dashboard/" +
              _vm.$route.params.brand +
              "/surveys/" +
              _vm.$route.params.survey +
              "/" +
              id,
            ripple: "",
            disabled: disabled
          },
          on: {
            change: function($event) {
              return _vm.onChange(id)
            }
          }
        },
        [
          icon
            ? _c("v-icon", { staticClass: "icon" }, [
                _vm._v("\n      " + _vm._s(icon) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          svgIcon
            ? _c("span", { staticClass: "svg-icon-container" }, [
                _c("img", { attrs: { src: svgIcon } })
              ])
            : _vm._e(),
          _vm._v("\n    " + _vm._s(name) + "\n  ")
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }