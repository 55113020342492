var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { light: "" } },
    [_c("v-content", { attrs: { id: "homepage" } }, [_c("nuxt")], 1)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }