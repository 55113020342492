<template>
  <v-content class="loader pa-2">
    <v-layout
      justify-space-between
      class="pt-2 pr-2 pl-2 font-weight-bold filter-footer-label"
    >
      <div>
        Showing
        {{ percentage }}% of respondents
      </div>
      <div>{{ value }} of {{ total }}</div>
    </v-layout>
    <div class="progress">
      <div
        id="inner"
        ref="inner"
        class="inner"
        :style="{
          width: percentage + '%',
        }"
      />
    </div>
  </v-content>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: -1
    },
    value: {
      type: Number,
      default: -1
    }
  },
  computed: {
    percentage() {
      return this.value === 0 && this.total === 0
        ? 0
        : ((this.value / this.total) * 100).toFixed(0)
    }
  }
}
</script>
<style lang="scss" scoped>
.loader {
  background: #333;
  position: fixed;
  bottom: 0;
  width: 25%;
  height: 60px;
  color: #fff;

  &.compare-segments-loader {
    position: relative;
    display: block !important;
    background: #333;
    flex-basis: 60px !important;
    max-height: 60px !important;
    width: 100%;
  }

  .progress {
    width: 96%;
    height: 3px;
    background: #444;
    position: absolute;
    left: 2%;
    top: 36px;
  }

  .inner {
    width: 100%;
    background: #ffe800;
    height: 3px;
    -webkit-transition: width 2s;
    /* Safari */
    transition: width 2s;
  }
}
</style>
