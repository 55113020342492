import Api from '@/services/Api'
export default class SurveyService extends Api {
  // Get list of surveys by workspace
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-GetSurveyList
  getSurveyList(brandInfo) {
    const id = brandInfo.id
    return this.$axios
      .get(`workspaces/${id}/surveys`)
      .then(response => response.data)
  }

  // Create New Survey
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-CreateSurvey
  createSurvey(payload) {
    return this.$axios.post('surveys', payload)
    // .then(response => {
    //   return response.data
    // })
    // .catch(err => {
    //   return err.response
    // })
  }

  // Import Survey Questions and CSV info
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SurveyVendor-SaveSurveyResponses
  importSurvey(SurveyData) {
    return this.$axios.post('/surveys/responses', SurveyData).then(response => {
      return response.data
    })
  }

  // Poll Survey Save
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SurveyVendor-PollSurveyResponses
  importSavePoll(hash) {
    return this.$axios.get(`/surveys/${hash}/responses/poll`).then(response => {
      // Get Survey By Id/' + id).then(response => {
      return response.data
    })
  }

  // Poll Survey Watson Api
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SurveyVendor-CallWatsonAndSaveResponses
  surveyProcessWatson(hash) {
    return this.$axios.post(`/surveys/${hash}/watson`, '').then(response => {
      return response.data
    })
  }

  // Poll Survey Watson Api
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-SurveyVendor-PollWatsonLoad
  importWatsonPoll(hash) {
    return this.$axios.get(`/surveys/${hash}/watson/poll`).then(response => {
      // Get Survey By Id/' + id).then(response => {
      return response.data
    })
  }

  // Get Survey By Id
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-GetSurveyById
  getSurvey(id) {
    return this.$axios.get('surveys/' + id).then(response => {
      return response.data
    })
  }

  // Add Question to Survey
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Question-CreateSurveyQuesition
  addQuestion(question) {
    return this.$axios.post('surveys/questions', question).then(response => {
      return response.data
    })
  }

  // Delete Question from Survey
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Question-DeleteSurveyQuesition
  deleteQuestion(question) {
    return this.$axios
      .delete('surveys/questions', { data: question })
      .catch(err => console.log(err.response))
  }

  // Update Question
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Question-UpdateSurveyQuesition
  updateQuestion(question) {
    return this.$axios
      .put('surveys/questions', question)
      .then(res => {
        return res.data
      })
      .catch(err => console.log(err))
  }

  // Update Survey Preview
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-UpdateSurveyPreview
  updateSurveyPreview(payload) {
    return this.$axios
      .put('/surveys/preview', payload)
      .then(res => {
        // console.log('updateSurveyPreview API, res:', res)
        return res.data
      })
      .catch(err => console.log(err))
  }

  // Update Survey Name Description
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-UpdateSurveyNameAndDescription
  updateSurveyNameDescription(payload) {
    return this.$axios
      .put('/surveys/nameanddesc', payload)
      .then(res => res.data)
      .catch(err => console.log(err))
  }

  // Survey - Update survey flags
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-UpdateSurveyFlags
  updateSurveyFlags(payload) {
    return this.$axios
      .put('/surveys/flags', payload)
      .then(res => res.data)
      .catch(err => console.log(err))
  }

  // Gather Responses page api...Survey - Request list of respondents data
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-GetSurveyRespondentList
  getRespondents(surveyHash) {
    return this.$axios
      .get(`/surveys/${surveyHash}/respondents`)
      .then(res => {
        // console.log('getRespondents, res.data:', res.data)
        return res.data
      })
      .catch(err => console.log('getRespondents ERROR:', err))
  }

  // Survey - Get survey respondent answers by survey hashcode and respondent id.
  // https://havas-arcadia-api-dev.tech4.ny.havasww.com/surveys/:hashcode/respondents/:respondentId
  getRespondentSurvey(data) {
    const { surveyHash, respondentId } = data
    return this.$axios
      .get(`/surveys/${surveyHash}/respondents/${respondentId}`)
      .then(res => res.data)
      .catch(err => console.log('getRespondentSurvey ERROR:', err))
  }

  surveyRespondentsGroupResponseFilters(payload) {
    return this.$axios
      .post('/surveys/analyze/respondent/count/by/filters', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Hide Respondent data
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-UpdateSurveyRespondentHidden
  updateRespondentHidden(data) {
    const { surveyId, respondentId, isHidden } = data
    return this.$axios
      .put(
        // `/surveys/566/respondents/92583/hide/true`,
        `/surveys/${surveyId}/respondents/${respondentId}/hide/${isHidden}`,
        ''
      )
      .then(res => res.data)
      .catch(err => console.log('getRespondentSurvey ERROR:', err))
  }

  // Take Survey - Post Respondent Responses
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-SubmitRespondentResponses
  postRespondentResponses(payload) {
    return this.$axios
      .post('/surveys/respondents/responses', payload)
      .then(res => res.data)
      .catch(err => console.log(err))
  }

  // https://havas-arcadia-api-dev.tech4.ny.havasww.com/surveys/respondent/group
  postAudienceGroup(payload) {
    return this.$axios
      .post('/surveys/respondent/group', payload)
      .then(res => res.data)
      .catch(err => console.log(err))
  }

  // https://havas-arcadia-api-dev.tech4.ny.havasww.com/surveys/respondent/group
  updateAudienceGroup(payload) {
    // console.log('update audience group from surveyservice', payload)
    return this.$axios
      .put('/surveys/respondent/group', payload)
      .then(res => {
        return res.data
      })
      .catch(err => console.log(err))
  }

  // https://havas-arcadia-api-dev.tech4.ny.havasww.com/surveys/respondent/group
  deleteAudienceGroup(payload) {
    // console.log('deleteAudienceGroup, payload:', payload)
    return this.$axios
      .delete('/surveys/respondent/group', payload)
      .then(res => {
        return res.data
      })
      .catch(err => console.log(err))
  }

  // Survey_Question - Update survey question flags
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey_Question-UpdateFlags
  toggleAnalyzeHidden(payload) {
    return this.$axios.put('surveys/question/flags', payload)
  }

  createJourney(payload) {
    console.log('SurveyService, createJourney, payload:', payload)
    return this.$axios
      .post('/surveys/journeys', payload)
      .then(res => {
        // console.log('SurveyService, createJourney, res.data:', res.data)
        return res.data
      })
      .catch(err => console.log(err))
  }

  // https://havas-arcadia-api-dev.tech4.ny.havasww.com/surveys/:surveyId/respondent/group
  getAudienceList(surveyId) {
    return this.$axios
      .get(`/surveys/${surveyId}/respondent/group`)
      .then(res => {
        // console.log('getAudience, res.data:', res.data)
        return res.data
      })
  }

  // https://havas-arcadia-api-dev.tech4.ny.havasww.com/surveys/respondent/group/:groupId
  getAudience(groupId) {
    return this.$axios
      .get(`/surveys/respondent/group/${groupId} `)
      .then(res => {
        // console.log('getAudience, res.data:', res.data)
        return res.data
      })
  }

  getJourney(journeyId) {
    return this.$axios.get(`/surveys/journeys/${journeyId}`).then(res => {
      return res.data.Journey
    })
  }

  updateJourney(payload) {
    return this.$axios.put('/surveys/journeys', payload).then(res => res.data)
  }

  // eslint-disable-next-line prettier/prettier
  getSurveyResponse(surveyId) {
    return this.$axios
      .get(`/surveys/${surveyId}/respondent/counts`)
      .then(res => res.data)
  }

  updateJourneyQuestionOrder(payload) {
    return this.$axios
      .put('/surveys/journeys/questions', payload)
      .then(res => res.data)
  }

  // Survey - Get survey responses CSV file
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-GetSurveyResponsesCSV
  getSurveyResponsesCSV(hash) {
    return this.$axios
      .get(`surveys/${hash}/responses`)
      .then(response => response.data)
  }

  // Survey - Get survey respondent answers by survey hashcode and respondent id.
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Survey-GetSurveyResponsesByRespondent
  getSurveyRespondentByHashAndId(hash, respondentId) {
    return this.$axios.get(`surveys/${hash}/respondents/${respondentId}`)
  }
}
