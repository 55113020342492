<template>
  <div>
    <v-select
      v-bind="$attrs"
      :items="items"
      outline
      flat
      :menu-props="{ contentClass: 'my-special-class' }"
      dense
      solo
      hide-details
      @change="onChange"
    >
      <template slot="item" slot-scope="data" class="dropdown-item">
        <div
          v-if="data.item.icon"
          class="dropdown-menu-item"
          :class="{
            'icon-item': data.item.icon,
            'border-bottom': data.item.id === -1,
          }"
        />
        <v-icon v-if="data.item.icon" class="dropdown-round-item-icon">
          {{ data.item.icon }}
        </v-icon>
        <v-list-tile-content class="my-content-class">
          <v-list-tile-title class="my-title-class">
            {{ data.item.name || data.item.text }}
          </v-list-tile-title>
        </v-list-tile-content>
        <slot name="prepend-item" />
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      default: () => [],
      type: Array
    },
    width: {
      default: '',
      type: String
    },
    variant: {
      default: '',
      type: String
    },
    styles: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  updated() {},
  mounted() {},
  methods: {
    onChange(event) {
      if (event.id === this.$attrs.value) return
      this.$emit('change', event)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
@import '@/assets/css/_customDropDown.scss';
</style>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
.dropdown-menu-item.icon-item {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  &.border-bottom {
    border-bottom: 1px solid color(font-medium);
  }
}
</style>
