import Api from '@/services/Api'

export default class StrategyService extends Api {
  // DataPoint - Syndicated Data Point - Save
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-SaveSyndicatedDataPoint
  saveDatapointSyndicated(payload) {
    return this.$axios
      .post('syndicated/data/point', payload)
      .then(res => {
        // console.log('res:', res)
        return res
      })
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Survey - Save
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-SaveSurveyDataPoint
  saveDatapointSurvey(payload) {
    return this.$axios
      .post('survey/data/point', payload)
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Syndicated - Update
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-UpdateSyndicatedDataPoint
  updateDatapointSyndicated(payload) {
    return this.$axios
      .put('syndicated/data/point', payload)
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Survey - Update
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-UpdateSurveyDataPoint
  updateDatapointSurvey(payload) {
    return this.$axios
      .put('survey/data/point', payload)
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Syndicated - Delete
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-DeleteSyndicatedDataPoint
  deleteDatapointSyndicated(payload) {
    return this.$axios
      .delete(`syndicated/data/point/${payload.id}`, payload)
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Survey - Delete
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-DeleteSurveyDataPoint
  deleteDatapointSurvey(payload) {
    return this.$axios
      .delete(`survey/data/point/${payload.id}`, payload)
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Data Point Survey / Syndicated Dropdown List
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-GetDataPointSurveySyndicatedDropdownList
  getDatapointSources(workspaceId) {
    return this.$axios
      .get(`workspace/${workspaceId}/list`)
      .then(res => {
        return res
      })
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Data Point Syndicated List - Retrieve
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-GetDataPointSyndicatedList
  getDatapointListSyndicated(workspaceId, segmentId) {
    return this.$axios
      .get(`workspace/${workspaceId}/syndicated/${segmentId}/data/points`)
      .then(res => {
        // console.log('res:', res)
        return res
      })
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Data Point Survey List - Retrieve
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-GetDataPointSurveyList
  getDatapointListSurvey(workspaceId, surveyId, userId, sortBy = 'recent') {
    return this.$axios
      .get(
        `workspace/${workspaceId}/survey/${surveyId}/user/${userId}/data/points/sort/${sortBy}`
      )
      .then(res => {
        return res
      })
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Survey - Retrieve Graph Data
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-RetrieveSurveyDataPointGraph
  getDatapointSurvey(datapointId) {
    return this.$axios
      .get(`survey/data/point/${datapointId}`)
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Syndicated - Retrieve Graph Data
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-RetrieveSyndicatedDataPointGraph
  getDatapointSyndicated(datapointId) {
    return this.$axios
      .get(`syndicated/data/point/${datapointId}`)
      .then(res => {
        // console.log('res:', res)
        return res
      })
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Survey Data Point User List - Retrieve
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-GetSurveyDataPointUserList
  getDatapointSurveyUserList(workspaceId, surveyId) {
    return this.$axios
      .get(`workspace/${workspaceId}/survey/${surveyId}/users`)
      .then(res => {
        // console.log('res:', res)
        return res
      })
      .catch(err => console.log('err:', err))
  }

  // DataPoint - Syndicated Data Point User List - Retrieve
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-DataPoint-GetSyndicatedDataPointUserList
  getDatapointSyndicatedUserList(workspaceId, syndicatedId) {
    return this.$axios
      .get(`workspace/${workspaceId}/syndicated/${syndicatedId}/users`)
      .then(res => {
        // console.log('res:', res)
        return res
      })
      .catch(err => console.log('err:', err))
  }
}
