<template>
  <v-layout align-center>
    <v-flex id="breadcrumbs">
      <v-breadcrumbs :items="breadcrumbs" pl-0>
        <template slot="item" slot-scope="props">
          <li>
            <a
              class="v-breadcrumbs__item"
              @click="$router.push(props.item.href)"
            >{{ props.item.text }}</a>
          </li>
        </template>
        <v-icon slot="divider">
          chevron_right
        </v-icon>
      </v-breadcrumbs>
    </v-flex>
    <v-flex>
      <SurveyTitleForm v-if="onSurveyDesignSection" />
      <SyndicatedTitleForm v-if="onSyndicatedSegment" />
    </v-flex>
  </v-layout>
</template>

<script>
/* eslint no-useless-escape : 0 */ // in case regex includes useless escape slashes
import { mapGetters } from 'vuex'
import SurveyTitleForm from '@/components/pages/survey/SurveyTitleForm'
import SyndicatedTitleForm from '@/components/pages/syndicated/SyndicatedTitleForm'
export default {
  components: {
    SurveyTitleForm,
    SyndicatedTitleForm
  },
  data() {
    return {
      dashboardPages: [
        'insights',
        'stories',
        'personas',
        'journeys',
        'syndicated',
        'surveys',
        'social',
        'search',
        'strategy'
      ],
      adminPages: [
        'settings',
        'activity',
        'accounts',
        'journeys',
        'categories',
        'clients',
        'profile'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentDataset: 'syndicated/currentDataset'
    }),
    matchedPath() {
      return this.$route.matched[0].path
    },
    workspaceId() {
      return this.$route.params.brand
    },
    surveyHash() {
      return this.$route.params.survey
    },
    onSyndicated() {
      const regex = new RegExp(
        '^/dashboard/((?:[^/]+?))/syndicated/segments(?:/(?=$))?$',
        'i'
      )
      return regex.test(this.matchedPath)
    },
    onSyndicatedSegment() {
      return (
        this.onSyndicated &&
        (this.currentDataset ? this.currentDataset.id !== 'compare' : false)
      )
    },
    onSurvey() {
      // regex derived from this.$route.matched[0].regex
      const regex = new RegExp(
        '^/dashboard/((?:[^/]+?))/surveys/((?:[^/]+?))/((?:[^/]+?))(?:/(?=$))?$$',
        'i'
      )
      return regex.test(this.matchedPath)
    },
    onSurveyDesignSection() {
      const regex = new RegExp(
        '^/dashboard/((?:[^/]+?))/surveys/((?:[^/]+?))/design(?:/(?=$))?$',
        'i'
      )
      return regex.test(this.matchedPath)
    },
    onSurveyImport() {
      const regex = new RegExp(
        '^/dashboard/((?:[^/]+?))/surveys/import_survey(?:/(?=$))?$',
        'i'
      )
      return regex.test(this.matchedPath)
    },
    onWorkspace() {
      const regex = new RegExp('^/dashboard/((?:[^/]+?))(?:/(?=$))?$', 'i')
      return regex.test(this.matchedPath)
    },
    onDashboard() {
      const regex = new RegExp('^/dashboard(?:/(?=$))?$', 'i')
      return regex.test(this.matchedPath)
    },
    onProfile() {
      const regex = new RegExp(
        '^/admin/profile(?:/((?:[^/]+?)))?(?:/(?=$))?$',
        'i'
      )
      return regex.test(this.matchedPath)
    },
    dashboardCrumb() {
      return { href: `/dashboard`, text: 'Workspaces' }
    },
    workspaceCrumb() {
      return {
        href: `/dashboard/${this.workspaceId}`,
        text: this.$store.state.brandInfo
          ? this.$store.state.brandInfo.name
          : ''
      }
    },
    profileCrumb() {
      return { href: '/admin/profile/', text: 'Profile' }
    },
    adminCrumb() {
      return { href: `/admin`, text: 'Admin' }
    },
    surveyImportCrumb() {
      return {
        href: `/dashboard/${this.workspaceId}/surveys/import_survey`,
        text: 'Import'
      }
    },
    syndicatedCrumb() {
      return {
        href: `/dashboard/${this.workspaceId}/syndicated`,
        text: 'Syndicated'
      }
    },
    breadcrumbs() {
      /* Dashboard */
      if (this.onDashboard) {
        return [this.dashboardCrumb]
      }
      if (this.onWorkspace) {
        return [this.dashboardCrumb, this.workspaceCrumb]
      }
      if (this.onSurvey) {
        return this.makeSurveyCrumbs()
      }
      if (this.onSurveyImport) {
        return this.makeSurveyImportCrumbs()
      }
      // if on a dashboard page, make breadcrumbs for it
      for (let i = 0; i < this.dashboardPages.length; i++) {
        const page = this.dashboardPages[i]
        if (this.onDashboardPage(page)) {
          return this.makeDashboardCrumbs(page)
        }
      }

      /* Admin */
      if (this.onProfile) {
        return [this.adminCrumb, this.profileCrumb]
      }
      // if on an admin page, make breadcrumbs for it
      for (let i = 0; i < this.adminPages.length; i++) {
        const page = this.adminPages[i]
        if (this.onAdminPage(page)) {
          return this.makeAdminCrumbs(page)
        }
      }

      /* Syndicated */
      if (this.onSyndicated) {
        return [
          this.dashboardCrumb,
          this.workspaceCrumb,
          this.syndicatedCrumb,
          {
            disabled: true,
            text: this.currentDataset ? this.currentDataset.name : '',
            href: ''
          }
        ]
      }

      return []
    }
  },
  watch: {
    $route: function() {},
    breadcrumbs: function() {}
  },
  methods: {
    onDashboardPage(page) {
      const regex = new RegExp(
        `^\/dashboard\/((?:[^\/]+?))\/${page}(?:\/(?=$))?$`,
        'i'
      )
      return regex.test(this.matchedPath)
    },

    onAdminPage(page) {
      const regex = new RegExp(`^\/admin\/${page}(?:\/(?=$))?$`, 'i')
      return regex.test(this.matchedPath)
    },
    makeDashboardCrumbs(page) {
      return [
        this.dashboardCrumb,
        this.workspaceCrumb,
        {
          href: `/dashboard/${this.workspaceId}/${page}`,
          text: this.capitalize(page)
        }
      ]
    },
    makeAdminCrumbs(page) {
      return [
        this.adminCrumb,
        {
          href: `/admin/${page}`,
          text: this.capitalize(page)
        }
      ]
    },
    makeSurveyCrumbs() {
      return [
        ...this.makeDashboardCrumbs('surveys'),
        {
          href: `/dashboard/${this.workspaceId}/surveys/${
            this.surveyHash
          }/design`,
          text: this.$store.state.survey.title
        }
      ]
    },
    makeSurveyImportCrumbs() {
      return [...this.makeDashboardCrumbs('surveys'), this.surveyImportCrumb]
    },
    capitalize(str) {
      const words = str.split('_')
      const capitalized = words.map(word => {
        const firstLetter = word[0]
        const restOfWord = word.slice(1)
        return `${firstLetter.toUpperCase()}${restOfWord}`
      })
      return capitalized.join(' ')
    }
  }
}
</script>

<style lang='scss'>
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.v-breadcrumbs {
  padding-left: 0;
}

a {
  @include headline-small-strong;
}

#breadcrumbs {
  /* margin-left: $spacing-base; */
}
</style>
