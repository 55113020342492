var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { light: "" } },
    [
      _c("LeftNav"),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "nav-offset" } },
        [
          _c("TopNav"),
          _vm._v(" "),
          _c(
            "v-content",
            {
              staticClass: "content__wrap",
              class: { hasSubnav: _vm.hasSurveyNav }
            },
            [_c("nuxt")],
            1
          ),
          _vm._v(" "),
          _vm.showFilters
            ? _c(
                "div",
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "fixed-container",
                      attrs: { "d-flex": "", xs3: "" }
                    },
                    [_c("v-layout", [_c("FilterPanel")], 1)],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "holder" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "slide-fade" } },
                        [
                          _c(
                            "v-flex",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentView == "SEGMENT",
                                  expression: "currentView == 'SEGMENT'"
                                }
                              ],
                              staticClass: "panel segments",
                              attrs: { xs12: "" }
                            },
                            [_c("SegmentFilter")],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "slide-fade" } },
                        [
                          _c(
                            "v-flex",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentView == "AUDIENCE",
                                  expression: "currentView == 'AUDIENCE'"
                                }
                              ],
                              staticClass: "panel audience",
                              attrs: { xs12: "" }
                            },
                            [_c("AudienceFilter")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }