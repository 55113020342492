var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "loader pa-2" },
    [
      _c(
        "v-layout",
        {
          staticClass: "pt-2 pr-2 pl-2 font-weight-bold filter-footer-label",
          attrs: { "justify-space-between": "" }
        },
        [
          _c("div", [
            _vm._v(
              "\n      Showing\n      " +
                _vm._s(_vm.percentage) +
                "% of respondents\n    "
            )
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(_vm.value) + " of " + _vm._s(_vm.total))])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "progress" }, [
        _c("div", {
          ref: "inner",
          staticClass: "inner",
          style: {
            width: _vm.percentage + "%"
          },
          attrs: { id: "inner" }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }