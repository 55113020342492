<template>
  <div id="top-nav">
    <v-toolbar
      v-if="this"
      app
      dense
      flat
    >
      <div id="first-top-item">
        <BreadCrumbs v-if="$store.state.isAuth" />
      </div>
      <v-spacer />

      <Button v-if="isAdmin && $route.params.brand && inWorkspace" :to="`${baseURL}/edit`" variant="outline" :small="true">
        workspace settings
      </Button>
    </v-toolbar>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import BreadCrumbs from '@/components/layouts/BreadCrumbs'
import Button from '@/components/ui/Button'

export default {
  components: {
    BreadCrumbs,
    Button
  },
  data() {
    return {
      title: 'arcadia'
    }
  },
  computed: {
    ...mapGetters({
      roleId: 'auth/roleId'
    }),
    ...mapState(['auth']),
    isAdmin() {
      return this.roleId === 1
    },
    baseURL: function() {
      const client = this.$route.params.brand
      return `/dashboard/${client}`
    },
    inWorkspace() {
      return this.baseURL + '/' === this.$route.path
    }
  },
  watch: {
    $route() {}
  },
  mounted() {},
  methods: {
    ...mapActions(['toggleMiniNav'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
#top-nav {
  z-index: 1000;
}
#first-top-item {
  padding-left: $leftnav-width;
}
#title {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 22px;
  color: white;
}

#home-icon {
  margin-right: 0.625em;
}

#toolbar-extension {
  margin-left: 0px;
}

a {
  color: white;
  text-decoration: none;
}

.theme--light.v-toolbar {
  background-color: white;
}
</style>
