<template>
  <v-btn
    v-bind="$attrs"
    :type="type"
    :class="classes"
    :to="to"
    :round="round"
    flat
    :dark="dark"
    :disabled="disabled"
    :small="small"
    @mousedown="$emit('mousedown')"
    @mouseup="$emit('mouseup')"
    @click="$emit('click', $event)"
  >
    <v-icon v-if="icon">
      {{ icon }}
    </v-icon>
    <v-spacer v-if="icon" />
    <slot />
  </v-btn>
</template>

<script>
export default {
  components: {},
  props: {
    text: {
      default: '',
      type: String
    },
    dark: {
      default: false,
      type: Boolean
    },
    type: {
      default: 'button', // or submit
      type: String
    },
    variant: {
      default: 'primary',
      type: String
    },
    icon: {
      default: '',
      type: String
    },
    to: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    round: {
      default: false,
      type: Boolean
    },
    capitalize: {
      default: false,
      type: Boolean
    },
    small: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  computed: {
    classes() {
      return `${this.variant} ${this.capitalize ? 'capitalize' : ''}`
    }
  },
  watch: {},
  created() {},
  updated() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';

.v-btn {
  color: black;
  @include headline-small-strong;
  padding: 0px 14px;
  font-size: 14px;
  margin: 6px 5px;
  text-transform: uppercase;

  &.v-btn--small {
    font-size: 12px;
  }

  .v-icon {
    color: black;
  }

  &.v-btn--round {
    text-transform: none;
  }
}

.primary {
  background-color: color('primary') !important;
  color: color('font-dark') !important;
}

.noBackground {
  color: #fff;
  border-color: #fff;
}

.secondary {
  background-color: color('bg-dark') !important;
  color: color('font-light') !important;
}

.tertiary {
  background-color: color('bg-medium-dark') !important;
  color: color('font-light') !important;
}

.outline {
  background-color: transparent !important;
  border: 1px solid black !important;
}

.outline-light {
  background-color: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 0px;
  color: color('font-light') !important;
  &:disabled {
    border: 1px solid color('font-medium') !important;
  }
}

.outline-medium {
  background-color: transparent !important;
  border: 1px solid color('font-medium') !important;
  border-radius: 0px;
  color: color('font-medium');
  &:disabled {
    border: 1px solid color('font-medium') !important;
  }
}

.selected {
  border: 1px solid rgba(0, 0, 0, 0);
  color: color('font-light') !important;
  background-color: color('bg-dark') !important;
}

.unselected {
  border: 1px solid color('bg-dark');
  color: color('font-dark');
}

.warning {
  background-color: #ffe2dc !important;
  @include headline-small-soft;
  color: #ff4d28 !important;
}

.white {
  background-color: #fff;
  color: #000;
}

.v-btn--icon {
  width: 100%;
  &:before {
    border-radius: 0px;
  }
}
.theme--light.v-btn.v-btn--disabled.secondary {
  color: rgba(color('font-light'), 0.5) !important;
  background-color: color('bg-dark') !important;
  opacity: 0.2;
}
// light bg, dark text if not disabled
.theme--dark.v-btn.secondary:not(.v-btn--disabled) {
  color: rgba(color('font-dark'), 1) !important;
  background: rgba(color('bg-light'), 1) !important;
}

// light bg, dark text if not disabled
.theme--dark.v-btn.secondary.dark-outline:not(.v-btn--disabled) {
  color: rgba(color('font-light'), 1) !important;
  background: rgba(color('bg-dark'), 1) !important;
  border: 1px color('bg-light') solid !important;
}

div {
  margin: 0px 2px;
}
</style>
