export const state = () => ({
  checklist: {
    persona: 0,
    segmented: 0,
    journey: 0,
    phases: []
  },
  surveyIsLocked: false,
  responsesCount: 0,
  title: '',
  description: '',
  id: null
})

export const getters = {
  // checklist: state => {
  //   return state.checklist
  // }
  getTitle: state => state.title,
  surveyIsLocked: state => state.surveyIsLocked || state.responsesCount !== 0
}

export const mutations = {
  // setChecklist(state, checklist) {
  //   state.checklist = checklist;
  // },
  setTitle(state, title) {
    state.title = title
  },
  setSurveyLocked(state, survey) {
    state.surveyIsLocked = survey.is_locked
    state.responsesCount = survey.responses_count
  },
  setId(state, id) {
    state.id = id
  },
  setDescription(state, description) {
    state.description = description
  }
}

export const actions = {
  // setChecklist({ commit }, val) {
  // 	// console.log('val',val)
  //   commit('setChecklist', val)
  // },
  setTitle({ commit }, val) {
    commit('setTitle', val)
  },
  setSurveyLocked({ commit }, survey) {
    commit('setSurveyLocked', survey)
  },
  setId({ commit }, id) {
    commit('setId', id)
  },
  setDescription({ commit }, description) {
    commit('setDescription', description)
  }
}
