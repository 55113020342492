import { render, staticRenderFns } from "./SurveyNavigation.vue?vue&type=template&id=8316aa66&scoped=true&"
import script from "./SurveyNavigation.vue?vue&type=script&lang=js&"
export * from "./SurveyNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SurveyNavigation.vue?vue&type=style&index=0&id=8316aa66&lang=scss&scoped=true&"
import style1 from "./SurveyNavigation.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8316aa66",
  null
  
)

/* vuetify-loader */
import installComponents from "!/var/www/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib'
import { VTab } from 'vuetify/lib'
import { VTabs } from 'vuetify/lib'
installComponents(component, {VIcon,VTab,VTabs})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('8316aa66', component.options)
    } else {
      api.reload('8316aa66', component.options)
    }
    module.hot.accept("./SurveyNavigation.vue?vue&type=template&id=8316aa66&scoped=true&", function () {
      api.rerender('8316aa66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/pages/survey/SurveyNavigation.vue"
export default component.exports