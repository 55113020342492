var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-btn",
        {
          attrs: { slot: "activator", icon: "" },
          on: { click: _vm.prepopulateText },
          slot: "activator"
        },
        [
          _c("v-icon", { attrs: { id: "edit-syndicated" } }, [
            _vm._v("\n      edit\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "card-title" },
            [
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    [
                      _c("v-layout", { attrs: { column: "" } }, [
                        _c("h2", [_vm._v("Segment Info")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "segment-definition" }, [
                          _vm._v(_vm._s(_vm.currentDataset.query))
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.dialog = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit($event)
                }
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "form-field" },
                    [
                      _c("label", { staticClass: "bold" }, [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          placeholder: "Provide a name for your segment",
                          rules: _vm.nameRules,
                          "error-messages": _vm.nameErrors,
                          "hide-details": "",
                          "validate-on-blur": "",
                          box: ""
                        },
                        model: {
                          value: _vm.name,
                          callback: function($$v) {
                            _vm.name = $$v
                          },
                          expression: "name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "form-field" },
                    [
                      _c("label", { staticClass: "bold" }, [
                        _vm._v("Description")
                      ]),
                      _vm._v(" "),
                      _c("v-textarea", {
                        attrs: {
                          placeholder:
                            "Leave a description about why you’re creating this CSV",
                          rules: _vm.descriptionRules,
                          "error-messages": _vm.descriptionErrors,
                          "hide-details": "",
                          "validate-on-blur": "",
                          box: ""
                        },
                        model: {
                          value: _vm.description,
                          callback: function($$v) {
                            _vm.description = $$v
                          },
                          expression: "description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "Button",
                    {
                      attrs: {
                        variant: "primary",
                        type: "submit",
                        disabled: _vm.unchanged
                      }
                    },
                    [_vm._v("\n          Save Changes\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }