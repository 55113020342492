export const state = () => ({
  token: null,
  user: {}
})

// export const getters = {
//   firstName: state => state.auth.user.first_name,
//   lastName: state => state.auth.user.last_name,
//   email: state => state.auth.user.email,
//   profileImg: state => state.auth.user.profileImg,
//   id: state => state.auth.user.id,
//   roleId: state => state.auth.user.role_id
// }

export const getters = {
  roleId: state => state.user.role_id,
  isAdmin: state => state.user.role_id === 1
}

export const mutations = {
  setAuth(state, auth) {
    if (!auth) return
    state.token = auth.token
    state.user = { ...auth.user }
  },
  updateUser(state, user) {
    state.user.first_name = user.first_name
    state.user.last_name = user.last_name
    state.user.image_name = user.image_name
  }
}

export const actions = {
  updateUser({ commit }, user) {
    commit('updateUser', user)
  }
}
