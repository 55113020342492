const utils = {
  changeClass(el, str, option) {
    switch (option) {
      case 'add':
        return el.map(ref => {
          return ref.classList.add(str)
        })
      case 'remove':
        return el.map(ref => {
          return ref.classList.remove(str)
        })
    }
  },
  curry(fn, arity = fn.length) {
    return (function nextCurried(prevArgs) {
      return function curried(nextArg) {
        const args = [...prevArgs, nextArg]
        if (args.length >= arity) {
          return fn(...args)
        } else {
          return nextCurried(args)
        }
      }
    })([])
  },
  pipe(...fns) {
    return function(x) {
      return fns.reduce(function(v, f) {
        return f(v)
      }, x)
    }
  },

  getSegmentQuestion(arr, b) {
    return arr.filter(q => q.id === b)
  },
  getAudienceQuestion(arr, b) {
    return arr.filter(q => q.id === b)
  },
  assignToObject(arr) {
    return Object.assign({}, arr[0])
  },
  getMatchById(arr1, arr2) {
    const arr = []
    arr1.forEach(a1 =>
      arr2.forEach(a2 => {
        if (a1.id === a2) {
          a1 = Object.assign({}, a1, { checked: true })
          arr.push(a1)
        }
      })
    )
    return arr
  },

  sortByText(obj, value) {
    const sortBy = value || 'text'
    return [...obj].sort((a, b) => {
      const textA = a[sortBy].toUpperCase()
      const textB = b[sortBy].toUpperCase()
      return textA < textB ? -1 : textA > textB ? 1 : 0
    })
  },
  filteredQuestions(a1 = [], a2 = []) {
    const q = a1.map(i => i.question_id)
    return a2.filter(i => {
      return q.includes(i.id)
    })
  },
  filteredGroupQuestions(a1 = [], a2 = []) {
    const q = a2.map(i => i.id)
    return a1.filter(i => {
      return q.includes(i.id)
    })
  },
  getSum(total, num) {
    return total + num
  },
  updateObjectInArray(array, action) {
    return array.map(item => {
      if (item.id === action.answer_id) {
        return Object.assign({}, item, {
          respondent_count: action.respondent_count
        })
      }
    })
  },
  getCurrentTime() {
    return new Date()
  },
  isSafari() {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1
  },
  exportPDF() {
    const px2cmRatio = 0.026 // 0.02645833333333
    const download = document.createElement('a')
    download.download = this.name + '.pdf'
    download.target = '_blank'
    let html = this.$refs.wrapper.cloneNode(true)
    html.id = 'pdfStyles'
    document.body.appendChild(html)
    // this.$refs.wrapper.id = 'pdfStyles'

    /* add additional pixel to top right section for horizontal alignment when no segments */
    const target = document.querySelector('#pdfStyles .dynamicTopRightHeight')
    if (target) {
      const targetHeight = parseInt(target.style.height)
      target.setAttribute('style', `height: ${targetHeight + 4}px`)
    }

    const exportHeight = html.offsetHeight * px2cmRatio // this.$refs.wrapper.offsetHeight * px2cmRatio
    if (!this.isDemo) this.$nuxt.$loading.start()
    const payload = {
      html: html.outerHTML,
      height: exportHeight
    }
    console.log('PAYLOAD = ' + JSON.stringify(payload))
    // console.log(this.$refs.wrapper.offsetHeight, this.$refs.wrapper.offsetWidth)
    // this.$refs.wrapper.style.width = '100%'
    console.log(
      'height',
      this.$refs.wrapper.offsetHeight,
      'width',
      this.$refs.wrapper.offsetWidth,
      payload
    )
    if (this.isDemo) return
    // this.$refs.wrapper.id = ''
    html.remove()
    this.$api.util
      .htmlToPdf(payload)
      .then(response => {
        if (response.data && response.data.link) {
          const file = response.data.link.split(
            'https://arcadia-pdf.s3.amazonaws.com/'
          )[1]
          const fileProxy = '/pdfExport/' + file

          download.href = fileProxy

          // snall timeout to
          setTimeout(() => {
            download.click()
            this.$nuxt.$loading.finish()
            download.remove()
          }, 250)
        }
        console.log(response, 'htmlToPdf')
      })
      .catch(err => console.log('ERROR:', err))
    html = null
  },
  exportSVG(id, emotionText) {
    // Find the SVG element inside the specified container
    const svgElement = document.querySelector('#averageChart .the-chart')
    if (!svgElement) {
      console.error('SVG element not found!')
      return
    }

    // Serialize the SVG element to a string
    const svgData = new XMLSerializer().serializeToString(svgElement)
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
    const svgUrl = URL.createObjectURL(svgBlob)

    // Create a temporary download link and trigger the download
    const downloadLink = document.createElement('a')
    downloadLink.href = svgUrl
    downloadLink.download = `${id}-${emotionText.replace(' ', '-')}-chart.svg`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  },
  // Responsive design
  currentBreakpoint() {
    if (window.matchMedia('(max-width: 599px)')) {
      return 'xs'
    } else if (
      window.matchMedia('(min-width: 600px)') &&
      window.matchMedia('(max-width: 959px)')
    ) {
      return 'sm'
    } else if (
      window.matchMedia('(min-width: 960px)') &&
      window.matchMedia('(max-width: 1263px)')
    ) {
      return 'md'
    } else if (
      window.matchMedia('(min-width: 1264px)') &&
      window.matchMedia('(min-width: 1904px)')
    ) {
      return 'lg'
    } else {
      return 'xl'
    }
  }
}
export default utils
