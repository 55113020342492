var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      attrs: {
        stroke: _vm.color,
        "fill-rule": "evenodd",
        "stroke-width": "1.25"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M8.001 17.91c-.872-.015-1.72-.195-2.567-.392-1.07-.25-2.146-.476-3.217-.723-.84-.193-1.296-.928-1.206-1.877.104-1.088.157-2.182.286-3.266.27-2.264 1.362-3.85 3.282-4.711.06-.027.171.003.223.052 1.792 1.694 4.493 1.72 6.344.003.11-.102.197-.088.31-.034 1.951.92 3.012 2.567 3.241 4.883.105 1.064.19 2.13.267 3.197.066.91-.393 1.575-1.204 1.757-1.467.33-2.934.662-4.405.967-.444.092-.902.099-1.354.145"
        }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d:
            "M11.75 4.58A3.732 3.732 0 0 1 8 8.293c-2.05-.013-3.762-1.731-3.764-3.778C4.234 2.446 5.93.745 7.991.748c2.093.003 3.765 1.708 3.759 3.831z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }