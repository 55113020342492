<template>
  <div class="filterpanel">
    <v-flex xs12 class="pl-3 pr-3 pb-3 pt-3">
      <v-layout align-center justify-space-between row class="mb-2">
        <v-flex class="font-weight-bold" style="color: white">
          Filter results
        </v-flex>
        <v-flex class="text-xs-right">
          <v-layout right class="info-btn">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div class="custom-info" dark max-width="50" v-on="on" />
              </template>
              <v-spacer />
              <template class="info-tooltip">
                <p style="max-width: 200px">
                  Create segments based on responses from demographic and
                  psychographic questions. If you asked for respondents’ ages,
                  you can create a segment that shows only respondents of a
                  certain age.
                </p>
                <p style="max-width: 200px">
                  Once you’ve defined your segment, use another question to
                  compare sub-segments. For example, within your age segment,
                  you could sub-segment using a gender or income question.
                </p>
              </template>
            </v-tooltip>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="bg-black">
      <v-layout
        align-center
        justify-space-between
        row
        class="mb-3 pt-2 pb-2 pl-3 pr-3"
        style="background: #333333"
      >
        <v-flex class="text-18 font-weight-bold">
          Your segment
        </v-flex>
        <v-flex v-if="brandInfo.is_editable" class="text-xs-right">
          <v-btn
            outline
            small
            color="white"
            class="filter-btn"
            style="min-width: 60px"
            @click="showMyAudience($event)"
          >
            Edit
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout wrap align-center class="pt-2 mb-2 ml-3 mr-3">
        <v-flex xs12 d-flex class="font-weight-bold">
          <FilterSelect />
        </v-flex>

        <v-flex class>
          <v-expansion-panel dark class="segment-detail-expansion-panel">
            <!-- THE SLIDE DOWN COMPONENT THAT CONTAINS THE SURVEY ANSWERS -->
            <v-expansion-panel-content
              v-for="(item, i) in 1"
              id="expansionpanel"
              :key="i"
            >
              <!-- v-model="panel" -->
              <template v-slot:header>
                <div class="segment-details-label" style="text-align: center">
                  Segment details
                </div>
              </template>
              <simplebar
                id="simplebar1"
                ref="simplebar"
                class="simplebar1"
                data-simplebar-auto-hide="true"
              >
                <no-ssr>
                  <div>
                    <v-card
                      v-for="(question, index) in surveyQuestions"
                      :key="index"
                      style="border-bottom: 1px solid #333"
                      flat
                    >
                      <v-card-text>
                        <v-flex style="color: #999999; text-align: left">
                          <span class="question-text">{{ question.text }}</span>
                        </v-flex>
                        <DropdownAnswers
                          :id="question.id"
                          :answers="question.SurveyAnswers"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </no-ssr>
              </simplebar>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-flex>

        <!-- background="primary" -->
      </v-layout>
      <no-ssr>
        <v-layout
          align-center
          justify-space-between
          row
          class="mb-3 pt-2 pb-2 pl-3 pr-3"
          style="background: #333333"
        >
          <v-flex class="text-18 font-weight-bold">
            Compare sub-segments
          </v-flex>
          <v-flex class="text-xs-right">
            <v-btn
              id="segments"
              outline
              small
              color="white"
              class="filter-btn"
              style="min-width: 60px"
              @click="showMySegments()"
            >
              Edit
            </v-btn>
          </v-flex>
        </v-layout>
        <!-- Bottom -->
        <v-flex class="filter-container">
          <v-flex>
            <div
              v-if="currentQuestionId == 0"
              class="segmentname grey--text caption"
            >
              *no segment selected
            </div>
          </v-flex>
          <!-- Before choosing segment -->
          <v-flex v-if="currentQuestionId == 0">
            <v-layout justify-space-between class="pl-3 pr-3 pt-2">
              <v-flex class="audience-name-container">
                <span
                  class="circle small-circle"
                  :data-id="0"
                  :style="{ backgroundColor: backgroundColor(0) }"
                />
                <span class="audience-name">{{ audienceName }}</span>
              </v-flex>
              <div class="font-weight-bold" style="float: right">
                {{ getFilteredResponsesCount }}
              </div>
            </v-layout>
          </v-flex>
          <v-content
            class="pt-0 pl-4 pr-4 mb-4 font-weight-bold"
            v-text="currentQuestion"
          />
          <!-- Not Sure -->
          <simplebar
            id="simplebar2"
            class="simplebar2 pl-4 pr-4"
            data-simplebar-auto-hide="false"
          >
            <div id="filter-datapoint-html">
              <v-flex v-if="grouped.length" xs12 class="pt-0">
                <v-content
                  v-for="(group, index) in grouped"
                  :key="index"
                  class="pt-0 pl-2 pr-2 mb-2 group"
                  style="padding-left: 30px !important"
                >
                  <v-layout align-center justify-space-between row>
                    <div
                      v-if="group.Answers.length"
                      :id="group.group_name"
                      class="mb-2 grp-name"
                    >
                      {{ group.group_name }}
                    </div>
                    <div class="font-weight-bold">
                      {{ group.total }}
                    </div>
                  </v-layout>
                  <v-flex>
                    <span
                      v-if="group.Answers.length"
                      class="circle small-circle"
                      :data-id="index"
                      :style="{ backgroundColor: backgroundColor(index) }"
                      style="
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        display: inline-block;
                        margin-right: 10px;
                        position: absolute;
                        top: 0;
                        left: -30px;
                      "
                    />
                    <v-flex v-for="(answer, ind) in group.Answers" :key="ind">
                      <span
                        v-if="answer.checked == true"
                        :id="answer.id"
                        ref="rolloverbtn"
                        class="rolloverbtn"
                        @mouseover="onAnswerOver(index, answer)"
                        @mouseleave="onAnswerOut"
                      >
                        <div>
                          <span class="target">{{ answer.text }}</span>
                        </div>
                        <div class="target font-weight-bold">
                          {{ answer.respondent_count }}
                        </div>
                      </span>
                    </v-flex>
                  </v-flex>
                </v-content>
              </v-flex>

              <!-- After choosing segment -->
              <v-layout column @mouseleave="onPanelOut">
                <v-flex
                  v-for="(answer, index) in ungrouped"
                  :key="index"
                  justify-space-around
                  class="mb-2"
                >
                  <Subsegments
                    :enable-scroll="enableScroll"
                    :answer="answer"
                    :index="index"
                    :grouped-length="grouped.length"
                    :background-color="backgroundColor(index + grouped.length)"
                  />
                </v-flex>
              </v-layout>
            </div>
          </simplebar>
        </v-flex>
      </no-ssr>
      <FilterProgress
        :value="getFilteredResponsesCount"
        :total="getActiveResponseCount"
      />
    </v-flex>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import simplebar from 'simplebar-vue'
import FilterSelect from '@/components/pages/survey/filters/FilterSelect'
import DropdownAnswers from '@/components/pages/survey/filters/DropdownAnswers'
import Subsegments from '@/components/pages/survey/filters/Subsegments'
import colorGenerator from '@/js/colorGenerator'
import FilterProgress from '@/components/pages/survey/filters/FilterProgress'

export default {
  components: {
    FilterSelect,
    DropdownAnswers,
    simplebar,
    Subsegments,
    FilterProgress
  },
  data() {
    return {
      grouped: [],
      ungrouped: []
      // panel: false
    }
  },
  computed: {
    ...mapGetters({
      brandInfo: 'brandInfo',
      survey: 'analyzeSurvey/getSurvey',
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      audienceQuestions: 'analyzeSurvey/getAudienceQuestions',
      currentQuestionId: 'analyzeSurvey/getCurrentQuestionId',
      currentQuestion: 'analyzeSurvey/getCurrentQuestion',
      groupedSegments: 'analyzeSurvey/getSegmentQuestionFilterGroups',
      ungroupedSegments: 'analyzeSurvey/getSegmentQuestionFiltersUngrouped',
      audienceId: 'analyzeSurvey/getAudienceId',
      audienceName: 'analyzeSurvey/getAudienceName',
      audienceGroupResponses: 'analyzeSurvey/getAudienceResponseFilters',
      getActiveResponseCount: 'analyzeSurvey/getActiveResponseCount',
      getSegmentedResponseCount: 'analyzeSurvey/getSegmentedResponseCount',
      getFilteredResponsesCount: 'analyzeSurvey/getFilteredResponsesCount',
      analyzeJourneyView: 'analyzeSurvey/getAnalyzeJourneyView'
    }),

    surveyQuestions() {
      if (this.audienceId === 0) {
        return this.segmentQuestions
      } else {
        return this.audienceQuestions
      }
    },
    enableScroll() {
      return this.analyzeJourneyView === 'detail'
    }
  },

  mounted() {
    this.$bus.$on('update-filters-panel', groupInfo => {
      this.setPanelGrouping(groupInfo)
    })
    this.$bus.$on('reset-filters-panel', () => {
      this.resetPanelGroping()
    })
  },
  methods: {
    ...mapActions({
      setCurrentQuestionFilterGroups:
        'analyzeSurvey/setCurrentQuestionFilterGroups',
      setSegmentQuestionFilterUngrouped:
        'analyzeSurvey/setSegmentQuestionFilterUngrouped',
      setSegGroups: 'analyzeSurvey/setSegGroups',
      setView: 'analyzeSurvey/setCurrentView'
    }),

    // returns running total as you add or remove items
    setPanelGrouping(obj) {
      this.grouped = obj.groups
      this.ungrouped = obj.ungrouped
    },

    resetPanelGroping() {
      this.$set(this, 'grouped', [])
      this.$set(this, 'ungrouped', [])
    },

    backgroundColor(ind) {
      return colorGenerator(ind)
    },
    // set view in state
    showMyAudience: function($event) {
      this.setView('AUDIENCE')
    },
    // set view in state
    showMySegments: function() {
      // this.setPanelGrouping({
      //   groups: [],
      //   ungrouped: []
      // })
      this.setView('SEGMENT')
    },
    onAnswerOver(index, answer) {
      this.$bus.$emit('onSegmentOver', index)
      this.$refs.rolloverbtn.map(ref => {
        if (ref.id === answer.id.toString()) {
          return ref.classList.remove('inactive')
        } else {
          return ref.classList.add('inactive')
        }
      })
    },
    onAnswerOut() {
      this.$bus.$emit('onSegmentOut')
    },
    onPanelOut() {
      document.querySelectorAll('.rolloverbtn').forEach(ref => {
        ref.classList.remove('inactive')
      })
      // this.$refs.rolloverbtn.forEach(ref => {
      //   ref.classList.remove('inactive')
      // })
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/css/_filterPanel.scss';
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_mixins.scss';
.filterpanel {
  .theme--dark.v-expansion-panel .v-expansion-panel__container {
    color: red;
  }

  .v-alert.v-alert--outline {
    border: 1px solid yellow !important;
  }
}
.custom-info {
  width: 24px;
  height: 24px;
  background: url('../../../../assets/images/info_24px.svg') no-repeat;
}

.segmentname {
  text-align: right;
  margin-right: $spacing-base !important;
}
.filterpanel {
  overflow-y: auto;
}

.filter-footer-label {
  font-size: $font-size-base * 0.85;
  padding: 0rem;
}
</style>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';

.response-container {
  padding-right: $spacing-base;
}

.segment-detail-expansion-panel {
  box-shadow: none;
}
</style>

<style lang="scss" scoped>
.audience-name {
  display: inline-block;
  padding: 0 10px;
}

.audience-name-container {
  display: flex;
  align-items: baseline;
}

.scroll-to {
  cursor: pointer;
}

.v-tooltip__content {
  p {
    margin-bottom: 0;
  }
}
</style>
