import colors from '@/assets/css/_vars.scss'
const colorScheme = colors['data-viz'].split(',').map(item => item.trim())

// ind (int) - value will adjust to accomodate length of colorScheme
export default ind => {
  if (ind === undefined) {
    return colorScheme
  } else {
    const totalColors = colorScheme.length
    const adjIndex = ind - Math.floor(ind / totalColors) * totalColors
    return colorScheme[adjIndex]
  }
}
