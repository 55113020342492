import Api from '@/services/Api'
export default class UtilService extends Api {
  // Utils - Contact admin/support
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Utils-SendContactUsEmail
  sendContactUsEmail(payload) {
    return this.$axios
      .post('utils/contactus', payload)
      .then(response => {
        // console.log('deleteUserFromWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Utils - Convert HTML String to PDF file
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Utils-ConvertHtmlToPdf
  htmlToPdf(payload) {
    return (
      this.$axios
        .post('utils/htmltopdf', payload)
        // .then(response => {
        //   return response.data
        // })
        .catch(err => {
          return err.response
        })
    )
  }

  // Utils - Get Watson Token
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Utils-GetWatsonToken
  getWatsonToken(payload) {
    return this.$axios
      .get('utils/getwatsontoken')
      .then(function(response) {
        return response.data
      })
      .catch(function(error) {
        console.log(error, 'error')
      })
  }
}
