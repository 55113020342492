var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { class: { transparentBg: _vm.transparentBg }, attrs: { flat: "" } },
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }