<template>
  <svg
    xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    viewBox="0 0 30 30" 
    :aria-labelledby="iconName" 
    role="presentation"
  >
    <title lang="en">{{ iconName }} icon</title>
    <g fill="'#FF0000'" :stroke="color">
      <ArrowRight v-if="iconName === &quot;ArrowRight&quot;" />
      <CircleGraph v-if="iconName === &quot;CircleGraph&quot;" />
      <Expand v-if="iconName === &quot;Expand&quot;" />
      <Graph v-if="iconName === &quot;Graph&quot;" />
      <Grid v-if="iconName === &quot;Grid&quot;" />
      <List v-if="iconName === &quot;List&quot;" />
      <Page v-if="iconName === &quot;Page&quot;" />
      <Person v-if="iconName === &quot;Person&quot;" :color="color" />
      <Search v-if="iconName === &quot;Search&quot;" />
      <SquareGraph v-if="iconName === &quot;SquareGraph&quot;" />
      <Star v-if="iconName === &quot;Star&quot;" />
    </g>
  </svg>
</template>

<script>
import ArrowRight from '@/components/icons/ArrowRight'
import CircleGraph from '@/components/icons/CircleGraph'
import Expand from '@/components/icons/Expand'
import Graph from '@/components/icons/Graph'
import Grid from '@/components/icons/Grid'
import List from '@/components/icons/List'
import Page from '@/components/icons/Page'
import Person from '@/components/icons/Person'
import Search from '@/components/icons/Search'
import SquareGraph from '@/components/icons/SquareGraph'
import Star from '@/components/icons/Star'
export default {
  components: {
    ArrowRight,
    CircleGraph,
    Expand,
    Graph,
    Grid,
    List,
    Page,
    Person,
    Search,
    SquareGraph,
    Star
  },
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 30
    },
    height: {
      type: [Number, String],
      default: 30
    },
    color: {
      type: String,
      default: '#00FF00'
    }
  }
}
</script>

<style lang="scss" scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px;
}
</style>
