<template>
  <div v-if="enableScroll">
    <span
      :id="id"
      ref="rolloverbtn"
      v-scroll-to="`#anchor-${index}`"
      class="rolloverbtn scroll-to"
      href="#"
      @mouseover="onAnswerOver(index + groupedLength, id)"
      @mouseleave="onAnswerOut"
    >
      <div class="response-container">
        <span
          class="circle small-circle"
          :style="{ backgroundColor: backgroundColor }"
          style="
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          "
        />
        <span :id="text" class="target">{{ text }}</span>
      </div>
      <div class="target font-weight-bold">{{ answer.respondent_count }}</div>
    </span>
  </div>
  <div v-else>
    <span
      :id="id"
      class="rolloverbtn"
      @mouseover="onAnswerOver(index + groupedLength, id)"
      @mouseleave="onAnswerOut"
    >
      <div class="response-container">
        <span
          class="circle small-circle"
          :style="{ backgroundColor: backgroundColor }"
          style="
            width: 15px;
            height: 15px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
          "
        />
        <span :id="text" class="target">{{ text }}</span>
      </div>
      <div class="target font-weight-bold">{{ answer.respondent_count }}</div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    enableScroll: {
      default: false,
      type: Boolean
    },
    index: {
      default: -1,
      type: Number
    },
    backgroundColor: {
      default: '',
      type: String
    },
    answer: {
      default: () => {},
      type: Object
    },
    groupedLength: {
      default: 0,
      type: Number
    }
  },
  computed: {
    id() {
      return this.answer.id || this.answer.group_id
    },
    text() {
      return this.answer.text || this.answer.group_name
    }
  },
  methods: {
    onAnswerOver(i, id) {
      this.$bus.$emit('onSegmentOver', i)

      document.querySelectorAll('.rolloverbtn').forEach(ref => {
        if (ref.id === id.toString()) {
          return ref.classList.remove('inactive')
        } else {
          return ref.classList.add('inactive')
        }
      })
    },
    onAnswerOut() {
      this.$bus.$emit('onSegmentOut')
    }
  }
}
</script>

<style lang="scss" scoped>
.scroll-to {
  cursor: pointer;
}

.rolloverbtn {
  .target {
    opacity: 1;
    transition: opacity 0.5 linear;
  }

  &.inactive {
    .target {
      opacity: 0.5;
    }
  }
}

.rolloverbtn:focus {
  outline: none !important;
}
</style>
