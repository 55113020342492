import { render, staticRenderFns } from "./SurveyTitleForm.vue?vue&type=template&id=45a64b93&scoped=true&"
import script from "./SurveyTitleForm.vue?vue&type=script&lang=js&"
export * from "./SurveyTitleForm.vue?vue&type=script&lang=js&"
import style0 from "./SurveyTitleForm.vue?vue&type=style&index=0&id=45a64b93&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a64b93",
  null
  
)

/* vuetify-loader */
import installComponents from "!/var/www/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VForm } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VDialog,VForm,VIcon})


/* hot reload */
if (module.hot) {
  var api = require("/var/www/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('45a64b93', component.options)
    } else {
      api.reload('45a64b93', component.options)
    }
    module.hot.accept("./SurveyTitleForm.vue?vue&type=template&id=45a64b93&scoped=true&", function () {
      api.rerender('45a64b93', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/pages/survey/SurveyTitleForm.vue"
export default component.exports