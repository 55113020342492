export const state = () => ({
  workspaces: []
})

export const getters = {}

export const mutations = {
  setWorkspaces(state, workspaces) {
    state.workspaces = workspaces
  }
}

export const actions = {
  setWorkspaces({ commit }, val) {
    commit('setWorkspaces', val)
  }
}
