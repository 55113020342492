import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/es5/util/colors'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import arcadiacolors from '@/assets/css/_vars.scss'

Vue.use(Vuetify, {
  iconfont: ['md', 'mdi'],
  options: {
    customProperties: true
  },
  theme: {
    primary: arcadiacolors.primary,
    secondary: arcadiacolors.secondary,
    accent: colors.grey.darken3,
    info: arcadiacolors.info,
    positive: arcadiacolors.positive,
    negative: arcadiacolors.negative,
    'negative-light': arcadiacolors['negative-light'],
    warning: colors.amber.base,
    error: colors.deepOrange.accent4,
    success: colors.green.accent3,
    'bg-light': arcadiacolors['bg-light'],
    'bg-medium': arcadiacolors['bg-medium'],
    'info-light': arcadiacolors['info-light']
  }
})
