<template>
  <v-app light>
    <v-content>
      <nuxt />
    </v-content>
  </v-app>
</template>

<script>
export default {
  head: {
    script: [{ src: '/watson-speech.js' }]
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
