import Vue from 'vue'

import moment from 'moment'
// Capitlize first letter of string
const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
Vue.filter('capitalize', capitalize)

const titleCase = str => {
  const splitStr = str.toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}
Vue.filter('titleCase', titleCase)

// Format date
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY h:mm a')
  }
})
Vue.filter('formatDateNoTime', function(value) {
  if (value) {
    return moment(String(value)).format('MMM DD, YYYY')
  }
})
