import Api from '@/services/Api'

export default class WorkspaceService extends Api {
  // Get list of brands for user
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Brand-GetBrandListbyUser

  // *** DONT USE THIS SERVICE (getWorkSpaces) ***
  // *** USE getWorkspacesByCurrentUser ***

  getWorkspaces() {
    return this.$axios.get('workspaces').then(response => response.data)
  }

  // Workspace - Create new workspace by Admin
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-CreateWorkspace
  createWorkspace(payload) {
    return this.$axios
      .post('workspaces', payload)
      .then(response => {
        // console.log('deleteUserFromWorkspace(payload)', response)
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Workspace - Update workspace by Admin
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-UpdateWorkspace
  updateWorkspace(payload) {
    return this.$axios
      .put('workspaces', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Workspace - Get workspaces by current user's favor
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-GetWorkspaceMyList
  getWorkspacesByUsersFavor() {
    return this.$axios.get('workspaces/my/list').then(response => response.data)
  }

  // Workspace - Get workspaces by current user
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-GetWorkspaceList
  getWorkspacesByCurrentUser() {
    return this.$axios.get('workspaces/list').then(response => response.data)
  }

  // Workspace - Get workspace by workspace ID
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-GetWorkspaceById
  getWorkspaceById(payload) {
    return this.$axios
      .get(`workspaces/id/${payload}`)
      .then(response => response.data)
  }

  // Workspace - Get users from current workspace
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-GetUsersFromCurrentWorkspace
  getUsersFromCurrentWorkspace() {
    return this.$axios.get('workspaces/users').then(response => response.data)
  }

  // Workspace - Add public workspace to my list
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-AddPublicWorkspaceToMyList
  addPublicWorkspaceToMyList(payload) {
    return this.$axios
      .post('workspaces/my', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Workspace - Request workspace access
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-RequestWorkspaceAccessEmail
  requestWorkspaceAccess(payload) {
    return this.$axios
      .post('workspaces/requestAccess', payload)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Workspace - Delete workspace from my list
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Workspace-DeleteWorkspaceFromMyList
  deletePublicWorkspaceToMyList(payload) {
    return this.$axios
      .delete('workspaces/my', { data: payload })
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err.response
      })
  }

  // Get list of brands for user
  // https://havas-arcadia-api-documentation.tech4.ny.havasww.com/#api-Brand-GetBrandListbyUser
  // getSurveyList(brandInfo) {
  //   const id = brandInfo.id
  //   return this.$axios
  //     .get(`workspaces/${id}/surveys`)
  //     .then(response => response.data)
  // }
}
