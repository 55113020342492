/* eslint-disable prettier/prettier */
export const state = () => ({
  questionId: null,
  questionIdArr: [7417,7418]
})
export const mutations = {
  setGroupedQuestionId(state, val) {
    state.questionId = val
  },
}

export const actions = {
  setGroupedQuestionId({ commit }, val) {
    commit('setGroupedQuestionId', val)
  }
}

export const getters = {
  getGroupedQuestionId: state => state.questionId,
  getGroupedQuestionIdArray: state => state.questionIdArr,
}
