export default function({ store, redirect }) {
  const { user } = store.state.auth
  const roleId = user.role_id
  if (roleId === 1) {
    return redirect(`/admin/profile/${user.id}`)
  } else if (roleId === 2) {
    return redirect(`/admin/profile/${user.id}`)
  } else if (roleId === 3) {
    return redirect(`/admin/profile/${user.id}`)
  } else {
    return redirect(`/admin/profile/${user.id}`)
  }
}
