export function isEmail(input) {
  return (
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
      input
    ) || 'E-mail must be valid'
  )
}

export function notEmpty(input) {
  return (!!input && input.trim().length > 0) || 'Field is required'
}

export function min8(input) {
  return input.length >= 8 || 'Must be at least 8 characters'
}

export function max32(input) {
  return input.length <= 32 || 'Must be less than 32 characters'
}

export function max255(input) {
  return input.length <= 255 || 'Must be less than 255 characters'
}

export function max6000(input) {
  return input.length <= 6000 || 'Maximum character count reached'
}

export function alphanumeric(input) {
  return (
    /^[a-zA-Z0-9]*$/.test(input) ||
    'Password can only contain lowercase letters, uppercase letters, and numbers.'
  )
}

export function containsNumber(input) {
  return /\d/.test(input) || 'Contain at least one number'
}

export function containsUppercase(input) {
  return /[A-Z]/.test(input) || 'Contain at least one uppercase letter'
}

export function containsLowercase(input) {
  return /[a-z]/.test(input) || 'Contain at least one lowercase letter'
}

export function containsSymbol(input) {
  return /\W|_/g.test(input) || 'Contain at least one symbol'
}

export function enoughAnswers(input) {
  return this.SurveyAnswers.length >= 2
    ? true
    : 'Please add at least one more answer option'
}

export function uniqueAnswer(input) {
  const count = this.SurveyAnswers.reduce((count, answer) => {
    return input.toString() === answer.text ? count + 1 : count
  }, 0)
  return count <= 1 ? true : 'This answer already exists'
}

export function uniqueQuestion(input) {
  if (this.survey && this.survey.SurveyQuestions) {
    // currently looking through survey questions passed as a async prop, can replace with survey in store if better
    const { SurveyQuestions } = this.survey
    const questionNames = SurveyQuestions.map(question => question.text)
    return questionNames.indexOf(input) === -1
      ? true
      : 'This question already exists - please enter a new question'
  } else {
    return true
  }
}

export function inputsAreEqual(input1, input2) {
  return input1 === input2 || 'Passwords must match'
}
