var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "align-center": "" } },
    [
      _c(
        "v-flex",
        { attrs: { id: "breadcrumbs" } },
        [
          _c(
            "v-breadcrumbs",
            {
              attrs: { items: _vm.breadcrumbs, "pl-0": "" },
              scopedSlots: _vm._u([
                {
                  key: "item",
                  fn: function(props) {
                    return [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "v-breadcrumbs__item",
                            on: {
                              click: function($event) {
                                return _vm.$router.push(props.item.href)
                              }
                            }
                          },
                          [_vm._v(_vm._s(props.item.text))]
                        )
                      ])
                    ]
                  }
                }
              ])
            },
            [
              _vm._v(" "),
              _c("v-icon", { attrs: { slot: "divider" }, slot: "divider" }, [
                _vm._v("\n        chevron_right\n      ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        [
          _vm.onSurveyDesignSection ? _c("SurveyTitleForm") : _vm._e(),
          _vm._v(" "),
          _vm.onSyndicatedSegment ? _c("SyndicatedTitleForm") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }