/* eslint-disable prettier/prettier */
// import survey from '@/data/fake-survey.js'

export const state = () => ({
  surveyQuestions: [],
  currentQuestionId: 0,
  currentQuestion: '',
})
export const mutations = {
  setFilters(state, filters) {
    state.segmentationFilters = filters
  },
}

export const actions = {
  setAudienceFilters({ commit }, val) {
    commit('setAudience', val)
  },
}

export const getters = {
  getSurvey: state => state.survey,
  getAudienceFilters: state => state.audienceFilters,
}
