var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "stroke-width": "1.25",
      d:
        "M11 17l-5.878 3.09 1.123-6.545L1.489 8.91l6.572-.955L11 2l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z",
      "fill-rule": "evenodd",
      "stroke-linecap": "round"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }