var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-btn",
        { attrs: { slot: "activator", icon: "" }, slot: "activator" },
        [
          _c("v-icon", { attrs: { id: "edit-survey" } }, [
            _vm._v("\n      edit\n    ")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c(
            "v-form",
            { ref: "form" },
            [
              _c("h2", [_vm._v("Edit Survey")]),
              _vm._v(" "),
              _c("p", [_vm._v("Enter a new title and description below.")]),
              _vm._v(" "),
              _c("TextField", {
                attrs: {
                  label: "Enter survey name",
                  rules: _vm.nameRules,
                  "error-messages": _vm.nameErrors,
                  autofocus: "",
                  placeholder: "Enter survey name",
                  "validate-on-blur": "",
                  required: ""
                },
                model: {
                  value: _vm.name,
                  callback: function($$v) {
                    _vm.name = $$v
                  },
                  expression: "name"
                }
              }),
              _vm._v(" "),
              _c("Textarea", {
                attrs: {
                  label: "Survey description",
                  solo: "",
                  placeholder: "Add a description for your survey here.",
                  required: "",
                  "validate-on-blur": "",
                  rules: _vm.descriptionRules,
                  "error-messages": _vm.descriptionErrors
                },
                model: {
                  value: _vm.description,
                  callback: function($$v) {
                    _vm.description = $$v
                  },
                  expression: "description"
                }
              }),
              _vm._v(" "),
              _c(
                "Button",
                { attrs: { variant: "secondary" }, on: { click: _vm.submit } },
                [_vm._v("\n        submit\n      ")]
              ),
              _vm._v(" "),
              _c(
                "Button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("\n        cancel\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }