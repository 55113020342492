const cookieparser = process.server ? require('cookieparser') : undefined

export const state = () => ({
  isAuth: false,
  brandInfo: null,
  route: [],
  section: ''
})

export const actions = {
  async nuxtServerInit({ dispatch }, { req, params, store }) {
    let auth = null
    let brand = null
    if (req.headers.cookie) {
      const parsed = cookieparser.parse(req.headers.cookie)
      try {
        auth = JSON.parse(parsed.auth)
        brand = JSON.parse(parsed.brand)
        this.$axios.setToken(auth.token)
      } catch (err) {
        // No valid cookie found
      }
    }
    await dispatch('setAuth', auth)
    await dispatch('setBrand', brand)
  },
  setBrand({ commit }, brand) {
    commit('setBrand', brand)
  },
  setAuth({ commit }, auth) {
    commit('isAuth', auth)
    commit('auth/setAuth', auth)
  },
  setSection({ commit }, section = '') {
    commit('setSection', section)
  }
}

export const mutations = {
  isAuth(state, isAuth) {
    state.isAuth = Boolean(isAuth)
  },
  setBrand(state, brandInfo) {
    state.brandInfo = brandInfo
  },
  setSection(state, section = '') {
    state.section = section
  }
}

export const getters = {
  accessToken: state => {
    return state.auth.token
  },
  brandInfo: state => {
    return state.brandInfo
  },
  section: state => {
    return state.section
  }
}
