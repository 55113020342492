import Api from '@/services/Api'
export default class AuthService extends Api {
  login(data) {
    const formData = {
      email: data.email,
      password: data.password
    }
    return this.$axios.post('login', formData)
  }

  register(data) {
    const formData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password
    }
    return this.$axios
      .post('register', formData)
      .then(response => response.data)
  }
}
