var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-textarea", {
        staticClass: "arcadia-textarea",
        class: {
          outline: _vm.outline,
          dark: _vm.dark
        },
        attrs: {
          autofocus: _vm.autofocus,
          outline: _vm.outline,
          label: _vm.label,
          rules: _vm.rules,
          placeholder: _vm.placeholder
        },
        on: {
          click: function($event) {
            return _vm.$emit("click")
          },
          change: _vm.handleChange
        },
        model: {
          value: _vm.text,
          callback: function($$v) {
            _vm.text = $$v
          },
          expression: "text"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }