<template>
  <v-app light>
    <LeftNav />
    <div id="nav-offset">
      <TopNav />
      <v-content class="content__wrap" :class="{hasSubnav: hasSurveyNav}">
        <nuxt />
      </v-content>
      <div v-if="showFilters">
        <v-flex d-flex xs3 class="fixed-container">
          <v-layout>
            <FilterPanel />
          </v-layout>
        </v-flex>
        <v-flex class="holder">
          <transition name="slide-fade">
            <v-flex v-show="currentView == 'SEGMENT'" xs12 class="panel segments">
              <SegmentFilter />
            </v-flex>
          </transition>
          <transition name="slide-fade">
            <v-flex v-show="currentView == 'AUDIENCE'" xs12 class="panel audience">
              <AudienceFilter />
            </v-flex>
          </transition>
        </v-flex>
      </div>
    </div>
  </v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import LeftNav from '@/components/layouts/LeftNav'
import TopNav from '@/components/layouts/TopNav'
import FilterPanel from '@/components/pages/survey/filters/FilterPanel'
import SegmentFilter from '@/components/pages/survey/filters/SegmentFilter'
import AudienceFilter from '@/components/pages/survey/filters/AudienceFilter'
import 'simplebar/dist/simplebar.min.css'

export default {
  middleware: ['authenticated', 'checkWorkspaceIdMiddleware'],
  components: {
    TopNav,
    LeftNav,
    FilterPanel,
    AudienceFilter,
    SegmentFilter
  },
  computed: {
    ...mapGetters({
      currentView: 'analyzeSurvey/getCurrentView',
      getLayout: 'analyzeSurvey/getLayout'
    }),
    renderNav: function() {
      const renderNav =
        this.$route.name !== 'dashboard' && this.$store.state.auth
      return renderNav
    },
    showFilters: function() {
      if (
        this.$route.name !== 'dashboard-brand-surveys-survey-analyze_journey' &&
        this.$route.name !== 'dashboard-brand-surveys-survey-analyze_audience'
      ) {
        return false
      } else {
        return true
      }
    },
    hasSurveyNav() {
      const subnavRouteNames = [
        'dashboard-brand-surveys-survey-preview',
        'dashboard-brand-surveys-survey-design',
        'dashboard-brand-surveys-survey-gather_responses'
      ]
      return subnavRouteNames.includes(this.$route.name)
    }
  },
  updated() {},
  mounted() {
    this.$setWorkspaceId()
    this.setAudienceFilters()
  },
  beforeMount: function() {
    this.$checkWorkspaceId()
  },
  head() {
    return {
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content: 'My custom description'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      // toggleMiniNav: 'nav/toggleMiniNav'
      removeSegmentation: 'analyzeSurvey/removeSegmentation',
      resetView: 'analyzeSurvey/setCurrentView',
      setAudienceFilters: 'analyzeSurvey/setAudienceFilters'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';
.content__wrap {
  &.hasSubnav {
    margin-top: $horizontal-nav-height;
  }
}
</style>


<style lang="scss">
@import '@/assets/css/_vars.scss';
@import '@/assets/css/_journeylayout.scss';
#nav-offset {
  margin-left: $leftnav-width;
}
// .simplebar-scrollbar:before {
//   position: absolute;
//   content: '';
//   background: #fff;
//   border-radius: 7px;
//   left: 0;
//   right: 0;
//   opacity: 0;
//   transition: opacity 0.2s linear;
// }
</style>
