var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    { staticClass: "pt-0 audience-container" },
    [
      _c(
        "div",
        { staticClass: "wrapper" },
        [
          _vm.dialog
            ? _c(
                "v-content",
                { staticClass: "dialog" },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        "align-center": "",
                        "justify-center": "",
                        "align-items-center": "",
                        row: "",
                        "fill-height": ""
                      }
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { color: "darken-2" } },
                        [
                          _c(
                            "v-card-title",
                            { attrs: { "primary-title": "" } },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "font-weight-bold",
                                    staticStyle: { "font-size": "18px" }
                                  },
                                  [
                                    _c("span", { staticClass: "info-icon" }, [
                                      _vm._v("i")
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Unsaved changes")])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                You have unsaved changes. Are you sure you want to continue?\n              "
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            { attrs: { "justify-end": "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "btn-black font-weight-bold",
                                  attrs: { flat: "", light: "" },
                                  on: { click: _vm.unsavedOk }
                                },
                                [
                                  _vm._v(
                                    "\n              continue\n            "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { flat: "", outline: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.dialog = false
                                    }
                                  }
                                },
                                [_vm._v("\n              cancel\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "header",
              class: {
                open: (_vm.disabled && _vm.audienceId == 0) || _vm.createmode
              }
            },
            [
              _c(
                "v-card",
                { staticClass: "font-weight-medium filters-card bg-black" },
                [
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "close-btn",
                        staticStyle: {
                          cursor: "pointer",
                          position: "absolute",
                          top: "15px",
                          right: "10px",
                          color: "white",
                          "align-items": "flex-end",
                          "justify-content": "flex-end"
                        },
                        on: { click: _vm.closeFilters }
                      },
                      [
                        _c("v-icon", { staticStyle: { color: "white" } }, [
                          _vm._v("\n              close\n            ")
                        ])
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-title",
                    {
                      staticClass: "filterstitle font-weight-bold pl-3",
                      attrs: { primary: "" }
                    },
                    [_vm._v("\n          Edit segments\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "bg-black pl-2" },
                [
                  _vm.audienceId == 0 &&
                  _vm.isloading == false &&
                  _vm.createmode === false
                    ? _c(
                        "v-alert",
                        {
                          staticClass: "my-alert font-weight-bold",
                          staticStyle: { "font-size": "13px" },
                          attrs: {
                            id: "my-alert",
                            value: true,
                            icon: "info",
                            outline: ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          You will not be able to adjust the ‘all respondents’ segment. To\n          create a new segment, open the dropdown and click create new\n          segment.\n        "
                          )
                        ]
                      )
                    : _c(
                        "v-alert",
                        {
                          staticClass:
                            "my-alert font-weight-bold ma-0 alert-no-icon",
                          staticStyle: {
                            "{\n              padding-left": "8px"
                          },
                          attrs: { value: true, outline: "" },
                          model: {
                            value: _vm.isValid,
                            callback: function($$v) {
                              _vm.isValid = $$v
                            },
                            expression: "isValid"
                          }
                        },
                        [
                          _vm.emptyanswers
                            ? _c("span", [
                                _vm._v(
                                  "You must select at least one option from every question."
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "WARNING: At least one answer must be hidden before saving this\n            segment"
                                )
                              ])
                        ]
                      ),
                  _vm._v(" "),
                  _c(
                    "v-alert",
                    {
                      staticClass: "my-alert font-weight-bold ma-0",
                      attrs: { id: "my-alert-2", value: true, outline: "" },
                      model: {
                        value: _vm.alert,
                        callback: function($$v) {
                          _vm.alert = $$v
                        },
                        expression: "alert"
                      }
                    },
                    [
                      _vm._v(
                        "\n          Please name your segment before saving.\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "simplebar",
            {
              staticClass: "scroller",
              class: { open: _vm.disabled && _vm.audienceId == 0 }
            },
            [
              _c(
                "v-content",
                { staticClass: "pa-0 scroll-container" },
                [
                  _c(
                    "v-content",
                    { staticClass: "pt-0 scroll-content" },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "mb-0 ml-2 pt-3",
                          attrs: { column: "", wrap: "", "align-left": "" }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "font-weight-medium pl-2",
                              staticStyle: { color: "white" }
                            },
                            [
                              _vm._v(
                                "\n              Your segments\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.showinput
                            ? _c(
                                "v-content",
                                {
                                  staticClass: "pl-2 pt-0 pb-0",
                                  attrs: { xs12: "", "d-flex": "" }
                                },
                                [_c("FilterSelect")],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.createmode
                        ? _c(
                            "v-alert",
                            {
                              staticClass: "my-icon font-weight-bold",
                              staticStyle: { "font-size": "12px" },
                              attrs: { id: "my-info", value: true, outline: "" }
                            },
                            [
                              _vm._v(
                                "\n            Define your segment by deselecting answers to questions. For\n            example, if you want to create a segment for females only, just\n            deselect all other genders for that question.\n          "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        {
                          staticClass: "ml-0 mr-2 mt-0 mb-0",
                          staticStyle: { width: "300px" },
                          attrs: { column: "" }
                        },
                        [
                          _vm.showinput
                            ? _c(
                                "v-flex",
                                {
                                  staticStyle: {
                                    background: "white:color:black"
                                  },
                                  attrs: { xs12: "", "d-flex": "" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.message,
                                        expression: "message"
                                      }
                                    ],
                                    staticClass:
                                      "text-14 custom font-weight-bold ml-3 mb-2",
                                    staticStyle: {
                                      background: "white:color:black"
                                    },
                                    attrs: {
                                      type: "text",
                                      label: "Segment name",
                                      placeholder: "Name your segment"
                                    },
                                    domProps: { value: _vm.message },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.message = $event.target.value
                                        },
                                        _vm.nameChange
                                      ]
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        {
                          staticClass:
                            "w-100 pl-3 pr-4 pt-0 pb-2 font-weight-bold",
                          staticStyle: {
                            color: "#999999",
                            "font-size": "12px",
                            "border-bottom": "1px solid #444"
                          },
                          attrs: { "justify-space-between": "", row: "" }
                        },
                        [
                          _c("div", [_vm._v("SHOW")]),
                          _vm._v(" "),
                          _c("div", [_vm._v("RESPONDENTS")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card",
                        { staticClass: "bg-black" },
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "pa-0 bg-black",
                              attrs: { fluid: "" }
                            },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "bg-black",
                                  staticStyle: { "padding-bottom": "100px" },
                                  model: {
                                    value: _vm.radios,
                                    callback: function($$v) {
                                      _vm.radios = $$v
                                    },
                                    expression: "radios"
                                  }
                                },
                                _vm._l(_vm.surveyQuestions, function(
                                  question,
                                  i
                                ) {
                                  return _c(
                                    "v-list",
                                    {
                                      key: question.id,
                                      staticClass: "pt-0 pl-0 bg-black pb-2"
                                    },
                                    [
                                      (question.SurveyAnswers &&
                                        question.SurveyAnswers.length > 0) ||
                                      (question.Answers &&
                                        question.Answers.length > 0)
                                        ? _c(
                                            "v-container",
                                            {
                                              staticClass:
                                                "pt-2 pb-2 pl-3 panel bg-black",
                                              class: "question-container",
                                              staticStyle: {
                                                "border-bottom":
                                                  "1px solid #333"
                                              },
                                              attrs: {
                                                id:
                                                  "container-" +
                                                  question.id.toString(),
                                                index: i,
                                                fluid: "",
                                                "data-id": question.id
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  staticClass:
                                                    "option-holder ml-0 mr-2"
                                                },
                                                [
                                                  _c(
                                                    "v-content",
                                                    {
                                                      staticClass:
                                                        "pt-0 pb-2 pl-0 font-weight-bold"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            question.text
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("FilterAnswers", {
                                                    attrs: {
                                                      id: question.id,
                                                      answers:
                                                        question.SurveyAnswers ||
                                                        question.Answers,
                                                      disabled: _vm.disabled
                                                    },
                                                    on: {
                                                      "update-question":
                                                        _vm.updateAudienceQuestion
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "fixed-bottom font-weight-medium footer bg-black ml-2" },
        [
          _c("v-layout", { attrs: { "align-center": "", "fill-height": "" } }, [
            _c(
              "div",
              { staticClass: "text-xs-left pr-3" },
              [
                _vm.audienceId != 0 &&
                !_vm.hasupdated &&
                !_vm.createmode &&
                _vm.disabled &&
                !_vm.isloading
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "text-black ml-3",
                        attrs: { color: "" },
                        on: { click: _vm.editAudience }
                      },
                      [_vm._v("\n          EDIT\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.disabled
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "text-black ml-3",
                        class: { disabled: _vm.disabled || !_vm.hasupdated },
                        attrs: { color: "secondary", disabled: _vm.dialog },
                        on: { click: _vm.saveUpdateAudience }
                      },
                      [_vm._v("\n          SAVE\n        ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.disabled || (!_vm.createmode && _vm.showinput)
                  ? _c(
                      "v-btn",
                      {
                        staticStyle: { color: "white" },
                        attrs: {
                          flat: "",
                          light: "",
                          color: "",
                          disabled: _vm.dialog
                        },
                        on: { click: _vm.reset }
                      },
                      [_vm._v("\n          CANCEL\n        ")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.isloading
              ? _c(
                  "div",
                  { staticClass: "progress-bar-container" },
                  [
                    _c("v-progress-linear", {
                      staticClass: "progress",
                      attrs: { indeterminate: true }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.disabled
              ? _c("div", { staticClass: "progress-bar-container" }, [
                  _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          (
                            (_vm.getFilteredResponsesCount /
                              _vm.getActiveResponseCount) *
                            100
                          ).toFixed(0)
                        ) +
                        "% of respondents\n          "
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-layout",
                          {
                            attrs: {
                              "justify-space-between": "",
                              "justify-content-center": "",
                              row: ""
                            }
                          },
                          [
                            _c("div", { staticClass: "bar" }, [
                              _c("div", {
                                ref: "inner",
                                staticClass: "inner",
                                style: {
                                  width:
                                    (
                                      (_vm.getFilteredResponsesCount /
                                        _vm.getActiveResponseCount) *
                                      100
                                    ).toFixed(0) + "%"
                                },
                                attrs: { id: "inner" }
                              })
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.disabled
              ? _c("div", { staticClass: "copy ml-2 mr-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.getFilteredResponsesCount) +
                      " of " +
                      _vm._s(_vm.getActiveResponseCount) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }