export default function({ store, redirect, params, app }) {
  // coerce route param string into number
  const id = params.brand * 1
  const existingWorkspace = store.state.brandInfo

  if (!id) {
    return
  }

  if (!existingWorkspace || id !== existingWorkspace.id) {
    return app.$api.workspace
      .getWorkspaceById(id)
      .then(data => {
        const { Workspace } = data
        store.dispatch('setBrand', Workspace)
      })
      .catch(err => console.log('err:', err))
  }
}
