var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "title pa-0 ma-0 segment-answers bg-black" },
    [
      _c(
        "v-content",
        { staticClass: "pt-0 bg-black", attrs: { id: "ungrouped" } },
        [
          _c(
            "v-list",
            [
              _c(
                "no-ssr",
                _vm._l(_vm.getAudeince, function(answer, index) {
                  return _c(
                    "v-layout",
                    {
                      key: index,
                      staticClass: "mb-2",
                      staticStyle: { "font-size": "12px" },
                      attrs: { "justify-space-between": "" }
                    },
                    [
                      _c("div", { attrs: { for: answer.id.toString() } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(answer.text) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(answer.respondent_count))])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }