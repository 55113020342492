var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("path", {
    attrs: {
      "stroke-width": "2",
      d: "M1.071.964l7.071 7.072-7.07 7.07",
      "fill-rule": "evenodd"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }