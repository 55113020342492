<template>
  <v-app light>
    <v-content id="homepage">
      <nuxt />
    </v-content>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
const Cookie = process.client ? require('js-cookie') : undefined

export default {
  computed: {
    renderNav: function() {
      return this.$route.name !== 'dashboard' && this.$store.state.auth
    }
  },
  mounted() {
    Cookie.set('brand', null)
    // this.updateBreadcrubs('')
  },
  methods: {
    ...mapActions({
      // updateBreadcrubs: 'nav/updateBreadcrubs'
    })
  }
}
</script>

<style lang="scss" scoped>
#homepage {
  padding: 0 !important;
}
</style>
