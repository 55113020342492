var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-select",
        _vm._b(
          {
            attrs: {
              items: _vm.items,
              outline: "",
              flat: "",
              "menu-props": { contentClass: "my-special-class" },
              dense: "",
              solo: "",
              "hide-details": ""
            },
            on: { change: _vm.onChange },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(data) {
                    return [
                      data.item.icon
                        ? _c("div", {
                            staticClass: "dropdown-menu-item",
                            class: {
                              "icon-item": data.item.icon,
                              "border-bottom": data.item.id === -1
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      data.item.icon
                        ? _c(
                            "v-icon",
                            { staticClass: "dropdown-round-item-icon" },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(data.item.icon) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        { staticClass: "my-content-class" },
                        [
                          _c(
                            "v-list-tile-title",
                            { staticClass: "my-title-class" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(data.item.name || data.item.text) +
                                  "\n        "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._t("prepend-item")
                    ]
                  }
                }
              ],
              null,
              true
            )
          },
          "v-select",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }