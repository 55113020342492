import Vue from 'vue'

const Cookie = process.client ? require('js-cookie') : undefined

// grabs workspace id from route params and updates cookie - in case page is refreshed or url is accessed directly
Vue.prototype.$setWorkspaceId = function() {
  const { brand: workspaceid } = this.$route.params
  if (workspaceid) {
    Cookie.set('workspaceid', workspaceid)
  }
}
