var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "title pa-0 ma-0 segment-answers" },
    [
      _c(
        "v-layout",
        {
          staticClass: "w-100 pb-2 pt-2 font-weight-bold",
          staticStyle: { color: "#fff", "font-size": "12px" },
          attrs: { "justify-space-between": "", row: "" }
        },
        [
          _c("div", [_vm._v("SHOWING")]),
          _vm._v(" "),
          _c("div", [_vm._v("RESPONDENTS")])
        ]
      ),
      _vm._v(" "),
      _c(
        "no-ssr",
        _vm._l(_vm.groups, function(group, index) {
          return _c(
            "v-content",
            {
              key: index,
              staticClass: "pt-0 pl-1 ml-1",
              class: "group" + parseInt(index + 1),
              staticStyle: { cursor: "pointer" },
              attrs: { id: "group" + parseInt(index + 1) }
            },
            [
              _c(
                "div",
                {
                  staticClass: "group p1-2 pr-2 pt-1 pb-0",
                  staticStyle: { "margin-left": "-30px" },
                  attrs: { id: "sub-group-" + parseInt(index + 1) }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "text-14",
                      staticStyle: { "margin-bottom": "5px" },
                      attrs: { id: index + 1 }
                    },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "groupname" },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                "align-center": "",
                                "justify-space-between": "",
                                row: ""
                              }
                            },
                            [
                              _c("div", { staticClass: "leftside" }, [
                                _c(
                                  "div",
                                  {
                                    ref: "groupname",
                                    refInFor: true,
                                    on: {
                                      mouseleave: function($event) {
                                        _vm.hover = false
                                      }
                                    }
                                  },
                                  [
                                    !group.Answers.length
                                      ? _c("v-icon", [
                                          _vm._v(
                                            "\n                    arrow_right\n                  "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    group.Answers.length
                                      ? _c("v-icon", [
                                          _vm._v(
                                            "\n                    arrow_drop_down\n                  "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/folder.svg")
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("span", {}, [
                                      _vm._v(_vm._s(group.group_name))
                                    ]),
                                    _vm._v(" "),
                                    !group.Answers.length
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "dragnaddrop pb-2",
                                            staticStyle: {
                                              "font-style": "italic"
                                            }
                                          },
                                          [_vm._v("Drag items here")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: group.Answers.length,
                                            expression: "group.Answers.length"
                                          }
                                        ],
                                        staticClass: "pa-0 ma-0 group-button",
                                        attrs: {
                                          text: "",
                                          small: "",
                                          flat: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onUngroup(group, index)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Ungroup\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "pa-0 ma-0 group-button",
                                        attrs: {
                                          text: "",
                                          small: "",
                                          flat: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.onNameChange(index)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    Rename\n                  "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pa-0 ma-0 text-xs-right",
                                        staticStyle: {
                                          width: "30px",
                                          display: "inline-block"
                                        },
                                        attrs: { text: "", small: "", flat: "" }
                                      },
                                      [_vm._v(_vm._s(group.total))]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.rename && _vm.currentIndex == index,
                                  expression: "rename && currentIndex == index"
                                }
                              ]
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: group.group_name,
                                    expression: "group.group_name"
                                  }
                                ],
                                ref: "gname",
                                refInFor: true,
                                staticClass: "rename text-14 pl-2 ml-2",
                                staticStyle: { color: "black" },
                                attrs: { type: "text" },
                                domProps: { value: group.group_name },
                                on: {
                                  blur: function($event) {
                                    return _vm.onBlur(group, index)
                                  },
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.onKeyUp(index)
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      group,
                                      "group_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "draggable-group-container" },
                [
                  _c(
                    "draggable",
                    {
                      ref: "answer",
                      refInFor: true,
                      attrs: {
                        id: "draggable-group-" + (index + 1).toString(),
                        groupid: group.id,
                        list: group.Answers,
                        group: "answers"
                      },
                      on: {
                        start: function($event) {
                          _vm.isDragging = true
                        },
                        end: function($event) {
                          return _vm.sortByIndex(group)
                        },
                        add: function($event) {
                          return _vm.updateGroup(group)
                        }
                      }
                    },
                    _vm._l(group.Answers, function(answer, index2) {
                      return _c(
                        "div",
                        { key: index2 },
                        [
                          answer.respondent_count > 0
                            ? _c(
                                "v-layout",
                                {
                                  ref: "answer",
                                  refInFor: true,
                                  staticClass: "answer mb-2 pl-2 pr-2",
                                  attrs: { "justify-space-between": "" }
                                },
                                [
                                  _c("input", {
                                    key: index2,
                                    ref: "checkbox",
                                    refInFor: true,
                                    staticClass:
                                      "mt-0 mb-1 ml-2 draggable-answer text-white styled-checkbox",
                                    attrs: {
                                      id: "group-" + answer.id.toString(),
                                      type: "checkbox",
                                      "data-id": answer.id,
                                      copy: answer.text,
                                      "data-count": answer.respondent_count,
                                      name: _vm.id.toString(),
                                      label: answer.text
                                    },
                                    domProps: {
                                      checked: answer.checked || false,
                                      value: answer.text
                                    },
                                    on: {
                                      input: function($event) {
                                        _vm.onFilterChange(
                                          group,
                                          "group-" + answer.id.toString(),
                                          "group"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for: "group-" + answer.id.toString()
                                      }
                                    },
                                    [_vm._v(_vm._s(answer.text))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "font-weight-bold",
                                      staticStyle: { "font-size": "12px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(answer.respondent_count) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        { staticClass: "pt-0", attrs: { id: "ungrouped" } },
        [
          _c(
            "v-list",
            [
              _c(
                "no-ssr",
                [
                  _c(
                    "draggable",
                    {
                      ref: "ungrouped",
                      attrs: {
                        id: "draggable-group-0",
                        list: _vm.ungrouped.Answers,
                        group: "answers"
                      },
                      on: {
                        start: function($event) {
                          _vm.isDragging = true
                        },
                        end: function($event) {
                          return _vm.sortByIndex(_vm.ungrouped)
                        },
                        add: function($event) {
                          return _vm.updateGroup(_vm.ungrouped)
                        }
                      }
                    },
                    _vm._l(_vm.ungrouped.Answers, function(answer, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          answer.respondent_count > 0
                            ? _c(
                                "v-layout",
                                {
                                  ref: "answer",
                                  refInFor: true,
                                  staticClass:
                                    "answer mb-1 pl-2 pr-2 pb-1 pt-1",
                                  class: { over: _vm.over },
                                  attrs: { "justify-space-between": "" }
                                },
                                [
                                  _c("input", {
                                    key: index,
                                    staticClass:
                                      "mt-0 mb-1 draggable-answer text-white styled-checkbox",
                                    attrs: {
                                      id:
                                        "ungrouped-" +
                                        (_vm.ungrouped, answer.id.toString()),
                                      type: "checkbox",
                                      "data-id": answer.id,
                                      copy: answer.text,
                                      "data-count": answer.respondent_count,
                                      name: _vm.id.toString(),
                                      label: answer.text
                                    },
                                    domProps: {
                                      checked: answer.checked || false,
                                      value: answer.text
                                    },
                                    on: {
                                      change: function($event) {
                                        _vm.onFilterChange(
                                          _vm.ungrouped,
                                          "ungrouped-" + answer.id.toString(),
                                          "ungrouped"
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      attrs: {
                                        for:
                                          "ungrouped-" +
                                          (_vm.ungrouped, answer.id.toString())
                                      },
                                      on: { click: _vm.logLabelClick }
                                    },
                                    [_vm._v(_vm._s(answer.text))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "font-weight-bold",
                                      staticStyle: { "font-size": "12px" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(answer.respondent_count) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-space-between": "", row: "" } },
        [
          _c(
            "v-btn",
            {
              staticClass: "creategroup lowercase ma-0",
              attrs: { small: "", flat: "" },
              on: {
                click: function($event) {
                  return _vm.addGroup(_vm.groups)
                }
              }
            },
            [
              _c("v-icon", { staticClass: "mr-1" }, [
                _vm._v("\n        add_circle_outline\n      ")
              ]),
              _vm._v("CREATE NEW GROUP\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _vm.groups.length
            ? _c(
                "v-btn",
                {
                  staticClass: "lowercase ma-0 pl-0",
                  attrs: { small: "", flat: "" },
                  on: {
                    click: function($event) {
                      return _vm.ungroupAll(_vm.groups)
                    }
                  }
                },
                [_vm._v("\n      Ungroup all\n    ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }