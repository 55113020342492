var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pt-0 compare-segments-container",
      attrs: { id: "compare-segments-container" }
    },
    [
      _c(
        "div",
        [
          _c(
            "v-layout",
            {
              staticStyle: { background: "#333333", color: "#fff" },
              attrs: {
                "align-center": "",
                "justify-space-between": "",
                row: "",
                "py-2": "",
                "px-3": ""
              }
            },
            [
              _c("v-flex", { staticClass: "text-18 font-weight-bold" }, [
                _vm._v("\n        Compare your segment\n      ")
              ]),
              _vm._v(" "),
              _c(
                "v-flex",
                { staticClass: "text-xs-right" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "min-width": "60px" },
                      attrs: { outline: "", small: "", color: "white" },
                      on: { click: _vm.closeFilter }
                    },
                    [_vm._v("\n          CLOSE\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.comparedSegments.length > 0
            ? _c(
                "v-layout",
                {
                  staticClass: "bg-black",
                  attrs: { "mx-3": "", "mt-4": "", "py-3": "", column: "" },
                  on: { mouseleave: _vm.onPanelOut }
                },
                _vm._l(_vm.comparedSegments, function(segment, index) {
                  return _c(
                    "v-flex",
                    {
                      key: index,
                      staticClass: "mb-2",
                      attrs: { "justify-space-around": "" }
                    },
                    [
                      _c("Subsegments", {
                        attrs: {
                          "enable-scroll": _vm.enableScroll,
                          answer: segment,
                          index: index,
                          "grouped-length": 0,
                          "background-color": _vm.backgroundColor(index)
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        { staticClass: "checklist", attrs: { "my-4": "" } },
        [
          _c(
            "v-list",
            { staticClass: "bg-black" },
            [
              _c(
                "no-ssr",
                _vm._l(_vm.segments, function(segment, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "align-center answer",
                          attrs: {
                            "justify-space-between": "",
                            "mb-1": "",
                            "px-3": "",
                            "py-1": ""
                          }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: segment.checked,
                                expression: "segment.checked"
                              }
                            ],
                            key: index,
                            staticClass: "mr-2 text-white styled-checkbox",
                            attrs: {
                              id: "compare-segments-" + segment.id,
                              type: "checkbox",
                              "data-id": segment.id,
                              copy: segment.name,
                              name: "compare-segments-" + segment.id,
                              label: segment.name
                            },
                            domProps: {
                              checked: segment.checked || false,
                              value: segment.name,
                              checked: Array.isArray(segment.checked)
                                ? _vm._i(segment.checked, segment.name) > -1
                                : segment.checked
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = segment.checked,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = segment.name,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          segment,
                                          "checked",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          segment,
                                          "checked",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(segment, "checked", $$c)
                                  }
                                },
                                _vm.onChange
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: { for: "compare-segments-" + segment.id }
                            },
                            [_vm._v(_vm._s(segment.name))]
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "v-alert",
            {
              staticClass: "my-alert font-weight-bold ma-0",
              staticStyle: { "font-size": "13px", border: "none !important" },
              attrs: { id: "my-alert", icon: "info", outline: "" },
              model: {
                value: _vm.alert,
                callback: function($$v) {
                  _vm.alert = $$v
                },
                expression: "alert"
              }
            },
            [
              _vm._v(
                "\n      Warning: You can only select up to 5 segments to compare\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-xs-left pr-3" },
        [
          _c(
            "v-btn",
            {
              staticClass: "text-black",
              class: { disabled: !_vm.enabled },
              attrs: { color: "primary" },
              on: { click: _vm.compare }
            },
            [_vm._v("\n      COMPARE SELECTED SEGMENTS\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("FilterProgress", {
        staticClass: "compare-segments-loader",
        attrs: { value: _vm.checkedSegmentCount, total: _vm.activeSegmentCount }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }