<template>
  <v-container class="title pa-0 ma-0 segment-answers bg-black">
    <v-content id="ungrouped" class="pt-0 bg-black">
      <v-list>
        <no-ssr>
          <v-layout
            v-for="(answer,index) in getAudeince"
            :key="index"
            justify-space-between
            class="mb-2"
            style="font-size:12px"
          >
            <div :for="answer.id.toString()">
              {{ answer.text }}
            </div>
            <div>{{ answer.respondent_count }}</div>
          </v-layout>
        </no-ssr>
      </v-list>
    </v-content>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import utils from '@/js/utils.js'

export default {
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      segmentQuestions: 'analyzeSurvey/getSegmentionQuestions',
      audienceQuestions: 'analyzeSurvey/getAudienceQuestions',
      audienceId: 'analyzeSurvey/getAudienceId'
    }),
    getAudeince() {
      if (this.audienceId === 0) {
        return utils.getSegmentQuestion(this.segmentQuestions, this.id)[0]
          .SurveyAnswers
      } else {
        return utils.getSegmentQuestion(this.audienceQuestions, this.id)[0]
          .SurveyAnswers
      }
    }
  }
}
</script>
<style lang="scss">
.simplebar-content-wrapper {
  .theme--dark.v-sheet {
    background-color: #222;
  }
  .v-list.theme--dark {
    background-color: #222;
  }
}
</style>
