var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    { attrs: { "fill-rule": "evenodd", "stroke-width": "1.25" } },
    [
      _c("path", {
        attrs: { d: "M12.74.625H.625v16.75h17.75V6.285L12.74.625z" }
      }),
      _vm._v(" "),
      _c("path", {
        attrs: { d: "M12.5 1.557V5.5M12.5 6.5h5", "stroke-linecap": "square" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }