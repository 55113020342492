<template lang="html">
  <div v-if="loading" id="loading-page" class="loading-page" :class="{show}">
    <div v-if="screenType === 'loading'" class="loading-page-content">
      <div>
        <p class="loading-page-copy">
          Loading
        </p>
        <ul class="loading-page-animation">
          <li />
          <li />
          <li />
          <li />
        </ul>
      </div>
    </div>
    <div v-if="screenType === 'error'" class="loading-page-content">
      <button class="loading-page-close" @click="loading=false">
        <v-icon>close</v-icon>
      </button> 
      <div>
        <p class="loading-page-copy">
          {{ error }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { TweenMax, Power4 } from 'gsap'

export default {
  data: () => ({
    loading: false,
    show: false,
    screenType: 'loading',
    error: null
  }),
  methods: {
    start(screenType = 'loading') {
      this.screenType = screenType
      this.loading = true
      this.show = true
      TweenMax.set('.loading-page', { alpha: 0 })
      TweenMax.to('.loading-page', 0.3, { alpha: 1, ease: Power4.easeOut })
      //   TweenMax.set('.loading-page-content', { alpha: 0, scale: 0.6 })
      //   TweenMax.to('.loading-page-content', 0.6, {
      //     alpha: 1,
      //     scale: 1,
      //     ease: Power4.easeOut
      //   })
    },
    finish() {
      // only remove loader when all ajax calls have been complete
      if (this.$axios.numberOfAjaxCAllPending > 0) return
      TweenMax.to('.loading-page', 0.5, {
        alpha: 0,
        ease: Power4.easeOut,
        onComplete: () => {
          this.show = false
          this.loading = false
        }
      })
    },
    showError(error) {
      this.$set(this, 'screenType', 'error')
      this.$set(this, 'error', error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/_vars.scss';

@keyframes fadeIn {
  0% {
    background-color: #fff;
    transform: scale(0);
  }
  25% {
    background-color: color(primary);
    transform: scale(1.3);
  }
  35% {
    transform: scale(1);
  }
  50% {
    background-color: #fff;
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.loading-page {
  //   opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 30px;
  font-family: sans-serif;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-page-content {
  background-color: #222;
  padding: 20px;
  max-width: 580px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.loading-page-copy {
  color: #fff;
  font-size: 18px;
  font-weight: $font-bold;
}
.loading-page-animation {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  li {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    margin-left: 5px;
    &:first-of-type {
      margin-left: 0;
      animation-delay: 0;
    }
    &:nth-of-type(2) {
      animation-delay: 0.5s;
    }
    &:nth-of-type(3) {
      animation-delay: 1s;
    }
    &:nth-of-type(4) {
      animation-delay: 1.5s;
    }
  }
}

.loading-page-close {
  position: absolute;
  top: 0px;
  right: 20px;
}
</style>

<style lang="scss">
.loading-page-close {
  .theme--light.v-icon {
    color: #fff;
  }
}
</style>
