// Assuming you're using `js-cookie` for handling cookies
import Cookies from 'js-cookie'

export default function({ $axios, redirect, store, params }) {
  const baseURL =
    process.env.baseUrl || 'https://havas-arcadia-api-dev.tech4.ny.havasww.com/'
  $axios.defaults.baseURL = baseURL
  // count current # fo api calls
  $axios.numberOfAjaxCAllPending = 0

  // const bearer = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDIsImVtYWlsIjoibWFyay5jbGlmdG9uQGhhdmFzLmNvbSIsImZpcnN0X25hbWUiOiJNYXJrIiwibGFzdF9uYW1lIjoiQ2xpZnRvbiIsInBhc3N3b3JkIjoiJDJhJDA4JGRSUHR6Wnlsa0ZRWFl3SUJOc2NhU3VaNkdoalQyL0F6VFhpNDlLcTV1ZDg1bkFqUm1BSmVlIiwicm9sZV9pZCI6bnVsbCwiYWNjZXNzX2VuZF9kYXRlIjpudWxsLCJ1cGRhdGVkX2F0IjoiMjAxOS0wMS0yM1QyMjoyOTo1OS4wMDBaIiwiaWF0IjoxNTQ4ODAyOTQ3LCJleHAiOjE1NDk0MDc3NDd9.S4ClDsKnfFTiaSIVp2ytjJkFF12PMfsMvCl9Le9ZjFw"
  $axios.onRequest(config => {
    if (store.state.isAuth) {
      config.headers.common.Authorization = `Bearer ${store.state.auth.token}`
      config.headers.common.workspaceid = store.getters.brandInfo
        ? store.getters.brandInfo.id
        : params.brand
          ? params.brand
          : ''
      config.headers.common['Cache-Control'] = 'no-cache'
      // temporarily lower api timeout
      //  to resolve some errors with brand apis
      // config.timeout = 500
    }
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      redirect('/400')
    }
    console.log('onerror', error.response.data.error)
    // Check for the specific error message and redirect to login
    if (
      code === 403 ||
      (error.response &&
        error.response.data.error === 'you do not have access to this resource')
    ) {
      if (process.client && !localStorage.getItem('hasAlerted')) {
        alert('Your session has expired. Please log in again.')
        localStorage.setItem('hasAlerted', 'true')
        // Remove cookies
        Cookies.remove('auth')
        Cookies.remove('workspaceid')

        // Reload the page
        location.reload()
      }
    }
  })

  // Add a request interceptor
  $axios.interceptors.request.use(
    function(config) {
      $axios.numberOfAjaxCAllPending++
      // console.log('------------  Ajax pending', $axios.numberOfAjaxCAllPending)
      // show loader
      return config
    },
    function(error) {
      // console.log('error', error)
      return Promise.reject(error)
    }
  )
  // Add a response interceptor
  $axios.interceptors.response.use(
    function(response) {
      // Reset the hasAlerted flag upon a successful response
      if (process.client) {
        // Reset the hasAlerted flag upon a successful response
        localStorage.removeItem('hasAlerted')
      }

      $axios.numberOfAjaxCAllPending--
      // console.log('------------  Ajax pending', $axios.numberOfAjaxCAllPending)

      if ($axios.numberOfAjaxCAllPending === 0) {
        // this.$nuxt.$loading.finish()
        // hide loader
      }
      return response
    },
    function(error) {
      const code = parseInt(error.response && error.response.status)
      // Check for the specific error message and redirect to login
      if (
        code === 403 ||
        (error.response &&
          error.response.data.error ===
            'you do not have access to this resource')
      ) {
        if (!localStorage.getItem('hasAlerted')) {
          alert('Your session has expired. Please log in again.')
          localStorage.setItem('hasAlerted', 'true')
          // Remove cookies
          Cookies.remove('auth')
          Cookies.remove('workspaceid')

          // Reload the page
          location.reload()
        }
      }
      return Promise.reject(error)
    }
  )

  return $axios
}
