<template>
  <v-toolbar :class="{transparentBg}" flat>
    <h2>{{ title }}</h2>
    <v-spacer />
    <slot>
      <!--buttons and content are passed here by default -->
    </slot>
  </v-toolbar>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      default: 'Overview',
      type: String
    },
    transparentBg: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  updated() {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
.transparentBg {
  background: transparent;
}
</style>
