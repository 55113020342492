var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { key: _vm.componentKey },
    [
      _c(
        "v-content",
        {
          staticClass: "pt-2 mr-2 answers",
          staticStyle: { position: "relative" },
          attrs: { id: _vm.id }
        },
        [
          _c("div", {
            staticClass: "overlay",
            class: { disabled: _vm.disabled },
            staticStyle: {
              position: "absolute",
              top: "0",
              height: "100%",
              background: "rgba(34,34,34,0.50 )",
              width: "100%",
              "z-index": "4"
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.answers, function(answer, index) {
            return _c(
              "v-layout",
              {
                key: index,
                ref: "filteranswer",
                refInFor: true,
                staticClass: "filter-answer mb-2",
                class: { "disabled inactive": !answer.selected },
                attrs: { "justify-space-between": "" }
              },
              [
                _c("input", {
                  ref: "input",
                  refInFor: true,
                  staticClass: "mt-0 mb-1 text-white styled-checkbox disabled",
                  attrs: {
                    id: "segment-" + answer.id.toString(),
                    type: "checkbox",
                    "data-id": answer.id,
                    copy: answer.text,
                    "data-count": answer.respondent_count,
                    name: _vm.id.toString()
                  },
                  domProps: { checked: answer.selected || false },
                  on: {
                    change: function($event) {
                      return _vm.onFiltersChange(answer, index)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "label",
                  { attrs: { for: "segment-" + answer.id.toString() } },
                  [_vm._v(_vm._s(answer.text))]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v(
                    "\n        " + _vm._s(answer.respondent_count) + "\n      "
                  )
                ])
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }