var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 30 30",
        "aria-labelledby": _vm.iconName,
        role: "presentation"
      }
    },
    [
      _c("title", { attrs: { lang: "en" } }, [
        _vm._v(_vm._s(_vm.iconName) + " icon")
      ]),
      _vm._v(" "),
      _c(
        "g",
        { attrs: { fill: "'#FF0000'", stroke: _vm.color } },
        [
          _vm.iconName === "ArrowRight" ? _c("ArrowRight") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "CircleGraph" ? _c("CircleGraph") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Expand" ? _c("Expand") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Graph" ? _c("Graph") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Grid" ? _c("Grid") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "List" ? _c("List") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Page" ? _c("Page") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Person"
            ? _c("Person", { attrs: { color: _vm.color } })
            : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Search" ? _c("Search") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "SquareGraph" ? _c("SquareGraph") : _vm._e(),
          _vm._v(" "),
          _vm.iconName === "Star" ? _c("Star") : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }