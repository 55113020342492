var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { light: "" } },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { staticClass: "left-nav-container" },
            [_c("LeftNav")],
            1
          ),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c("TopNav"),
              _vm._v(" "),
              _c("SurveyNavigation"),
              _vm._v(" "),
              _c(
                "v-layout",
                [
                  _c(
                    "v-flex",
                    {
                      staticClass: "nuxt-content-container",
                      attrs: { xs9: "" }
                    },
                    [_c("nuxt")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    {
                      staticClass: "fixed-container",
                      attrs: { "d-flex": "", xs3: "" }
                    },
                    [_c("v-layout", [_c("FilterPanel")], 1)],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { staticClass: "holder" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "slide-fade" } },
                        [
                          _c(
                            "v-flex",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentView == "SEGMENT",
                                  expression: "currentView == 'SEGMENT'"
                                }
                              ],
                              staticClass: "panel segments",
                              attrs: { xs12: "" }
                            },
                            [_c("SegmentFilter")],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "slide-fade" } },
                        [
                          _c(
                            "v-flex",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentView == "AUDIENCE",
                                  expression: "currentView == 'AUDIENCE'"
                                }
                              ],
                              staticClass: "panel audience",
                              attrs: { xs12: "" }
                            },
                            [_c("AudienceFilter")],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "transition",
                        { attrs: { name: "slide-fade" } },
                        [
                          _c(
                            "v-flex",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentView == "COMPARE",
                                  expression: "currentView == 'COMPARE'"
                                }
                              ],
                              staticClass: "panel segments",
                              staticStyle: { "margin-top": "65px" },
                              attrs: { xs3: "" }
                            },
                            [_c("CompareSegments")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }