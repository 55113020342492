var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "top-nav" } },
    [
      this
        ? _c(
            "v-toolbar",
            { attrs: { app: "", dense: "", flat: "" } },
            [
              _c(
                "div",
                { attrs: { id: "first-top-item" } },
                [_vm.$store.state.isAuth ? _c("BreadCrumbs") : _vm._e()],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.isAdmin && _vm.$route.params.brand && _vm.inWorkspace
                ? _c(
                    "Button",
                    {
                      attrs: {
                        to: _vm.baseURL + "/edit",
                        variant: "outline",
                        small: true
                      }
                    },
                    [_vm._v("\n      workspace settings\n    ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }