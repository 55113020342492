import Vue from 'vue'

export const state = () => ({
  survey: null,
  answers: [],
  currentRecording: null,
  micAllowed: false
})

export const getters = {
  questions(state) {
    return state.survey ? state.survey.SurveyQuestions : []
  },
  orderedQuestions(state, getters) {
    const questions = [...getters.questions]
    return questions.sort((a, b) => a.survey_order - b.survey_order)
  },
  questionsTotal(state, getters) {
    return getters.questions.length
  },
  isAccepting(state) {
    return state.survey ? state.survey.is_accepting_responses : false
  },
  questionsCompleted(state, getters) {
    const completed = state.answers.filter(answer => {
      const { SurveyAnswerIDs, SurveyAnswerText } = answer
      if (SurveyAnswerIDs.length || SurveyAnswerText.length) {
        return answer
      }
    })
    return completed.length
  },
  progress(state, getters) {
    return (getters.questionsCompleted / getters.questionsTotal) * 100
  },
  getAnswerById(state) {
    return id => state.answers.find(answer => answer.id === id)
  }
}

export const mutations = {
  initSurvey(state, survey) {
    Vue.set(state, 'survey', survey)
  },
  initAnswers(state, answers) {
    Vue.set(state, 'answers', answers)
  },
  updateAnswer(state, answer) {
    Vue.set(
      state,
      'answers',
      state.answers.map(
        _answer => (_answer.id === answer.id ? answer : _answer)
      )
    )
  },
  setCurrentRecording(state, id) {
    Vue.set(state, 'currentRecording', id)
  },
  setMicAllowed(state, bool) {
    Vue.set(state, 'micAllowed', bool)
  }
}

export const actions = {
  initSurvey({ commit }, survey) {
    commit('initSurvey', survey)
  },
  initAnswers({ commit }, answers) {
    commit('initAnswers', answers)
  },
  updateAnswer({ commit }, answer) {
    commit('updateAnswer', answer)
  },
  setCurrentRecording({ commit }, id) {
    commit('setCurrentRecording', id)
  },
  setMicAllowed({ commit }, bool) {
    commit('setMicAllowed', bool)
  }
}
